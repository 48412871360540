<template>
    <v-container>
        <v-row text-center wrap>
            <v-col xs="12">
                <v-card class="mb-4">
                    <v-toolbar flat class="grey lighten-4">
                        <v-toolbar-title>Настройки</v-toolbar-title>
                    </v-toolbar>
                    <v-tabs>
                        <v-tab>
                            Профиль
                        </v-tab>
                        <v-tab>
                            Пароль
                        </v-tab>
                        <v-tab>
                            API
                        </v-tab>
                        <v-tab>
                            SMPP
                        </v-tab>
                        <v-tab-item>
                            <v-layout
                                v-if="tabProfile.loading"
                                justify-center
                            >
                                <v-progress-circular
                                    :size="50"
                                    color="primary"
                                    indeterminate
                                    class="ma-4 align-center"
                                />
                            </v-layout>
                            <v-form
                                v-if="!tabProfile.loading"
                                @submit.prevent="updateProfile"
                                class="pb-3"
                            >
                                <v-card-text>
                                    <v-row wrap text-left subheading>
                                        <v-col xs="4" md="3" lg="2" pa-2>
                                            Номер телефона
                                        </v-col>
                                        <v-col xs="8" md="9" lg="10" pa-2>
                                            +{{ tabProfile.data.phone }}
                                        </v-col>
                                        <v-col xs="4" md="3" lg="2" pa-2>
                                            Почта
                                        </v-col>
                                        <v-col xs="8" md="9" lg="10" pa-2>
                                            {{ tabProfile.data.email }}
                                        </v-col>
                                        <v-col xs="12" md="12" pa-2>
                                            <v-select
                                                v-model="tabProfile.data.timezone"
                                                :items="tabProfile.timezones"
                                                :cache-items="true"
                                                item-text="name"
                                                item-value="id"
                                                label="Часовой пояс"
                                            >
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer/>
                                    <v-btn
                                        :loading="tabProfile.progress"
                                        type="submit"
                                        color="blue-grey darken-2"
                                        dark
                                    >Сохранить</v-btn>
                                </v-card-actions>
                            </v-form>
                        </v-tab-item>
                        <v-tab-item>
                            <v-form
                                @submit.prevent="updatePassword"
                            >
                                <v-card-text>
                                    <v-text-field
                                        v-model="tabPassword.data.password_old"
                                        :error-messages="tabPassword.errors.password_old"
                                        prepend-icon="mdi-lock"
                                        name="password_old"
                                        label="Текущий пароль"
                                        type="password">
                                    </v-text-field>
                                    <v-text-field
                                        v-model="tabPassword.data.password_new"
                                        :error-messages="tabPassword.errors.password_new"
                                        prepend-icon="mdi-lock"
                                        name="password_new"
                                        label="Новый пароль"
                                        type="password">
                                    </v-text-field>
                                    <v-card-actions>
                                        <v-spacer/>
                                        <v-btn
                                            :loading="tabPassword.progress"
                                            type="submit"
                                            color="blue-grey darken-2"
                                            dark
                                        >Сохранить</v-btn>
                                    </v-card-actions>
                                </v-card-text>
                            </v-form>
                        </v-tab-item>
                        <v-tab-item>
                            <v-data-table
                                :headers="tabAPI.search.headers"
                                :items="tabAPI.search.data"
                                :loading="tabAPI.search.loading"
                                hide-default-footer
                            >
                                <template v-slot:item="{ item }">
                                    <tr>
                                        <td class="text-left">{{ item.key }}</td>
                                        <td class="text-left">{{ item.name }}</td>
                                        <td class="text-left">{{ tabAPI.api.filter(v => v.id === item.api)[0].name }}</td>
                                        <td class="text-left">
                                            <div
                                                v-for="ip in item.firewall"
                                                :key="ip.key"
                                            >
                                                {{ ip }}
                                            </div>
                                        </td>
                                        <td class="layout">
                                            <v-icon
                                                @click="tabAPI.update.data = {...item}; tabAPI.update.data.firewall = tabAPI.update.data.firewall.join('\n'); tabAPI.update.show = true"
                                                class="mr-2"
                                                small
                                            >
                                                mdi-pencil
                                            </v-icon>
                                            <v-icon
                                                @click="tabAPI.remove.data = item; tabAPI.remove.show = true"
                                                small
                                            >
                                                mdi-delete
                                            </v-icon>
                                        </td>
                                    </tr>
                                </template>
                                <template v-slot:no-data>
                                    <div v-if="tabAPI.search.loading">
                                        Идет загрузка данных
                                    </div>
                                    <div v-if="!tabAPI.search.loading">
                                        Нет доступных ключей
                                    </div>
                                </template>
                            </v-data-table>
                            <div class="text-center pt-2 pb-5">
                                <v-btn
                                    @click="tabAPI.create.show = true"
                                    color="blue-grey darken-2"
                                    dark
                                >
                                    <v-icon left>mdi-key-plus</v-icon>
                                    Добавить
                                </v-btn>
                            </div>
                            <v-dialog
                                v-model="tabAPI.create.show"
                                persistent
                                max-width="500"
                            >
                                <v-card>
                                    <v-card-title class="headline">Новый ключ API</v-card-title>
                                    <v-form @submit.prevent="createApiKey">
                                        <v-card-text>
                                            <v-alert class="blue-grey white--text">
                                                Ключ может работать только с выбранной версией API.
                                            </v-alert>
                                            <v-text-field
                                                v-model="tabAPI.create.data.name"
                                                :error-messages="tabAPI.create.errors.name"
                                                name="name"
                                                label="Название"
                                                type="text">
                                            </v-text-field>
                                            <v-select
                                                v-model="tabAPI.create.data.api"
                                                :error-messages="tabAPI.create.errors.api"
                                                :items="tabAPI.api"
                                                item-text="name"
                                                item-value="id"
                                                name="api"
                                                label="API"
                                            />
                                            <v-textarea
                                                v-model="tabAPI.create.data.firewall"
                                                :error-messages="tabAPI.create.errors.firewall"
                                                name="firewall"
                                                class="mt-3"
                                                label="Ограничения по IP"
                                                placeholder="Список IP или подсетей, через запятую или с новой строки. Пример: 10.100.0.1/24, 192.168.0.25"
                                                type="text">
                                            </v-textarea>
                                            <v-card-actions>
                                                <v-spacer/>
                                                <v-btn
                                                    :disabled="tabAPI.create.progress"
                                                    color="red darken-1"
                                                    text
                                                    @click="tabAPI.create.show = false"
                                                >
                                                    Отменить
                                                </v-btn>
                                                <v-btn
                                                    :loading="tabAPI.create.progress"
                                                    type="submit"
                                                    color="blue-grey darken-2"
                                                    dark
                                                >Сохранить</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-form>
                                </v-card>
                            </v-dialog>
                            <v-dialog
                                v-if="tabAPI.update.data"
                                v-model="tabAPI.update.show"
                                persistent
                                max-width="500"
                            >
                                <v-card>
                                    <v-card-title class="headline">Редактирование ключа API</v-card-title>
                                    <v-form @submit.prevent="updateApiKey">
                                        <v-card-text>
                                            <v-text-field
                                                v-model="tabAPI.update.data.name"
                                                :error-messages="tabAPI.update.errors.name"
                                                name="name"
                                                label="Название"
                                                type="text"
                                            />
                                            <v-select
                                                v-model="tabAPI.update.data.api"
                                                :error-messages="tabAPI.update.errors.api"
                                                :items="tabAPI.api"
                                                item-text="name"
                                                item-value="id"
                                                name="api"
                                                label="API"
                                            />
                                            <v-textarea
                                                v-model="tabAPI.update.data.firewall"
                                                :error-messages="tabAPI.update.errors.firewall"
                                                name="firewall"
                                                class="mt-3"
                                                label="Ограничения по IP"
                                                placeholder="Список IP или подсетей, через запятую или с новой строки. Пример: 10.100.0.1/24, 192.168.0.25"
                                                type="text"
                                            />
                                            <v-card-actions>
                                                <v-spacer/>
                                                <v-btn
                                                    :disabled="tabAPI.update.progress"
                                                    color="red darken-1"
                                                    text
                                                    @click="tabAPI.update.show = false"
                                                >
                                                    Отменить
                                                </v-btn>
                                                <v-btn
                                                    :loading="tabAPI.update.progress"
                                                    type="submit"
                                                    color="blue-grey darken-2"
                                                    dark
                                                >Сохранить</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-form>
                                </v-card>
                            </v-dialog>
                            <v-dialog
                                v-if="tabAPI.remove.data"
                                v-model="tabAPI.remove.show"
                                max-width="500"
                            >
                                <v-card>
                                    <v-card-title class="headline">Удалить ключ API {{ tabAPI.remove.data.name }}?</v-card-title>
                                    <v-card-text>
                                        После удаления ключа вы больше не сможете использовать его для доступа к API. Для предотвращения блокировки ваших серверов рекомендуем убедиться, что данный ключ не используется.
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer/>
                                        <v-btn
                                            @click="tabAPI.remove.show = false"
                                            color="red darken-1"
                                            text
                                        >
                                            Отменить
                                        </v-btn>
                                        <v-btn
                                            @click="removeApiKey"
                                            color="blue-grey darken-2"
                                            dark
                                            text
                                        >
                                            Удалить
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-tab-item>
                        <v-tab-item>
                            <v-data-table
                                :headers="tabSMPP.search.headers"
                                :items="tabSMPP.search.data"
                                :loading="tabSMPP.search.loading"
                                hide-default-footer
                            >
                                <template v-slot:item="{ item }">
                                    <tr>
                                        <td class="text-left">{{ item.name }}</td>
                                        <td class="text-left">{{ item.system_id }}</td>
                                        <td class="text-left">{{ item.password }}</td>
                                        <td class="text-left">
                                            <div
                                                v-for="ip in item.firewall"
                                                :key="ip.key"
                                            >
                                                {{ ip }}
                                            </div>
                                        </td>
                                        <td class="layout">
                                            <v-icon
                                                @click="tabSMPP.update.data = {...item}; tabSMPP.update.data.firewall = tabSMPP.update.data.firewall.join('\n'); tabSMPP.update.show = true"
                                                class="mr-2"
                                                small
                                            >
                                                mdi-pencil
                                            </v-icon>
                                            <v-icon
                                                @click="tabSMPP.remove.data = item; tabSMPP.remove.show = true"
                                                small
                                            >
                                                mdi-delete
                                            </v-icon>
                                        </td>
                                    </tr>
                                </template>
                                <template v-slot:no-data>
                                    <div v-if="tabSMPP.search.loading">
                                        Идет загрузка данных
                                    </div>
                                    <div v-if="!tabSMPP.search.loading">
                                        Нет доступных подключений
                                    </div>
                                </template>
                            </v-data-table>
                            <div class="text-center pt-2 pb-5">
                                <v-btn
                                    @click="tabSMPP.create.show = true"
                                    color="blue-grey darken-2"
                                    dark
                                >
                                    <v-icon left>mdi-plus-circle</v-icon>
                                    Добавить
                                </v-btn>
                            </div>
                            <v-dialog
                                v-model="tabSMPP.create.show"
                                persistent
                                max-width="500"
                            >
                                <v-card>
                                    <v-card-title class="headline">Новое подключение SMPP</v-card-title>
                                    <v-form @submit.prevent="createSMPP">
                                        <v-card-text>
                                            <v-text-field
                                                v-model="tabSMPP.create.data.name"
                                                :error-messages="tabSMPP.create.errors.name"
                                                name="name"
                                                label="Название подключения"
                                                type="text"
                                            />
                                            <v-text-field
                                                v-model="tabSMPP.create.data.system_id"
                                                :error-messages="tabSMPP.create.errors.system_id"
                                                name="name"
                                                label="System ID"
                                                type="text"
                                            />
                                            <v-text-field
                                                v-model="tabSMPP.create.data.password"
                                                :error-messages="tabSMPP.create.errors.password"
                                                name="name"
                                                label="Пароль (от 5 до 8 символов)"
                                                type="password"
                                            />
                                            <v-textarea
                                                v-model="tabSMPP.create.data.firewall"
                                                :error-messages="tabSMPP.create.errors.firewall"
                                                name="firewall"
                                                class="mt-3"
                                                label="Ограничения по IP"
                                                placeholder="Список IP или подсетей, через запятую или с новой строки. Пример: 10.100.0.1/24, 192.168.0.25"
                                                type="text"
                                            />
                                            <v-card-actions>
                                                <v-spacer/>
                                                <v-btn
                                                    :disabled="tabSMPP.create.progress"
                                                    color="red darken-1"
                                                    text
                                                    @click="tabSMPP.create.show = false"
                                                >
                                                    Отменить
                                                </v-btn>
                                                <v-btn
                                                    :loading="tabSMPP.create.progress"
                                                    type="submit"
                                                    color="blue-grey darken-2"
                                                    dark
                                                >Сохранить</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-form>
                                </v-card>
                            </v-dialog>
                            <v-dialog
                                v-if="tabSMPP.update.data"
                                v-model="tabSMPP.update.show"
                                persistent
                                max-width="500"
                            >
                                <v-card>
                                    <v-card-title class="headline">Редактирование подключения SMPP</v-card-title>
                                    <v-form @submit.prevent="updateSMPP">
                                        <v-card-text>
                                            <v-text-field
                                                v-model="tabSMPP.update.data.name"
                                                :error-messages="tabSMPP.update.errors.name"
                                                name="name"
                                                label="Название"
                                                type="text"
                                            />
                                            <v-text-field
                                                v-model="tabSMPP.update.data.system_id"
                                                :error-messages="tabSMPP.update.errors.system_id"
                                                name="name"
                                                label="System ID"
                                                type="text"
                                            />
                                            <v-text-field
                                                v-model="tabSMPP.update.data.password"
                                                :error-messages="tabSMPP.update.errors.password"
                                                name="name"
                                                label="Пароль (от 5 до 8 символов)"
                                                type="password"
                                            />
                                            <v-textarea
                                                v-model="tabSMPP.update.data.firewall"
                                                :error-messages="tabSMPP.update.errors.firewall"
                                                name="firewall"
                                                class="mt-3"
                                                label="Ограничения по IP"
                                                placeholder="Список IP или подсетей, через запятую или с новой строки. Пример: 10.100.0.1/24, 192.168.0.25"
                                                type="text"
                                            />
                                            <v-card-actions>
                                                <v-spacer/>
                                                <v-btn
                                                    :disabled="tabSMPP.update.progress"
                                                    color="red darken-1"
                                                    text
                                                    @click="tabSMPP.update.show = false"
                                                >
                                                    Отменить
                                                </v-btn>
                                                <v-btn
                                                    :loading="tabSMPP.update.progress"
                                                    type="submit"
                                                    color="blue-grey darken-2"
                                                    dark
                                                >Сохранить</v-btn>
                                            </v-card-actions>
                                        </v-card-text>
                                    </v-form>
                                </v-card>
                            </v-dialog>
                            <v-dialog
                                v-if="tabSMPP.remove.data"
                                v-model="tabSMPP.remove.show"
                                max-width="500"
                            >
                                <v-card>
                                    <v-card-title class="headline">Удалить подключение SMPP {{ tabSMPP.remove.data.name }}?</v-card-title>
                                    <v-card-text>
                                        После удаления подключения вы больше не сможете использовать его для доступа к SMPP. Для предотвращения блокировки ваших серверов рекомендуем убедиться, что данное подключение не используется.
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer/>
                                        <v-btn
                                            @click="tabSMPP.remove.show = false"
                                            color="red darken-1"
                                            text
                                        >
                                            Отменить
                                        </v-btn>
                                        <v-btn
                                            @click="removeSMPP"
                                            color="blue-grey darken-2"
                                            dark
                                            text
                                        >
                                            Удалить
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-tab-item>
                    </v-tabs>
                </v-card>
            </v-col>
        </v-row>
        <v-snackbar
            v-model="tabPassword.success"
            bottom right
            color="success"
            class="mb-3 mr-2"
        >
            Пароль успешно сохранен
            <v-btn
                text
                icon
                @click="tabPassword.success = false"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
export default {
    name: "ConfigController",
    data () {
        return {
            tabAPI: {
                api: [
                    {
                        id: 'json:2020-01-01',
                        name: 'JSON (ver. 2020-01-01)',
                    },
                ],
                create: {
                    show: false,
                    data: {
                        name: '',
                        firewall: '',
                        api: '',
                    },
                    errors: {
                        name: [],
                        firewall: [],
                        api: [],
                    },
                    progress: false,
                },
                remove: {
                    data: null,
                    show: false,
                },
                search: {
                    data: [],
                    headers: [
                        {
                            text: 'Ключ API',
                            value: 'key',
                        },
                        {
                            text: 'Название',
                            value: 'name',
                        },
                        {
                            text: 'API',
                            value: 'api',
                        },
                        {
                            text: 'Ограничения по IP',
                            value: 'firewall',
                            sortable: false,
                        },
                        {
                            text: 'Действия',
                            value: 'key',
                            sortable: false,
                        },
                    ],
                    loading: true,
                },
                update: {
                    data: null,
                    errors: {
                        name: [],
                        firewall: [],
                        api: [],
                    },
                    progress: false,
                    show: false,
                },
            },
            tabPassword: {
                data: {
                    password_old: '',
                    password_new: '',
                },
                errors: {
                    password_old: [],
                    password_new: [],
                },
                progress: false,
                success: false,
            },
            tabProfile: {
                data: {
                    email: '',
                    phone: '',
                    timezone: '',
                },
                errors: {
                    timezone: [],
                },
                loading: true,
                progress: false,
                timezones: [
                    {
                        id: 'Pacific/Samoa',
                        name: '(UTC-11:00) Паго-Паго',
                    },
                    {
                        id: 'Pacific/Niue',
                        name: '(UTC-11:00) Алофи',
                    },
                    {
                        id: 'Pacific/Tahiti',
                        name: '(UTC-10:00) Папеэте, Хуахине, Маупити',
                    },
                    {
                        id: 'Pacific/Honolulu',
                        name: '(UTC-10:00) Гонолулу, Хило, Милилани',
                    },
                    {
                        id: 'Pacific/Rarotonga',
                        name: '(UTC-10:00) Аваруа, Аитутаки',
                    },
                    {
                        id: 'Pacific/Marquesas',
                        name: '(UTC-09:30) Нуку Хива',
                    },
                    {
                        id: 'America/Anchorage',
                        name: '(UTC-08:00) Анкоридж, Фэрбанкс, Ситка',
                    },
                    {
                        id: 'America/Juneau',
                        name: '(UTC-08:00) Джуно, Кетчикан',
                    },
                    {
                        id: 'America/Dawson',
                        name: '(UTC-07:00) Доусон',
                    },
                    {
                        id: 'America/Vancouver',
                        name: '(UTC-07:00) Ванкувер, Суррей, Бернаби',
                    },
                    {
                        id: 'America/Phoenix',
                        name: '(UTC-07:00) Финикс, Тусон, Меса',
                    },
                    {
                        id: 'America/Hermosillo',
                        name: '(UTC-07:00) Эрмосильо, Сьюдад-Обрегон, Ногалес',
                    },
                    {
                        id: 'America/Whitehorse',
                        name: '(UTC-07:00) Уайтхорс',
                    },
                    {
                        id: 'America/Chihuahua',
                        name: '(UTC-07:00) Сьюдад-Хуарес, Чиуауа, Кульякан',
                    },
                    {
                        id: 'America/Tijuana',
                        name: '(UTC-07:00) Тихуана, Мехикали, Энсенада',
                    },
                    {
                        id: 'America/Dawson_Creek',
                        name: '(UTC-07:00) Форт-Сент-Джон, Кранбрук, Досон-Крик',
                    },
                    {
                        id: 'America/Los_Angeles',
                        name: '(UTC-07:00) Сан-Франциско, Лос-Анджелес, Сан-Диего',
                    },
                    {
                        id: 'America/Monterrey',
                        name: '(UTC-06:00) Монтеррей',
                    },
                    {
                        id: 'America/Swift_Current',
                        name: '(UTC-06:00) Саскатун, Реджайна, Принс-Альберт',
                    },
                    {
                        id: 'America/Denver',
                        name: '(UTC-06:00) Денвер, Альбукерке, Колорадо-Спрингс',
                    },
                    {
                        id: 'America/Yellowknife',
                        name: '(UTC-06:00) Йеллоунайф, Хей-Ривер, Инувик',
                    },
                    {
                        id: 'America/Managua',
                        name: '(UTC-06:00) Манагуа, Матагальпа, Леон',
                    },
                    {
                        id: 'America/Mexico_City',
                        name: '(UTC-06:00) Мехико, Гвадалахара, Леон',
                    },
                    {
                        id: 'America/Costa_Rica',
                        name: '(UTC-06:00) Сан-Хосе, Картаго, Лимон',
                    },
                    {
                        id: 'Pacific/Galapagos',
                        name: '(UTC-06:00) Пуэрто Айора, Пуэрто-Бакерисо-Морено, Бальтра',
                    },
                    {
                        id: 'America/Tegucigalpa',
                        name: '(UTC-06:00) Сан-Педро-Сула, Тегусигальпа, Ла-Сейба',
                    },
                    {
                        id: 'America/Edmonton',
                        name: '(UTC-06:00) Калгари, Эдмонтон, Ред Дир',
                    },
                    {
                        id: 'America/Belize',
                        name: '(UTC-06:00) Белиз, Сан-Игнасио, Бельмопан',
                    },
                    {
                        id: 'America/Boise',
                        name: '(UTC-06:00) Бойсе, Меридиан, Айдахо-Фолс',
                    },
                    {
                        id: 'America/Guatemala',
                        name: '(UTC-06:00) Гватемала, Сан-Сальвадор, Кесальтенанго',
                    },
                    {
                        id: 'America/Regina',
                        name: '(UTC-06:00) Мус-Джо, Свифт-Керрент, Йорктон',
                    },
                    {
                        id: 'America/Cayman',
                        name: '(UTC-05:00) Джорджтаун, Кайман-Брак',
                    },
                    {
                        id: 'America/Bogota',
                        name: '(UTC-05:00) Богота, Медельин, Кали',
                    },
                    {
                        id: 'America/Indiana/Knox',
                        name: '(UTC-05:00) Эвансвилл, Мичиган Сити, Вальпараисо',
                    },
                    {
                        id: 'America/Rio_Branco',
                        name: '(UTC-05:00) Риу-Бранку, Табатинга',
                    },
                    {
                        id: 'America/Jamaica',
                        name: '(UTC-05:00) Кингстон, Монтего-Бей, Мей Пен',
                    },
                    {
                        id: 'America/Chicago',
                        name: '(UTC-05:00) Чикаго, Эль-Пасо, Нашвилл',
                    },
                    {
                        id: 'America/Guayaquil',
                        name: '(UTC-05:00) Гуаякиль, Кито, Куэнка',
                    },
                    {
                        id: 'America/Atikokan',
                        name: '(UTC-05:00) Кенора, Драйден, Ред-Лейк',
                    },
                    {
                        id: 'Pacific/Easter',
                        name: '(UTC-05:00) Ханга-Роа',
                    },
                    {
                        id: 'America/Winnipeg',
                        name: '(UTC-05:00) Виннипег, Брандон, Томпсон',
                    },
                    {
                        id: 'America/Cancun',
                        name: '(UTC-05:00) Канкун, Четумаль, Плайя-дель-Кармен',
                    },
                    {
                        id: 'America/Menominee',
                        name: '(UTC-05:00) Мемфис, Джексон, Кливленд',
                    },
                    {
                        id: 'America/Lima',
                        name: '(UTC-05:00) Лима, Панама, Трухильо',
                    },
                    {
                        id: 'America/North_Dakota/Center',
                        name: '(UTC-05:00) Хьюстон, Сан-Антонио, Даллас',
                    },
                    {
                        id: 'America/Manaus',
                        name: '(UTC-04:00) Манаус, Боа-Виста, Паринтинс',
                    },
                    {
                        id: 'America/La_Paz',
                        name: '(UTC-04:00) Санта-Крус-де-ла-Сьерра, Эль-Альто, Кочабамба',
                    },
                    {
                        id: 'America/Grenada',
                        name: '(UTC-04:00) Сент-Джорджес',
                    },
                    {
                        id: 'America/New_York',
                        name: '(UTC-04:00) Нью-Йорк, Цицеро, Филадельфия',
                    },
                    {
                        id: 'America/Nassau',
                        name: '(UTC-04:00) Нассау, Фрипорт, Джордж Таун',
                    },
                    {
                        id: 'America/Iqaluit',
                        name: '(UTC-04:00) Икалуит',
                    },
                    {
                        id: 'America/Indiana/Marengo',
                        name: '(UTC-04:00) Индианаполис, Кармел, Кокомо',
                    },
                    {
                        id: 'America/Curacao',
                        name: '(UTC-04:00) Виллемстад, Филипсбург, Гран-Каз',
                    },
                    {
                        id: 'America/St_Vincent',
                        name: '(UTC-04:00) Кингстаун',
                    },
                    {
                        id: 'America/Detroit',
                        name: '(UTC-04:00) Детройт, Гранд-Рапидс, Уоррен',
                    },
                    {
                        id: 'America/Grand_Turk',
                        name: '(UTC-04:00) Коберн-Таун',
                    },
                    {
                        id: 'America/Puerto_Rico',
                        name: '(UTC-04:00) Сан-Хуан, Каролина, Понсе',
                    },
                    {
                        id: 'America/Indianapolis',
                        name: '(UTC-04:00) Саут-Бенд, Блумингтон, Мунчи',
                    },
                    {
                        id: 'America/Thunder_Bay',
                        name: '(UTC-04:00) Тандер-Бей',
                    },
                    {
                        id: 'America/Antigua',
                        name: '(UTC-04:00) Сент-Джонс',
                    },
                    {
                        id: 'America/Montreal',
                        name: '(UTC-04:00) Квебек, Монреаль, Лаваль',
                    },
                    {
                        id: 'America/Santo_Domingo',
                        name: '(UTC-04:00) Санто-Доминго, Сантьяго-де-лос-Кабальерос, Санто-Доминго-Есте',
                    },
                    {
                        id: 'America/St_Kitts',
                        name: '(UTC-04:00) Бастер',
                    },
                    {
                        id: 'America/Anguilla',
                        name: '(UTC-04:00) Валли',
                    },
                    {
                        id: 'America/Port-au-Prince',
                        name: '(UTC-04:00) Порт-о-Пренс, Гонаив, Кап-Аитьен',
                    },
                    {
                        id: 'America/Kralendijk',
                        name: '(UTC-04:00) Кралендейк',
                    },
                    {
                        id: 'America/Campo_Grande',
                        name: '(UTC-04:00) Кампу-Гранди, Дорадус, Корумба',
                    },
                    {
                        id: 'America/Porto_Velho',
                        name: '(UTC-04:00) Порту-Велью, Жи-Парана, Вильена',
                    },
                    {
                        id: 'America/St_Barthelemy',
                        name: '(UTC-04:00) Густавия',
                    },
                    {
                        id: 'America/Guyana',
                        name: '(UTC-04:00) Джорджтаун, Нью-Амстердам',
                    },
                    {
                        id: 'America/Caracas',
                        name: '(UTC-04:00) Каракас, Валенсия, Маракай',
                    },
                    {
                        id: 'America/Port_of_Spain',
                        name: '(UTC-04:00) Порт-оф-Спейн',
                    },
                    {
                        id: 'America/Montserrat',
                        name: '(UTC-04:00) Плимут',
                    },
                    {
                        id: 'America/Tortola',
                        name: '(UTC-04:00) Род-Таун, Кристианстед',
                    },
                    {
                        id: 'America/Indiana/Vevay',
                        name: '(UTC-04:00) Форт-Уэйн, Терр Хот',
                    },
                    {
                        id: 'America/Aruba',
                        name: '(UTC-04:00) Ораньестад, Ноорд, Пальм Бич',
                    },
                    {
                        id: 'America/Louisville',
                        name: '(UTC-04:00) Колумбус, Луисвилл, Лексингтон',
                    },
                    {
                        id: 'America/Barbados',
                        name: '(UTC-04:00) Бриджтаун',
                    },
                    {
                        id: 'America/Guadeloupe',
                        name: '(UTC-04:00) Ле-Гозье, Пуант-а-Питр, Сент-Франсуа',
                    },
                    {
                        id: 'America/Cuiaba',
                        name: '(UTC-04:00) Куяба, Варзеа-Гранди, Рондонополис',
                    },
                    {
                        id: 'America/Dominica',
                        name: '(UTC-04:00) Фор-де-Франс, Кастри, Грос Ислет',
                    },
                    {
                        id: 'America/St_Thomas',
                        name: '(UTC-04:00) Шарлотта-Амалия',
                    },
                    {
                        id: 'America/Kentucky/Monticello',
                        name: '(UTC-04:00) Атланта',
                    },
                    {
                        id: 'America/Havana',
                        name: '(UTC-04:00) Гавана, Тринидад, Сантьяго-де-Куба',
                    },
                    {
                        id: 'America/Toronto',
                        name: '(UTC-04:00) Торонто, Оттава, Миссиссага',
                    },
                    {
                        id: 'America/Goose_Bay',
                        name: '(UTC-03:00) Хеппи-Валли-Гуз-Бэй, Вабуш, Нейн',
                    },
                    {
                        id: 'Atlantic/Bermuda',
                        name: '(UTC-03:00) Сент-Джордж, Гамильтон',
                    },
                    {
                        id: 'America/Maceio',
                        name: '(UTC-03:00) Салвадор, Масейо, Натал',
                    },
                    {
                        id: 'America/Santiago',
                        name: '(UTC-03:00) Сантьяго, Антофагаста, Винья-дель-Мар',
                    },
                    {
                        id: 'America/Halifax',
                        name: '(UTC-03:00) Галифакс, Сент-Джон, Дартмут',
                    },
                    {
                        id: 'America/Fortaleza',
                        name: '(UTC-03:00) Форталеза, Аракажу, Каукая',
                    },
                    {
                        id: 'America/Cayenne',
                        name: '(UTC-03:00) Парамарибо, Кайенна',
                    },
                    {
                        id: 'America/Recife',
                        name: '(UTC-03:00) Ресифи, Терезина, Жабоатан-дус-Гуарарапес',
                    },
                    {
                        id: 'America/Sao_Paulo',
                        name: '(UTC-03:00) Сан-Паулу, Рио-де-Жанейро, Бразилиа',
                    },
                    {
                        id: 'America/Godthab',
                        name: '(UTC-03:00) Нуук, Какорток, Маниитсок',
                    },
                    {
                        id: 'America/Santarem',
                        name: '(UTC-03:00) Сан-Луис, Жуан-Песоа, Кампина-Гранди',
                    },
                    {
                        id: 'America/Asuncion',
                        name: '(UTC-03:00) Асунсьон, Сьюдад-дель-Эсте, Энкарнасьон',
                    },
                    {
                        id: 'America/Montevideo',
                        name: '(UTC-03:00) Монтевидео, Сальто, Пайсанду',
                    },
                    {
                        id: 'America/Argentina/Buenos_Aires',
                        name: '(UTC-03:00) Буэнос-Айрес, Кордова, Росарио',
                    },
                    {
                        id: 'America/Belem',
                        name: '(UTC-03:00) Белен, Ананиндеуа, Сантарен',
                    },
                    {
                        id: 'America/St_Johns',
                        name: '(UTC-02:30) Сент-Джонс, Корнер-Брук, Гандер',
                    },
                    {
                        id: 'America/Noronha',
                        name: '(UTC-02:00) Фернанду-ди-Норонья',
                    },
                    {
                        id: 'Atlantic/Azores',
                        name: '(UTC-01:00) Орта',
                    },
                    {
                        id: 'Atlantic/Cape_Verde',
                        name: '(UTC-01:00) Прая, Сан-Филип, Минделу',
                    },
                    {
                        id: 'Africa/Bamako',
                        name: '(UTC+00:00) Бамако, Уагадугу, Бобо-Диуласо',
                    },
                    {
                        id: 'Africa/Monrovia',
                        name: '(UTC+00:00) Монровия',
                    },
                    {
                        id: 'Africa/Dakar',
                        name: '(UTC+00:00) Дакар, Мбур, Сен-Луи',
                    },
                    {
                        id: 'Africa/Accra',
                        name: '(UTC+00:00) Аккра, Кумаси, Тамале',
                    },
                    {
                        id: 'Africa/Freetown',
                        name: '(UTC+00:00) Фритаун, Бо, Макени',
                    },
                    {
                        id: 'Africa/Nouakchott',
                        name: '(UTC+00:00) Нуакшот, Нуадибу, Росо',
                    },
                    {
                        id: 'Atlantic/Faeroe',
                        name: '(UTC+00:00) Киркьюбур',
                    },
                    {
                        id: 'Africa/Conakry',
                        name: '(UTC+00:00) Конакри, Канкан, Лабе',
                    },
                    {
                        id: 'Atlantic/Reykjavik',
                        name: '(UTC+00:00) Вестманнаэйяр',
                    },
                    {
                        id: 'Africa/Abidjan',
                        name: '(UTC+00:00) Абиджан, Буаке, Сан-Педро',
                    },
                    {
                        id: 'Africa/Sao_Tome',
                        name: '(UTC+00:00) Сан-Томе',
                    },
                    {
                        id: 'Africa/Bissau',
                        name: '(UTC+00:00) Бисау, Сан-Домингус, Бафата',
                    },
                    {
                        id: 'Europe/Dublin',
                        name: '(UTC+00:00) Дублин, Свордс, Дроэда',
                    },
                    {
                        id: 'Africa/Banjul',
                        name: '(UTC+00:00) Банжул, Фарафенни',
                    },
                    {
                        id: '',
                        name: '(UTC+00:00) Танджунгселор, Мамуджу, Атафу',
                    },
                    {
                        id: 'Europe/Lisbon',
                        name: '(UTC+00:00) Вила-Нова-ди-Гая, Матозиньюш, Уэйраш',
                    },
                    {
                        id: 'Africa/Casablanca',
                        name: '(UTC+00:00) Касабланка, Фес, Рабат',
                    },
                    {
                        id: 'Africa/El_Aaiun',
                        name: '(UTC+00:00) Эль-Аюн, Бир-Лелу',
                    },
                    {
                        id: 'Africa/Lome',
                        name: '(UTC+00:00) Ломе, Кара, Кпалиме',
                    },
                    {
                        id: 'Atlantic/Canary',
                        name: '(UTC+00:00) Лас-Пальмас-де-Гран-Канария, Санта-Крус-де-Тенерифе, Арона',
                    },
                    {
                        id: 'Europe/London',
                        name: '(UTC+00:00) Лондон, Бирмингем, Лидс',
                    },
                    {
                        id: 'Africa/Bangui',
                        name: '(UTC+01:00) Банги, Бамбари, Нола',
                    },
                    {
                        id: 'Europe/Vienna',
                        name: '(UTC+01:00) Вена, Вельс, Винер-Нойштадт',
                    },
                    {
                        id: 'Europe/Oslo',
                        name: '(UTC+01:00) Саннес, Олесунн, Стрюн',
                    },
                    {
                        id: 'Europe/Tirane',
                        name: '(UTC+01:00) Саранда, Химара, Тирана',
                    },
                    {
                        id: 'Africa/Porto-Novo',
                        name: '(UTC+01:00) Котону, Джугу, Порто-Ново',
                    },
                    {
                        id: 'Europe/Budapest',
                        name: '(UTC+01:00) Будапешт, Шопрон, Хайдусобосло',
                    },
                    {
                        id: 'Europe/Bratislava',
                        name: '(UTC+01:00) Липтовски-Микулаш, Братислава',
                    },
                    {
                        id: 'Europe/Belgrade',
                        name: '(UTC+01:00) Белград, Лесковац, Биела',
                    },
                    {
                        id: 'Europe/Zagreb',
                        name: '(UTC+01:00) Риека, Винковцы, Пореч',
                    },
                    {
                        id: 'Africa/Niamey',
                        name: '(UTC+01:00) Ниамей, Зиндер, Тахуа',
                    },
                    {
                        id: 'Europe/Vaduz',
                        name: '(UTC+01:00) Вадуц',
                    },
                    {
                        id: 'Europe/Stockholm',
                        name: '(UTC+01:00) Лунд, Бурос, Хельсингборг',
                    },
                    {
                        id: 'Europe/Prague',
                        name: '(UTC+01:00) Прага, Йиглава, Байё',
                    },
                    {
                        id: 'Africa/Kinshasa',
                        name: '(UTC+01:00) Киншаса, Луанда, Мбужи-Майи',
                    },
                    {
                        id: 'Africa/Tunis',
                        name: '(UTC+01:00) Тунис, Сфакс, Бизерта',
                    },
                    {
                        id: 'Europe/Monaco',
                        name: '(UTC+01:00) Монако',
                    },
                    {
                        id: 'Europe/Paris',
                        name: '(UTC+01:00) Париж, Марсель, Лион',
                    },
                    {
                        id: 'Europe/Warsaw',
                        name: '(UTC+01:00) Варшава, Лодзь, Краков',
                    },
                    {
                        id: 'Europe/Brussels',
                        name: '(UTC+01:00) Шарлеруа, Алст, Кортрейк',
                    },
                    {
                        id: 'Africa/Algiers',
                        name: '(UTC+01:00) Алжир, Оран, Константина',
                    },
                    {
                        id: 'Europe/Andorra',
                        name: '(UTC+01:00) Сант-Жулиа-де-Лория, Энкам',
                    },
                    {
                        id: 'Europe/Berlin',
                        name: '(UTC+01:00) Берлин, Гамбург, Мюнхен',
                    },
                    {
                        id: 'Europe/Vatican',
                        name: '(UTC+01:00) Ватикан',
                    },
                    {
                        id: 'Europe/Zurich',
                        name: '(UTC+01:00) Винтертур, Тун, Кройцлинген',
                    },
                    {
                        id: 'Europe/Ljubljana',
                        name: '(UTC+01:00) Марибор, Крань, Целе',
                    },
                    {
                        id: 'Europe/Madrid',
                        name: '(UTC+01:00) Мадрид, Барселона, Валенсия',
                    },
                    {
                        id: 'Africa/Libreville',
                        name: '(UTC+01:00) Либревиль, Порт-Жантиль, Гамба',
                    },
                    {
                        id: 'Europe/Skopje',
                        name: '(UTC+01:00) Зеница, Мостар, Тузла',
                    },
                    {
                        id: 'Europe/Gibraltar',
                        name: '(UTC+01:00) Гибралтар',
                    },
                    {
                        id: 'Europe/Copenhagen',
                        name: '(UTC+01:00) Эсбьерг, Раннерс, Кольдинг',
                    },
                    {
                        id: 'Europe/Rome',
                        name: '(UTC+01:00) Рим, Милан, Неаполь',
                    },
                    {
                        id: 'Europe/Malta',
                        name: '(UTC+01:00) Сент-Полс-Бэй, Меллиеха, Валлетта',
                    },
                    {
                        id: 'Europe/Sarajevo',
                        name: '(UTC+01:00) Сараево',
                    },
                    {
                        id: 'Africa/Ndjamena',
                        name: '(UTC+01:00) Нджамена, Абеше, Файя-Ларжо',
                    },
                    {
                        id: 'Europe/San_Marino',
                        name: '(UTC+01:00) Сан-Марино',
                    },
                    {
                        id: 'Africa/Brazzaville',
                        name: '(UTC+01:00) Браззавиль, Пуэнт-Нуар, Долизи',
                    },
                    {
                        id: 'Africa/Malabo',
                        name: '(UTC+01:00) Бата, Малабо',
                    },
                    {
                        id: 'Europe/Amsterdam',
                        name: '(UTC+01:00) Тилбург, Неймеген, Бреда',
                    },
                    {
                        id: 'Africa/Douala',
                        name: '(UTC+01:00) Дуала, Яунде, Баменда',
                    },
                    {
                        id: 'Africa/Lagos',
                        name: '(UTC+01:00) Лагос, Кано, Ибадан',
                    },
                    {
                        id: 'Europe/Luxembourg',
                        name: '(UTC+01:00) Дикирх, Гревенмахер, Клерво',
                    },
                    {
                        id: 'Europe/Helsinki',
                        name: '(UTC+02:00) Эспоо, Вантаа, Хамина',
                    },
                    {
                        id: 'Europe/Athens',
                        name: '(UTC+02:00) Афины, Пирей, Волос',
                    },
                    {
                        id: 'Africa/Maputo',
                        name: '(UTC+02:00) Мапуту, Нампула, Бейра',
                    },
                    {
                        id: 'Asia/Beirut',
                        name: '(UTC+02:00) Бейрут, Эн-Набатия, Захле',
                    },
                    {
                        id: 'Africa/Johannesburg',
                        name: '(UTC+02:00) Йоханнесбург, Кейптаун, Претория',
                    },
                    {
                        id: 'Africa/Kigali',
                        name: '(UTC+02:00) Кигали',
                    },
                    {
                        id: 'Asia/Amman',
                        name: '(UTC+02:00) Амман, Маан, Акаба',
                    },
                    {
                        id: 'Europe/Vilnius',
                        name: '(UTC+02:00) Вильнюс, Каунас, Клайпеда',
                    },
                    {
                        id: 'Africa/Cairo',
                        name: '(UTC+02:00) Каир, Александрия, Гиза',
                    },
                    {
                        id: 'Asia/Damascus',
                        name: '(UTC+02:00) Алеппо, Дамаск, Эль-Хасака',
                    },
                    {
                        id: 'Africa/Bujumbura',
                        name: '(UTC+02:00) Бужумбура',
                    },
                    {
                        id: 'Asia/Jerusalem',
                        name: '(UTC+02:00) Ришон-ле-Цион, Холон, Рамла',
                    },
                    {
                        id: 'Europe/Tallinn',
                        name: '(UTC+02:00) Таллин, Тарту, Нарва',
                    },
                    {
                        id: 'Africa/Tripoli',
                        name: '(UTC+02:00) Триполи, Бенгази, Аль-Байда',
                    },
                    {
                        id: 'Africa/Windhoek',
                        name: '(UTC+02:00) Виндхук, Уолфиш-Бей, Рунду',
                    },
                    {
                        id: 'Africa/Maseru',
                        name: '(UTC+02:00) Масеру',
                    },
                    {
                        id: 'Europe/Sofia',
                        name: '(UTC+02:00) София, Варна, Тырговиште',
                    },
                    {
                        id: 'Africa/Mbabane',
                        name: '(UTC+02:00) Мбабане, Манзини',
                    },
                    {
                        id: 'Europe/Kiev',
                        name: '(UTC+02:00) Киев, Харьков, Днепр',
                    },
                    {
                        id: 'Europe/Riga',
                        name: '(UTC+02:00) Рига, Даугавпилс, Лиепая',
                    },
                    {
                        id: 'Africa/Harare',
                        name: '(UTC+02:00) Булавайо, Хараре, Гверу',
                    },
                    {
                        id: 'Africa/Gaborone',
                        name: '(UTC+02:00) Габороне, Франсистаун, Молепололе',
                    },
                    {
                        id: 'Asia/Nicosia',
                        name: '(UTC+02:00) Паралимни, Лапитос, Пейя',
                    },
                    {
                        id: 'Asia/Gaza',
                        name: '(UTC+02:00) Рамалла',
                    },
                    {
                        id: 'Europe/Kaliningrad',
                        name: '(UTC+02:00) Калининград, Советск, Черняховск',
                    },
                    {
                        id: 'Europe/Bucharest',
                        name: '(UTC+02:00) Бухарест, Дорохой, Отопени',
                    },
                    {
                        id: 'Africa/Lusaka',
                        name: '(UTC+02:00) Лусака, Лилонгве, Блантайр',
                    },
                    {
                        id: 'Asia/Baghdad',
                        name: '(UTC+03:00) Багдад, Басра, Мосул',
                    },
                    {
                        id: 'Africa/Djibouti',
                        name: '(UTC+03:00) Джибути',
                    },
                    {
                        id: 'Africa/Kampala',
                        name: '(UTC+03:00) Кампала, Джинджа, Энтеббе',
                    },
                    {
                        id: 'Asia/Bahrain',
                        name: '(UTC+03:00) Доха',
                    },
                    {
                        id: 'Africa/Khartoum',
                        name: '(UTC+03:00) Омдурман, Хартум, Ньяла',
                    },
                    {
                        id: 'Indian/Comoro',
                        name: '(UTC+03:00) Морони',
                    },
                    {
                        id: 'Europe/Istanbul',
                        name: '(UTC+03:00) Стамбул, Анкара, Измир',
                    },
                    {
                        id: 'Europe/Moscow',
                        name: '(UTC+03:00) Москва, Санкт-Петербург, Нижний Новгород',
                    },
                    {
                        id: 'Africa/Nairobi',
                        name: '(UTC+03:00) Найроби, Момбаса, Кисуму',
                    },
                    {
                        id: 'Asia/Riyadh',
                        name: '(UTC+03:00) Эр-Рияд, Джидда, Мекка',
                    },
                    {
                        id: 'Africa/Mogadishu',
                        name: '(UTC+03:00) Могадишо, Харгейса, Бербера',
                    },
                    {
                        id: 'Africa/Dar_es_Salaam',
                        name: '(UTC+03:00) Дар-эс-Салам, Мванза, Аруша',
                    },
                    {
                        id: 'Asia/Kuwait',
                        name: '(UTC+03:00) Эль-Кувейт',
                    },
                    {
                        id: 'Africa/Asmera',
                        name: '(UTC+03:00) Асмэра, Массауа',
                    },
                    {
                        id: 'Europe/Minsk',
                        name: '(UTC+03:00) Минск, Гомель, Витебск',
                    },
                    {
                        id: 'Asia/Aden',
                        name: '(UTC+03:00) Сана, Эль-Бейда, Амран',
                    },
                    {
                        id: 'Africa/Addis_Ababa',
                        name: '(UTC+03:00) Аддис-Абеба, Дыре-Дауа, Гондэр',
                    },
                    {
                        id: 'Indian/Antananarivo',
                        name: '(UTC+03:00) Антананариву, Туамасина, Анцирабе',
                    },
                    {
                        id: 'Asia/Muscat',
                        name: '(UTC+04:00) Эс-Сиб, Хайма, Маскат',
                    },
                    {
                        id: 'Asia/Dubai',
                        name: '(UTC+04:00) Дубай, Абу-Даби',
                    },
                    {
                        id: 'Europe/Ulyanovsk',
                        name: '(UTC+04:00) Ульяновск, Димитровград, Инза',
                    },
                    {
                        id: 'Indian/Mauritius',
                        name: '(UTC+04:00) Порт-Луи, Вакоа-Феникс, Виктория',
                    },
                    {
                        id: 'Asia/Baku',
                        name: '(UTC+04:00) Баку, Сумгаит, Гянджа',
                    },
                    {
                        id: 'Europe/Astrakhan',
                        name: '(UTC+04:00) Астрахань, Ахтубинск, Знаменск',
                    },
                    {
                        id: 'Europe/Saratov',
                        name: '(UTC+04:00) Саратов, Энгельс, Балаково',
                    },
                    {
                        id: 'Europe/Samara',
                        name: '(UTC+04:00) Самара, Тольятти, Ижевск',
                    },
                    {
                        id: 'Indian/Reunion',
                        name: '(UTC+04:00) Сен-Дени, Сен-Пьер, Силао',
                    },
                    {
                        id: 'Asia/Tbilisi',
                        name: '(UTC+04:00) Тбилиси, Зугдиди, Озургети',
                    },
                    {
                        id: 'Asia/Yerevan',
                        name: '(UTC+04:00) Ереван, Гюмри, Ванадзор',
                    },
                    {
                        id: 'Asia/Kabul',
                        name: '(UTC+04:30) Кабул, Кандагар, Герат',
                    },
                    {
                        id: 'Asia/Tehran',
                        name: '(UTC+04:30) Тегеран, Мешхед, Исфахан',
                    },
                    {
                        id: 'Asia/Dushanbe',
                        name: '(UTC+05:00) Душанбе, Худжанд, Куляб',
                    },
                    {
                        id: 'Indian/Maldives',
                        name: '(UTC+05:00) Адду, Фувахмулах, Кулудуффуши',
                    },
                    {
                        id: 'Asia/Aqtau',
                        name: '(UTC+05:00) Актау',
                    },
                    {
                        id: 'Asia/Tashkent',
                        name: '(UTC+05:00) Ташкент, Наманган, Самарканд',
                    },
                    {
                        id: 'Asia/Karachi',
                        name: '(UTC+05:00) Карачи, Лахор, Фейсалабад',
                    },
                    {
                        id: 'Asia/Aqtobe',
                        name: '(UTC+05:00) Актобе, Уральск, Атырау',
                    },
                    {
                        id: 'Asia/Yekaterinburg',
                        name: '(UTC+05:00) Екатеринбург, Челябинск, Уфа',
                    },
                    {
                        id: 'Asia/Ashgabat',
                        name: '(UTC+05:00) Ашхабад, Туркменабад, Дашогуз',
                    },
                    {
                        id: 'Asia/Kolkata',
                        name: '(UTC+05:30) Мумбаи, Дели, Бангалор',
                    },
                    {
                        id: 'Asia/Colombo',
                        name: '(UTC+05:30) Полоннарува, Дехивала-Маунт-Лавиния, Негомбо',
                    },
                    {
                        id: 'Asia/Kathmandu',
                        name: '(UTC+05:45) Покхара, Бхаратпур, Биргандж',
                    },
                    {
                        id: 'Asia/Bishkek',
                        name: '(UTC+06:00) Бишкек, Ош, Джалал-Абад',
                    },
                    {
                        id: 'Asia/Almaty',
                        name: '(UTC+06:00) Алматы, Астана, Шымкент',
                    },
                    {
                        id: 'Asia/Omsk',
                        name: '(UTC+06:00) Омск, Тара, Исилькуль',
                    },
                    {
                        id: 'Asia/Thimphu',
                        name: '(UTC+06:00) Тхимпху',
                    },
                    {
                        id: 'Asia/Dhaka',
                        name: '(UTC+06:00) Дакка, Читтагонг, Нараянгандж',
                    },
                    {
                        id: 'Indian/Cocos',
                        name: '(UTC+06:30) Уэст-Айленд',
                    },
                    {
                        id: 'Asia/Rangoon',
                        name: '(UTC+06:30) Янгон, Мандалай, Патейн',
                    },
                    {
                        id: 'Asia/Vientiane',
                        name: '(UTC+07:00) Хошимин, Ханой, Чавинь',
                    },
                    {
                        id: 'Asia/Novosibirsk',
                        name: '(UTC+07:00) Новосибирск, Бердск, Искитим',
                    },
                    {
                        id: 'Asia/Krasnoyarsk',
                        name: '(UTC+07:00) Красноярск, Норильск, Абакан',
                    },
                    {
                        id: 'Asia/Tomsk',
                        name: '(UTC+07:00) Томск, Северск, Стрежевой',
                    },
                    {
                        id: 'Asia/Novokuznetsk',
                        name: '(UTC+07:00) Новокузнецк, Кемерово, Прокопьевск',
                    },
                    {
                        id: 'Asia/Bangkok',
                        name: '(UTC+07:00) Бангкок, Самутпракан, Самутсонгкхрам',
                    },
                    {
                        id: 'Asia/Jakarta',
                        name: '(UTC+07:00) Джакарта, Сурабая, Бандунг',
                    },
                    {
                        id: 'Asia/Phnom_Penh',
                        name: '(UTC+07:00) Пномпень, Баттамбанг, Сиануквиль',
                    },
                    {
                        id: 'Asia/Barnaul',
                        name: '(UTC+07:00) Барнаул, Бийск, Рубцовск',
                    },
                    {
                        id: 'Asia/Harbin',
                        name: '(UTC+08:00) Харбин',
                    },
                    {
                        id: 'Asia/Irkutsk',
                        name: '(UTC+08:00) Иркутск, Улан-Удэ, Братск',
                    },
                    {
                        id: 'Asia/Shanghai',
                        name: '(UTC+08:00) Чунцин, Шанхай, Пекин',
                    },
                    {
                        id: 'Asia/Manila',
                        name: '(UTC+08:00) Кесон-Сити, Давао, Манила',
                    },
                    {
                        id: 'Asia/Kuala_Lumpur',
                        name: '(UTC+08:00) Куала-Лумпур, Ипох, Шах-Алам',
                    },
                    {
                        id: 'Asia/Makassar',
                        name: '(UTC+08:00) Макасар, Сукавати, Бау-Бау',
                    },
                    {
                        id: 'Australia/Perth',
                        name: '(UTC+08:00) Перт, Рокингем, Банбери',
                    },
                    {
                        id: 'Asia/Ulaanbaatar',
                        name: '(UTC+08:00) Улан-Батор, Сайншанд, Арвайхээр',
                    },
                    {
                        id: 'Asia/Singapore',
                        name: '(UTC+08:00) Сингапур',
                    },
                    {
                        id: 'Asia/Macau',
                        name: '(UTC+08:00) Макао (Аомынь)',
                    },
                    {
                        id: 'Asia/Brunei',
                        name: '(UTC+08:00) Бандар-Сери-Бегаван',
                    },
                    {
                        id: 'Asia/Taipei',
                        name: '(UTC+08:00) Нанкин',
                    },
                    {
                        id: 'Asia/Pyongyang',
                        name: '(UTC+08:30) Пхеньян, Чхонджин',
                    },
                    {
                        id: 'Asia/Chita',
                        name: '(UTC+09:00) Чита, Краснокаменск, Борзя',
                    },
                    {
                        id: 'Asia/Yakutsk',
                        name: '(UTC+09:00) Якутск, Благовещенск, Белогорск',
                    },
                    {
                        id: 'Asia/Seoul',
                        name: '(UTC+09:00) Сеул, Пусан, Инчхон',
                    },
                    {
                        id: 'Asia/Dili',
                        name: '(UTC+09:00) Дили',
                    },
                    {
                        id: 'Asia/Tokyo',
                        name: '(UTC+09:00) Токио, Йокогама, Осака',
                    },
                    {
                        id: 'Asia/Jayapura',
                        name: '(UTC+09:00) Мерауке',
                    },
                    {
                        id: 'Pacific/Palau',
                        name: '(UTC+09:00) Корор, Нгерулмуд',
                    },
                    {
                        id: 'Australia/Darwin',
                        name: '(UTC+09:30) Дарвин, Алис-Спрингс, Кэтрин',
                    },
                    {
                        id: 'Australia/Brisbane',
                        name: '(UTC+10:00) Брисбен, Голд-Кост, Саншайн-Кост',
                    },
                    {
                        id: 'Pacific/Yap',
                        name: '(UTC+10:00) Вено, Колониа, Паликир',
                    },
                    {
                        id: 'Pacific/Guam',
                        name: '(UTC+10:00) Хагатна',
                    },
                    {
                        id: 'Asia/Vladivostok',
                        name: '(UTC+10:00) Владивосток, Хабаровск, Комсомольск-на-Амуре',
                    },
                    {
                        id: 'Pacific/Port_Moresby',
                        name: '(UTC+10:00) Порт-Морсби, Лаэ, Маунт-Хаген',
                    },
                    {
                        id: 'Australia/Broken_Hill',
                        name: '(UTC+10:30) Брокен Хилл',
                    },
                    {
                        id: 'Australia/Adelaide',
                        name: '(UTC+10:30) Аделаида, Маунт-Гамбир, Уайалла',
                    },
                    {
                        id: 'Pacific/Noumea',
                        name: '(UTC+11:00) Нумеа, Кумак, Ве',
                    },
                    {
                        id: 'Asia/Srednekolymsk',
                        name: '(UTC+11:00) Среднеколымск, Северо-Курильск',
                    },
                    {
                        id: 'Pacific/Efate',
                        name: '(UTC+11:00) Хониара, Порт-Вила, Сола',
                    },
                    {
                        id: 'Australia/Sydney',
                        name: '(UTC+11:00) Сидней, Канберра, Вуллонгонг',
                    },
                    {
                        id: 'Australia/Melbourne',
                        name: '(UTC+11:00) Мельбурн, Джелонг, Балларат',
                    },
                    {
                        id: 'Pacific/Norfolk',
                        name: '(UTC+11:00) Кингстон',
                    },
                    {
                        id: 'Australia/Hobart',
                        name: '(UTC+11:00) Хобарт, Лонсестон, Девонпорт',
                    },
                    {
                        id: 'Asia/Sakhalin',
                        name: '(UTC+11:00) Южно-Сахалинск, Корсаков, Холмск',
                    },
                    {
                        id: 'Asia/Magadan',
                        name: '(UTC+11:00) Магадан, Сусуман',
                    },
                    {
                        id: 'Pacific/Majuro',
                        name: '(UTC+12:00) Маджуро',
                    },
                    {
                        id: 'Pacific/Funafuti',
                        name: '(UTC+12:00) Фунафути',
                    },
                    {
                        id: 'Pacific/Tarawa',
                        name: '(UTC+12:00) Южная Тарава, Байрики',
                    },
                    {
                        id: 'Asia/Kamchatka',
                        name: '(UTC+12:00) Петропавловск-Камчатский, Елизово, Вилючинск',
                    },
                    {
                        id: 'Pacific/Fiji',
                        name: '(UTC+12:00) Сува, Нанди, Лабаса',
                    },
                    {
                        id: 'Pacific/Nauru',
                        name: '(UTC+12:00) Ярен',
                    },
                    {
                        id: 'Pacific/Tongatapu',
                        name: '(UTC+13:00) Нукуалофа, Нейафу, Пангаи',
                    },
                    {
                        id: 'Pacific/Auckland',
                        name: '(UTC+13:00) Окленд, Веллингтон, Крайстчерч',
                    },
                    {
                        id: 'Pacific/Apia',
                        name: '(UTC+14:00) Апиа',
                    }
                ],
            },
            tabSMPP: {
                create: {
                    data: {
                        name: '',
                        system_id: '',
                        password: '',
                        firewall: '',
                    },
                    errors: {
                        name: [],
                        system_id: [],
                        password: [],
                        firewall: [],
                    },
                    progress: false,
                    show: false,
                },
                remove: {
                    data: null,
                    show: false,
                },
                search: {
                    data: [],
                    headers: [
                        {
                            text: 'Название',
                            value: 'name',
                        },
                        {
                            text: 'System ID',
                            value: 'system_id',
                        },
                        {
                            text: 'Пароль',
                            value: 'password',
                        },
                        {
                            text: 'Ограничения по IP',
                            value: 'firewall',
                            sortable: false,
                        },
                        {
                            text: 'Действия',
                            value: 'key',
                            sortable: false,
                        },
                    ],
                    loading: true,
                },
                update: {
                    show: false,
                    data: null,
                    errors: {
                        name: [],
                        system_id: [],
                        password: [],
                        firewall: [],
                    },
                    progress: false,
                },
            },
        }
    },
    created () {
        this.$axios.post('/user/account')
            .then((res) => {
                if (res.data.success) {
                    this.tabProfile.data = res.data.data
                    this.tabProfile.loading = false
                }
            })
            .catch(() => {})

        this.searchApiKeys()
        this.searchSMPP()
    },
    methods: {
        createApiKey: function() {
            this.tabAPI.create.progress = true

            this.tabAPI.create.errors.name = []
            this.tabAPI.create.errors.api = []
            this.tabAPI.create.errors.firewall = []

            this.$axios.post('/user/api-key/create', this.tabAPI.create.data)
                .then((res) => {
                    if (res.data.success) {
                        this.tabAPI.create.show = false
                        this.tabAPI.create.data.name = ''
                        this.tabAPI.create.data.api = ''
                        this.tabAPI.create.data.firewall = ''

                        this.searchApiKeys()
                    } else {
                        this.tabAPI.create.errors.name = res.data.errors.name || []
                        this.tabAPI.create.errors.api = res.data.errors.api || []
                        this.tabAPI.create.errors.firewall = res.data.errors.firewall || []
                    }
                    this.tabAPI.create.progress = false
                })
                .catch(() => {
                    this.tabAPI.create.progress = false
                })
        },

        createSMPP: function() {
            this.tabSMPP.create.progress = true

            this.tabSMPP.create.errors.name = []
            this.tabSMPP.create.errors.system_id = []
            this.tabSMPP.create.errors.password = []
            this.tabSMPP.create.errors.firewall = []

            this.$axios.post('/user/smpp-account/create', this.tabSMPP.create.data)
                .then((res) => {
                    if (res.data.success) {
                        this.tabSMPP.create.show = false
                        this.tabSMPP.create.data.name = ''
                        this.tabSMPP.create.data.system_id = ''
                        this.tabSMPP.create.data.password = ''
                        this.tabSMPP.create.data.firewall = ''

                        this.searchSMPP()
                    } else {
                        this.tabSMPP.create.errors.name = res.data.errors.name || []
                        this.tabSMPP.create.errors.system_id = res.data.errors.system_id || []
                        this.tabSMPP.create.errors.password = res.data.errors.password || []
                        this.tabSMPP.create.errors.firewall = res.data.errors.firewall || []
                    }
                    this.tabSMPP.create.progress = false
                })
                .catch(() => {
                    this.tabSMPP.create.progress = false
                })
        },

        removeApiKey: function () {
            this.$axios.post('/user/api-key/remove', {id: this.tabAPI.remove.data.id})
                .then(() => {
                    this.tabAPI.remove.show = false
                    this.tabAPI.remove.data = null
                    this.searchApiKeys()
                })
                .catch(() => {})
        },

        removeSMPP: function () {
            const data = {
                id: this.tabSMPP.remove.data.id,
            };

            this.$axios.post('/user/smpp-account/remove', data)
                .then(() => {
                    this.tabSMPP.remove.show = false
                    this.searchSMPP()
                })
                .catch(() => {})
        },

        searchApiKeys: function () {
            this.tabAPI.search.data = []
            this.tabAPI.search.loading = true

            this.$axios.post('/user/api-key/search')
                .then((res) => {
                    if (res.data.success) {
                        this.tabAPI.search.data = res.data.data
                        this.tabAPI.search.loading = false
                    }
                })
                .catch(() => {})
        },

        searchSMPP: function () {
            this.tabSMPP.search.data = []
            this.tabSMPP.search.loading = true

            this.$axios.post('/user/smpp-account/search')
                .then((res) => {
                    if (res.data.success) {
                        this.tabSMPP.search.data = res.data.data
                        this.tabSMPP.search.loading = false
                    }
                })
                .catch(() => {})
        },

        updateApiKey: function () {
            this.tabAPI.update.progress = true

            this.tabAPI.update.errors.name = []
            this.tabAPI.update.errors.api = []
            this.tabAPI.update.errors.firewall = []

            this.$axios.post('/user/api-key/update', this.tabAPI.update.data)
                .then((res) => {
                    if (res.data.success) {
                        this.tabAPI.update.show = false
                        this.tabAPI.update.data.name = ''
                        this.tabAPI.update.data.api = ''
                        this.tabAPI.update.data.firewall = ''
                    } else {
                        this.tabAPI.update.errors.name = res.data.errors.name || []
                        this.tabAPI.update.errors.api = res.data.errors.api || []
                        this.tabAPI.update.errors.firewall = res.data.errors.firewall || []
                    }
                    this.tabAPI.update.progress = false

                    this.searchApiKeys()
                })
                .catch(() => {
                    this.tabAPI.update.progress = false
                })
        },

        updatePassword: function () {
            this.tabPassword.progress = true

            this.tabPassword.errors.password_old = []
            this.tabPassword.errors.password_new = []

            this.$axios.post('/user/password/update', this.tabPassword.data)
                .then((res) => {
                    if (res.data.success) {
                        this.tabPassword.success = true
                        this.tabPassword.data.password_old = ''
                        this.tabPassword.data.password_new = ''
                    } else {
                        this.tabPassword.errors.password_old = res.data.errors.password_old || []
                        this.tabPassword.errors.password_new = res.data.errors.password_new || []
                    }
                    this.tabPassword.progress = false
                })
                .catch(() => {
                    this.tabPassword.progress = false
                })
        },

        updateProfile: function () {
            this.tabProfile.progress = true

            this.tabProfile.errors.timezone = []

            this.$axios.post('/user/account/update', {timezone: this.tabProfile.data.timezone})
                .then((res) => {
                    if (res.data.success) {
                        this.tabProfile.success = true
                    } else {
                        this.tabProfile.errors.timezone = res.data.errors.timezone || []
                    }
                    this.tabProfile.progress = false
                })
                .catch(() => {
                    this.tabProfile.progress = false
                })
        },

        updateSMPP: function () {
            this.tabSMPP.update.progress = true

            this.tabSMPP.update.errors.name = []
            this.tabSMPP.update.errors.system_id = []
            this.tabSMPP.update.errors.password = []
            this.tabSMPP.update.errors.firewall = []

            this.$axios.post('/user/smpp-account/update', this.tabSMPP.update.data)
                .then((res) => {
                    if (res.data.success) {
                        this.tabSMPP.update.show = false
                        this.tabSMPP.update.data.name = ''
                        this.tabSMPP.update.data.system_id = ''
                        this.tabSMPP.update.data.password = ''
                        this.tabSMPP.update.data.firewall = ''

                        this.searchSMPP()
                    } else {
                        this.tabSMPP.update.errors.name = res.data.errors.name || []
                        this.tabSMPP.update.errors.system_id = res.data.errors.system_id || []
                        this.tabSMPP.update.errors.password = res.data.errors.password || []
                        this.tabSMPP.update.errors.firewall = res.data.errors.firewall || []
                    }
                    this.tabSMPP.update.progress = false
                })
                .catch(() => {
                    this.tabSMPP.update.progress = false
                })
        },
    },
}
</script>
