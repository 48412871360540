<template>
    <v-container>
        <v-row text-center wrap>
            <v-col xs="12">
                <v-card>
                    <v-toolbar flat class="grey lighten-4">
                        <v-toolbar-title>Черный список</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn
                            @click="create.show = true"
                            color="blue-grey darken-2"
                            dark
                        >
                            <v-icon>mdi-plus</v-icon>
                            Добавить
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-data-table
                            :headers="search.headers"
                            :items="search.data"
                            :loading="search.loading"
                        >
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td class="text-left">{{ item.phone }}</td>
                                    <td class="text-left">{{ item.categoryName }}</td>
                                    <td class="text-left">{{ item.comment }}</td>
                                    <td class="layout">
                                        <v-icon
                                            @click="update.data = {...item}; update.show = true"
                                            class="mr-2"
                                            small
                                        >
                                            mdi-pencil
                                        </v-icon>
                                        <v-icon
                                            @click="remove.data = item; remove.show = true"
                                            small
                                        >
                                            mdi-delete
                                        </v-icon>
                                    </td>
                                </tr>
                            </template>
                            <template v-slot:no-data>
                                <div v-if="search.loading">
                                    Идет загрузка данных
                                </div>
                                <div v-if="!search.loading">
                                    Нет доступных номеров
                                </div>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog
            v-model="create.show"
            persistent
            max-width="500"
        >
            <v-card>
                <v-toolbar flat class="grey lighten-4">
                    <v-toolbar-title>Новая запись</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-form @submit.prevent="createBlacklist()">
                        <v-card-text>
                            <v-select
                                v-model="create.data.category"
                                :items="create.categories"
                                :error-messages="create.errors.category"
                                item-text="name"
                                item-value="category"
                                label="Тип блокировки"
                            >
                            </v-select>
                            <v-text-field
                                v-model="create.data.phone"
                                :error-messages="create.errors.phone"
                                name="phone"
                                label="Номер телефона"
                                type="text">
                            </v-text-field>
                            <v-text-field
                                v-model="create.data.comment"
                                :error-messages="create.errors.comment"
                                name="comment"
                                label="Комментарий"
                                type="text">
                            </v-text-field>
                        </v-card-text>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="create.show = false"
                        color="red darken-1"
                        text
                    >
                        Отменить
                    </v-btn>
                    <v-btn
                        @click="createBlacklist()"
                        color="blue-grey darken-2"
                        dark
                    >
                        Сохранить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-if="remove.data"
            v-model="remove.show"
            max-width="500"
        >
            <v-card>
                <v-card-title class="headline">Удалить номер {{ remove.data.phone }}?</v-card-title>
                <v-card-text>
                    После удаления номера из черного списка вы сможете отправлять на него сообщения.
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="red darken-1"
                        text
                        @click="remove.show = false"
                    >
                        Отменить
                    </v-btn>
                    <v-btn
                        @click="removeBlacklist"
                        color="blue-grey darken-2"
                        dark
                        text
                    >
                        Удалить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-if="update.data"
            v-model="update.show"
            persistent
            max-width="800"
        >
            <v-form @submit.prevent="updateBlacklist">
                <v-card>
                    <v-toolbar flat class="grey lighten-4">
                        <v-toolbar-title>Редактирование номера</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-select
                            v-model="update.data.category"
                            :items="update.categories"
                            :error-messages="update.errors.category"
                            item-text="name"
                            item-value="category"
                            label="Тип блокировки"
                        >
                        </v-select>
                        <v-text-field
                            v-model="update.data.phone"
                            :error-messages="update.errors.phone"
                            name="phone"
                            label="Номер телефона"
                            type="text">
                        </v-text-field>
                        <v-text-field
                            v-model="update.data.comment"
                            :error-messages="update.errors.comment"
                            name="comment"
                            label="Комментарий"
                            type="text">
                        </v-text-field>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            :disabled="update.progress"
                            color="red darken-1"
                            text
                            @click="update.show = false"
                        >
                            Отменить
                        </v-btn>
                        <v-btn
                            :loading="update.progress"
                            type="submit"
                            color="blue-grey darken-2"
                            dark
                        >Сохранить</v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </v-container>
</template>

<script>
export default {
    name: "BlacklistController",
    data () {
        return {
            create: {
                data: {
                    category: '*',
                    comment: '',
                    phone: '',
                },
                errors: {},
                progress: false,
                show: false,
                categories: [
                    {
                        category: '*',
                        name: 'Все виды сообщений',
                    },
                    {
                        category: 'sms_promo',
                        name: 'Рекламные SMS',
                    },
                    {
                        category: 'sms_service',
                        name: 'Сервисные SMS',
                    },
                    {
                        category: 'viber',
                        name: 'Сообщения Viber',
                    },
                ],
            },
            remove: {
                data: null,
                show: false,
            },
            search: {
                data: [],
                headers: [
                    {
                        text: 'Номер',
                        value: 'phone',
                    },
                    {
                        text: 'Категория',
                        value: 'category',
                    },
                    {
                        text: 'Комментарий',
                        value: 'comment',
                    },
                    {
                        text: 'Действия',
                        value: 'phone',
                        sortable: false,
                    },
                ],
                loading: false,
            },
            update: {
                data: {
                    id: null,
                    category: '*',
                    comment: '',
                    phone: '',
                },
                errors: {},
                progress: false,
                show: false,
                categories: [
                    {
                        category: '*',
                        name: 'Все виды сообщений',
                    },
                    {
                        category: 'sms_promo',
                        name: 'Рекламные SMS',
                    },
                    {
                        category: 'sms_service',
                        name: 'Сервисные SMS',
                    },
                    {
                        category: 'viber',
                        name: 'Сообщения Viber',
                    },
                ],
            },
        }
    },
    created: function () {
        this.searchBlacklist()
    },
    methods: {
        createBlacklist: function () {
            this.create.progress = true
            this.create.errors = {};

            this.$axios.post('/blacklist/create', this.create.data)
                .then(res => {
                    if (res.data.success) {
                        this.create.progress = false
                        this.create.show = false
                        this.create.data.category = '*'
                        this.create.data.comment = ''
                        this.create.data.phone = ''
                        this.create.errors = {}
                        this.searchBlacklist()
                    } else {
                        this.create.progress = false
                        this.create.errors = res.data.errors
                    }
                })
                .catch(() => {
                    this.create.progress = false
                })
        },
        removeBlacklist: function () {
            this.$axios.post('/blacklist/remove', this.remove.data)
                .then(() => {
                    this.search.data = []
                    this.remove.show = false
                    this.remove.data = null
                    this.searchBlacklist()
                })
                .catch(() => {})
        },
        searchBlacklist: function () {
            this.search.loading = true

            this.$axios.post('/blacklist/search')
                .then(res => {
                    if (res.data.success) {
                        this.search.data = res.data.data
                    }
                    this.search.loading = false
                })
                .catch(() => {
                    this.search.loading = false
                })
        },
        updateBlacklist: function () {
            this.update.progress = true

            this.update.errors = {}

            this.$axios.post('/blacklist/update', this.update.data)
                .then((res) => {
                    if (res.data.success) {
                        this.update.show = false
                        this.update.data.id = null
                        this.update.data.category = '*'
                        this.update.data.comment = ''
                        this.update.data.phone = ''
                    } else {
                        this.update.errors = res.data.errors
                    }
                    this.update.progress = false

                    this.searchBlacklist()
                })
                .catch(() => {
                    this.update.progress = false
                })
        },
    },
}
</script>

<style scoped>

</style>