<template>
    <v-container
        fluid
        fill-height
    >
        <v-row
            align="center"
            justify="center"
        >
            <v-col xs="12" sm="6" md="4">
                <v-card class="elevation-12">
                    <v-toolbar dark color="primary">
                        <v-toolbar-title>Регистрация</v-toolbar-title>
                    </v-toolbar>
                    <v-form class="js-sign-up" @submit.prevent="captchaValidate">
                        <v-card-text>
                            <v-text-field
                                v-if="stage === 'init'"
                                v-model="data.phone"
                                :error-messages="errors.phone"
                                :disabled="stage !== 'init'"
                                prepend-icon="mdi-phone"
                                name="phone"
                                label="Номер телефона"
                                mask="phone"
                                prefix="+"
                                type="text"
                            />
                            <v-text-field
                                v-if="stage === 'init'"
                                v-model="data.email"
                                :error-messages="errors.email"
                                :disabled="stage !== 'init'"
                                prepend-icon="mdi-email"
                                name="email"
                                label="Почта"
                                type="email"
                            />
                            <v-text-field
                                v-if="stage === 'init'"
                                v-model="data.password"
                                :error-messages="errors.password"
                                :disabled="stage !== 'init'"
                                prepend-icon="mdi-lock"
                                name="password"
                                label="Пароль"
                                type="password"
                            />
                            <v-text-field
                                v-if="stage === 'confirm'"
                                v-model="data.code"
                                :error-messages="errors.code"
                                prepend-icon="mdi-message-text-lock"
                                name="code"
                                label="Код подтверждения из SMS"
                            />
                            <vue-recaptcha
                                ref="recaptcha"
                                size="invisible"
                                :sitekey="recaptcha.siteKey"
                                :loadRecaptchaScript="true"
                                @verify="signUp"
                                @expired="captchaReset"
                            />
                            <v-alert
                                v-if="errors.captcha.length > 0"
                                class="error mt-3 mb-5"
                                dark
                            >
                                Не удалось обработать ваш запрос. Попробуйте обновить страницу и повторить попытку.
                            </v-alert>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn
                                    :disabled="wait"
                                    type="submit"
                                    color="primary"
                                >
                                    Отправить
                                </v-btn>
                            </v-card-actions>
                        </v-card-text>
                        <v-divider/>
                        <v-card-actions>
                            <v-btn
                                to="/password-recovery"
                                text
                                small
                            >
                                Восстановить пароль
                            </v-btn>
                            <v-spacer/>
                            <v-btn
                                to="/sign-in"
                                text
                                small
                            >
                                Войти в личный кабинет
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
        <v-snackbar
            v-model="snackbar"
            color="error"
        >
            При обработке запроса произошла ошибка

            <template v-slot:action="{ attrs }">
                <v-btn
                    icon
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha'

export default {
    name: 'SignUpController',
    components: { VueRecaptcha },
    data () {
        return {
            data: {
                code: '',
                email: '',
                password: '',
                phone: '',
            },
            errors: {
                captcha: [],
                code: [],
                email: [],
                password: [],
                phone: [],
            },
            recaptcha: {
                siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
            },
            snackbar: false,
            stage: 'init',
            wait: false,
        }
    },
    methods: {
        captchaReset () {
            this.$refs.recaptcha.reset()
        },

        captchaValidate () {
            this.$refs.recaptcha.execute()
        },

        signUp (recaptchaToken) {
            this.stage === 'init' ? this.signUpInit(recaptchaToken) : this.signUpConfirm(recaptchaToken)
        },

        signUpConfirm (recaptchaToken) {
            this.wait = true
            this.snackbar = false

            this.$axios.post('/sign-up/confirm', {captcha: recaptchaToken, ...this.data})
                .then(res => {
                    if (res.data.success) {
                        this.$axios.defaults.headers.common['Authorization'] = res.data.data
                        this.$store.commit('updateToken', res.data.data)
                        this.$router.push('/')
                    } else {
                        this.errors.captcha = res.data.errors.captcha || []
                        this.errors.code = res.data.errors.code || []
                        this.errors.email = res.data.errors.email || []
                        this.errors.password = res.data.errors.password || []
                        this.errors.phone = res.data.errors.phone || []

                        this.captchaReset()
                    }

                    this.wait = false
                })
                .catch(() => {
                    this.captchaReset()

                    this.snackbar = true
                    this.wait = false
                })
        },

        signUpInit (recaptchaToken) {
            this.wait = true
            this.snackbar = false

            this.$axios.post('/sign-up/init', {captcha: recaptchaToken, ...this.data})
                .then(res => {
                    if (res.data.success) {
                        this.stage = 'confirm'
                    } else {
                        this.errors.captcha = res.data.errors.captcha || []
                        this.errors.email = res.data.errors.email || []
                        this.errors.password = res.data.errors.password || []
                        this.errors.phone = res.data.errors.phone || []
                    }

                    this.captchaReset()

                    this.wait = false
                })
                .catch(() => {
                    this.captchaReset()

                    this.snackbar = true
                    this.wait = false
                })
        },
    },
}
</script>
