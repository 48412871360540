<template>
    <v-container
        fluid
        fill-height
    >
        <v-row
            align="center"
            justify="center"
        >
            <v-col xs="12" sm="6" md="4">
                <v-card class="elevation-12">
                    <v-toolbar dark color="primary">
                        <v-toolbar-title>Восстановление пароля</v-toolbar-title>
                    </v-toolbar>
                    <v-form @submit.prevent="captchaValidate">
                        <v-card-text>
                            <p class="ma-5 text-center" v-if="success">
                                На ваш телефон отправлен новый пароль
                            </p>
                            <v-text-field
                                v-if="!success && stage === 'init'"
                                v-model="data.phone"
                                :error-messages="errors.phone"
                                :disabled="stage !== 'init'"
                                prepend-icon="mdi-phone"
                                name="phone"
                                label="Номер телефона"
                                mask="phone"
                                prefix="+"
                                type="text"
                            />
                            <v-text-field
                                v-if="!success && stage === 'confirm'"
                                v-model="data.code"
                                :error-messages="errors.code"
                                prepend-icon="mdi-message-text-lock"
                                name="code"
                                label="Код подтверждения из SMS"
                                type="email"
                            />
                            <vue-recaptcha
                                ref="recaptcha"
                                size="invisible"
                                :sitekey="recaptcha.siteKey"
                                :loadRecaptchaScript="true"
                                @verify="passwordRecovery"
                                @expired="captchaReset"
                            />
                            <v-alert
                                v-if="!success && errors.captcha.length > 0"
                                class="error mt-3 mb-5"
                                dark
                            >
                                Не удалось обработать ваш запрос. Попробуйте обновить страницу и повторить попытку.
                            </v-alert>
                            <v-card-actions
                                v-if="!success"
                            >
                                <v-spacer/>
                                <v-btn
                                    :disabled="wait"
                                    type="submit"
                                    color="primary"
                                >Отправить</v-btn>
                            </v-card-actions>
                        </v-card-text>
                        <v-divider/>
                        <v-card-actions>
                            <v-btn
                                to="/sign-up"
                                text
                                small
                            >
                                Зарегистрироваться
                            </v-btn>
                            <v-spacer/>
                            <v-btn
                                to="/sign-in"
                                text
                                small
                            >
                                Войти в личный кабинет
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha'

export default {
    name: 'PasswordRecoveryController',
    components: { VueRecaptcha },
    data () {
        return {
            data: {
                code: '',
                phone: '',
            },
            errors: {
                captcha: [],
                code: [],
                phone: [],
            },
            recaptcha: {
                siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
            },
            snackbar: false,
            stage: 'init',
            success: false,
            wait: false,
        }
    },
    methods: {
        captchaReset () {
            this.$refs.recaptcha.reset()
        },

        captchaValidate () {
            this.$refs.recaptcha.execute()
        },

        passwordRecovery (recaptchaToken) {
            this.stage === 'init' ? this.passwordRecoveryInit(recaptchaToken) : this.passwordRecoveryConfirm(recaptchaToken)
        },

        passwordRecoveryConfirm (recaptchaToken) {
            this.wait = true

            this.$axios.post('/password-recovery/confirm', {captcha: recaptchaToken, ...this.data})
                .then(res => {
                    if (res.data.success) {
                        this.success = true
                    } else {
                        this.errors.captcha = res.data.errors.captcha || []
                        this.errors.code = res.data.errors.code || []
                        this.errors.phone = res.data.errors.phone || []

                        this.captchaReset()
                    }

                    this.wait = false
                })
                .catch(() => {
                    this.captchaReset()

                    this.snackbar = true
                    this.wait = false
                })
        },

        passwordRecoveryInit (recaptchaToken) {
            this.wait = true

            this.$axios.post('/password-recovery/init', {captcha: recaptchaToken, ...this.data})
                .then(res => {
                    if (res.data.success) {
                        this.stage = 'confirm'
                    } else {
                        this.errors.captcha = res.data.errors.captcha || []
                        this.errors.phone = res.data.errors.phone || []
                    }

                    this.captchaReset()

                    this.wait = false
                })
                .catch(() => {
                    this.captchaReset()

                    this.snackbar = true
                    this.wait = false
                })
        },
    },
}
</script>
