<template>
    <v-container>
        <v-row text-center wrap>
            <v-col xs="12">
                <v-card>
                    <v-toolbar flat class="grey lighten-4">
                        <v-toolbar-title>Списки получателей</v-toolbar-title>
                        <v-spacer/>
                        <v-btn
                            @click="createPhonebookPopup"
                            color="blue-grey darken-2"
                            dark
                        >
                            <v-icon>mdi-plus</v-icon>
                            Добавить
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-data-table
                            :headers="search.headers"
                            :items="search.phonebooks"
                            :loading="search.loading"
                        >
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td class="text-left">{{ item.name }}</td>
                                    <td class="text-left">{{ item.num | currency('', 0, { thousandsSeparator: ' ' }) }}</td>
                                    <td class="layout pt-4">
                                        <v-btn
                                            @click="viewPhonebook(item)"
                                            class="mr-2"
                                            icon
                                            x-small
                                        >
                                            <v-icon>mdi-view-list</v-icon>
                                        </v-btn>
                                        <v-btn
                                            @click="updatePhonebookPopup(item)"
                                            class="mr-2"
                                            icon
                                            x-small
                                        >
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-btn>
                                        <v-btn
                                            @click="removePhonebookPopup(item)"
                                            icon
                                            x-small
                                        >
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </template>
                            <template v-slot:no-data>
                                <div v-if="search.loading">
                                    Идет загрузка данных
                                </div>
                                <div v-if="!search.loading">
                                    Нет доступных списков
                                </div>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
                <v-card
                    v-if="view.show"
                    class="mt-4"
                >
                    <v-toolbar flat class="grey lighten-4">
                        <v-toolbar-title>Список получателей {{ view.phonebook.name }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn
                            @click="view.show = false"
                            color="default"
                            icon
                        >
                            <v-icon>mdi-chevron-up</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-data-table
                            :headers="view.headers"
                            :items="view.data"
                            :loading="view.loading"
                            :options.sync="view.options"
                            :server-items-length="view.phonebook.num"
                            :footer-props="view.footer"
                            must-sort
                        >
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td class="text-left">+{{ item.phone }}</td>
                                    <td class="text-left">{{ item.last_name }}</td>
                                    <td class="text-left">{{ item.first_name }}</td>
                                    <td class="text-left">{{ item.middle_name }}</td>
                                    <td class="text-left">{{ item.birthday }}</td>
                                    <td class="text-left">{{ item.gender === 'male' ? 'Мужской' : (item.gender ? 'Женский' : '') }}</td>
                                </tr>
                            </template>
                            <template v-slot:no-data>
                                <div v-if="view.loading">
                                    Идет загрузка данных
                                </div>
                                <div v-if="!view.loading">
                                    Не найдены номера
                                </div>
                            </template>
                            <template v-slot:no-results>
                                <div v-if="view.loading">
                                    Идет загрузка данных
                                </div>
                                <div v-if="!view.loading">
                                    Не найдены номера
                                </div>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog
            v-if="create.data"
            v-model="create.show"
            max-width="500"
        >
            <v-card>
                <v-card-title class="headline">Новый список получателей</v-card-title>
                <v-form
                    @submit.prevent="createPhonebook"
                    autocomplete="off"
                >
                    <v-card-text>
                        <v-text-field
                            v-model="create.data.name"
                            :error-messages="create.errors.name"
                            :disabled="create.progress"
                            name="name"
                            label="Название"
                            type="text"
                        />
                        <v-text-field
                            v-model="create.data.filename"
                            :error-messages="create.errors.file"
                            :readonly="!!create.data.filename"
                            :disabled="create.progress"
                            name="file"
                            label="Файл"
                            type="text"
                            clearable
                            @click="createPhonebookFileSelect"
                            @click:clear="createPhonebookFileReset"
                        />
                        <input
                            v-if="create.show && upload.phonebook"
                            type="file"
                            style="display: none"
                            ref="phonebook"
                            accept=".csv,.txt,.xls,.xlsx"
                            @change="createPhonebookFileSelected"
                        />
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer/>
                        <v-btn
                            @click="create.show = false"
                            :disabled="create.progress"
                            text
                            color="error"
                        >
                            Отменить
                        </v-btn>
                        <v-btn
                            :loading="create.progress"
                            type="submit"
                            color="blue-grey darken-2"
                            dark
                        >
                            Сохранить
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
        <v-dialog
            v-if="remove.data"
            v-model="remove.show"
            max-width="500"
        >
            <v-card>
                <v-card-title class="headline">Удалить список {{ remove.data.name }}?</v-card-title>
                <v-card-text>
                    Удаление не отразится на уже запланированных рассылках.
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn
                        @click="remove.show = false"
                        :disabled="remove.progress"
                        color="red darken-1"
                        text
                    >
                        Отменить
                    </v-btn>
                    <v-btn
                        @click="removePhonebook"
                        :loading="remove.progress"
                        color="blue-grey darken-2"
                        dark
                        text
                    >
                        Удалить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-if="update.data"
            v-model="update.show"
            max-width="500"
        >
            <v-card>
                <v-card-title class="headline">Редактирование списка</v-card-title>
                <v-form @submit.prevent="updatePhonebook">
                    <v-card-text>
                        <v-text-field
                            v-model="update.data.name"
                            :error-messages="update.errors.name"
                            name="name"
                            label="Название"
                            type="text"
                        />
                        <v-card-actions>
                            <v-spacer/>
                            <v-btn
                                :disabled="update.progress"
                                color="red darken-1"
                                text
                                @click="update.show = false"
                            >
                                Отменить
                            </v-btn>
                            <v-btn
                                :loading="update.progress"
                                type="submit"
                                color="blue-grey darken-2"
                                dark
                            >Сохранить</v-btn>
                        </v-card-actions>
                    </v-card-text>
                </v-form>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
export default {
    name: "PhonebookController",
    data () {
        return {
            create: {
                data: {
                    filename: '',
                    file: '',
                    name: '',
                },
                errors: {
                    file: [],
                    name: [],
                },
                progress: false,
                show: false,
            },
            remove: {
                data: null,
                progress: false,
                show: false,
            },
            search: {
                headers: [
                    {
                        text: 'Подпись',
                        value: 'signature',
                    },
                    {
                        text: 'Кол-во номеров',
                        value: 'num',
                    },
                    {
                        text: 'Действия',
                        value: 'signature',
                        sortable: false,
                    },
                ],
                loading: false,
                data: [],
            },
            update: {
                data: {
                    name: '',
                },
                errors: {
                    name: [],
                },
                progress: false,
                show: false,
            },
            upload: {
                phonebook: true,
            },
            view: {
                data: [],
                footer: {
                    'items-per-page-options': [10, 25, 50, 100],
                    'items-per-page-text': "Строк на странице",
                },
                headers: [
                    {
                        text: 'Телефон',
                        value: 'phone',
                    },
                    {
                        text: 'Фамилия',
                        value: 'last_name',
                    },
                    {
                        text: 'Имя',
                        value: 'first_name',
                    },
                    {
                        text: 'Отчество',
                        value: 'middle_name',
                    },
                    {
                        text: 'Дата рождения',
                        value: 'birthday',
                    },
                    {
                        text: 'Пол',
                        value: 'gender',
                    },
                ],
                loading: false,
                options: {},
                phonebook: null,
                show: false,
            },
        }
    },

    created () {
        this.searchPhonebooks()
    },

    watch: {
        'view.options': {
            handler () {
                this.searchPhonebookEntries()
            },
            deep: true,
        }
    },

    methods: {
        createPhonebook () {
            this.create.progress = true

            const data = new FormData;
            data.append('name', this.create.data.name)
            data.append('file', this.create.data.file)

            this.$axios.post('/phonebook/create', data)
                .then(res => {
                    if (res.data.success) {
                        this.create.progress = false
                        this.create.show = false
                        this.create.data.file = null
                        this.create.data.filename = ''
                        this.create.data.name = ''
                        this.create.errors.file = []
                        this.create.errors.name = []
                        this.searchPhonebooks()
                    } else {
                        this.create.errors.file = res.data.errors.file || []
                        this.create.errors.name = res.data.errors.name || []
                        this.create.progress = false
                    }
                })
                .catch(() => {
                    this.create.progress = false
                })
        },

        createPhonebookFileReset () {
            this.create.data.file = null
            this.create.data.filename = ''
            this.upload.phonebook = false
            this.$nextTick(() => {
                this.upload.phonebook = true
            })
        },

        createPhonebookFileSelect () {
            this.$refs.phonebook.click()
        },

        createPhonebookFileSelected (e) {
            const files = e.target.files

            if (files[0] === undefined) {
                this.create.data.file = ''
                this.create.data.filename = ''
                return
            }

            this.create.data.file = files[0]
            this.create.data.filename = files[0].name
        },

        createPhonebookPopup () {
            this.view.show = false
            this.view.phonebook = null
            this.create.show = true
        },

        removePhonebook () {
            this.remove.progress = true

            const data = {
                id: this.remove.data.id,
            };

            this.$axios.post('/phonebook/remove', data)
                .then(() => {
                    this.search.phonebooks = []
                    this.remove.show = false
                    this.remove.progress = false
                    this.searchPhonebooks()
                })
                .catch(() => {
                    this.remove.progress = false
                })
        },

        removePhonebookPopup (data) {
            this.remove.data = data
            this.remove.show = true
            this.view.data = []
            this.view.show = false
            this.view.phonebook = null
        },

        searchPhonebookEntries () {
            if (this.view.loading) {
                return;
            }

            this.view.loading = true

            const data = {
                phonebook_id: this.view.phonebook.id,
                ...this.view.options,
            }

            this.$axios.post('/phonebook/entry/search', data)
                .then(res => {
                    if (res.data.success) {
                        this.view.data = res.data.data
                    } else {
                        this.view.data = []
                    }

                    this.view.loading = false
                })
                .catch(() => {
                    this.view.data = []
                    this.view.loading = false
                })
        },

        searchPhonebooks () {
            this.search.loading = true

            this.view.data = []
            this.view.show = false

            this.$axios.post('/phonebook/search')
                .then(res => {
                    if (res.data.success) {
                        this.search.phonebooks = res.data.data
                    }
                    this.search.loading = false
                })
                .catch(() => {
                    this.search.loading = false
                })
        },

        updatePhonebook () {
            this.update.progress = true

            this.update.errors.name = []

            this.$axios.post('/phonebook/update', this.update.data)
                .then((res) => {
                    if (res.data.success) {
                        this.update.show = false
                        this.update.data.id = ''
                        this.update.data.name = ''
                    } else {
                        this.update.errors.name = res.data.errors.name || []
                    }
                    this.update.progress = false

                    this.searchPhonebooks()
                })
                .catch(() => {
                    this.update.progress = false
                })
        },

        updatePhonebookPopup (data) {
            this.update.data = {...data}
            this.update.show = true
            this.view.data = []
            this.view.show = false
        },

        viewPhonebook (data) {
            this.view.phonebook = data
            this.view.show = true
            if (this.view.options.page === 1) {
                this.view.data = []
                this.searchPhonebookEntries()
            } else {
                this.view.options.page = 1
            }
            this.view.pages = Math.ceil(data.num / this.view.options.itemsPerPage)
        },
    },
}
</script>
