import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import Blacklist from '../components/controllers/BlacklistController.vue'
import Config from '../components/controllers/ConfigController.vue'
import Finance from '../components/controllers/FinanceController.vue'
import Message from '../components/controllers/MessageController.vue'
import PasswordRecovery from '../components/controllers/PasswordRecoveryController.vue'
import Phonebook from '../components/controllers/PhonebookController.vue'
import Report from '../components/controllers/ReportController.vue'
import Signature from '../components/controllers/SignatureController.vue'
import SignIn from '../components/controllers/SignInController.vue'
import SignUp from '../components/controllers/SignUpController.vue'
import Support from '../components/controllers/SupportController.vue'
import Template from '../components/controllers/TemplateController.vue'

Vue.use(Router)

const isUser = (to, from, next) => {
    if (store.getters.isUser) {
        next()
        return
    }
    next('/sign-in')
}

const isGuest = (to, from, next) => {
    if (!store.getters.isUser) {
        next()
        return
    }
    next('/message')
}

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/blacklist',
            name: 'blacklist',
            component: Blacklist,
            beforeEnter: isUser,
        },
        {
            path: '/config',
            name: 'config',
            component: Config,
            beforeEnter: isUser,
        },
        {
            path: '/finance',
            name: 'finance',
            component: Finance,
            beforeEnter: isUser,
        },
        {
            path: '/password-recovery',
            name: 'password-recovery',
            component: PasswordRecovery,
            beforeEnter: isGuest,
        },
        {
            path: '/phonebook',
            name: 'phonebook',
            component: Phonebook,
            beforeEnter: isUser,
        },
        {
            path: '/report',
            name: 'report',
            component: Report,
            beforeEnter: isUser,
        },
        {
            path: '/message',
            name: 'message',
            component: Message,
            beforeEnter: isUser,
        },
        {
            path: '/signature',
            name: 'signature',
            component: Signature,
            beforeEnter: isUser,
        },
        {
            path: '/sign-in',
            name: 'SignIn',
            component: SignIn,
            beforeEnter: isGuest,
        },
        {
            path: '/sign-up',
            name: 'SignUp',
            component: SignUp,
            beforeEnter: isGuest,
        },
        {
            path: '/support',
            name: 'support',
            component: Support,
            beforeEnter: isUser,
        },
        {
            path: '/template',
            name: 'template',
            component: Template,
            beforeEnter: isUser,
        },
        {
            path: '*',
            beforeEnter: (to, from, next) => {
                next('/sign-in')
            },
        },
    ]
})
