<template>
    <v-window
        v-model="window"
    >
        <v-window-item>
            <v-container>
                <v-card>
                    <v-toolbar
                        flat
                        dense
                        class="pa-auto grey lighten-4"
                    >
                        <v-toolbar-title>Новое обращение</v-toolbar-title>
                    </v-toolbar>
                    <v-form
                        autocomplete="off"
                        @submit.prevent="createRequest()"
                    >
                        <v-card-text>
                            <v-select
                                v-model="create.data.subject"
                                :items="subjects"
                                :error-messages="create.errors.subject"
                                item-text="name"
                                item-value="id"
                                label="Тема обращения"
                                placeholder="Выберите тему обращения"
                            >
                            </v-select>
                            <v-textarea
                                v-model="create.data.message"
                                :error-messages="create.errors.message"
                                rows="10"
                                label="Содержание обращения"
                                placeholder="Введите текст вашего обращения"
                                class="mt-3"
                            >
                            </v-textarea>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                :disabled="create.progress"
                                text
                                color="error"
                                @click="window = 1"
                            >
                                Отменить
                            </v-btn>
                            <v-btn
                                :loading="create.progress"
                                type="submit"
                                class="blue-grey darken-2"
                                dark
                            >
                                Отправить
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-container>
        </v-window-item>
        <v-window-item>
            <v-container class="mb-0 pb-0">
                <v-btn
                    @click="window = 0"
                    class="blue-grey darken-2 ml-0 mb-0"
                    dark
                >
                    <v-icon left>mdi-plus-circle</v-icon>
                    Новое обращение
                </v-btn>
            </v-container>
            <v-container>
                <v-list
                    two-line
                    class="elevation-2 pa-0"
                >
                    <v-list-item
                        v-for="item in index.data"
                        :key="item.id"
                        @click="viewRequest(item)"
                    >
                        <v-list-item-avatar>
                            <v-icon>{{ find(subjects, item.subject, 'id')[0].icon }}</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title>{{ item.message }}</v-list-item-title>
                            <v-list-item-subtitle>{{ find(subjects, item.subject, 'id')[0].name }}</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action
                            v-if="item.status === 'user'"
                        >
                            <v-btn icon ripple>
                                <v-icon color="">mdi-email-mark-as-unread</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-container>
        </v-window-item>
        <v-window-item>
            <v-container>
                <v-btn
                    class="ml-0"
                    @click="window = 1"
                >
                    <v-icon left>mdi-chevron-left</v-icon>
                    Назад
                </v-btn>
            </v-container>
            <v-container class="pt-0">
                <v-timeline
                    align-top
                    dense
                    class="pt-5"
                >
                    <v-timeline-item
                        v-for="(msg, i) in chat.messages"
                        :key="i"
                        :color="msg.user ? 'blue-grey' : 'green'"
                        class="mb-3"
                    >
                        <v-card
                            :color="msg.user ? 'blue-grey' : 'green'"
                            dark
                        >
                            <v-card-title class="title">
                                <div class="subheading">
                                    {{ msg.user ? 'Вы' : 'Служба поддержки' }}
                                </div>
                                <v-spacer></v-spacer>
                                <div class="caption">{{ $moment(msg.created).format('DD.MM.YYYY HH:mm') }}</div>
                            </v-card-title>
                            <v-card-text class="white text--primary pt-4">
                                <span style="white-space: pre">{{ msg.message }}</span>
                            </v-card-text>
                        </v-card>
                    </v-timeline-item>
                    <v-timeline-item color="blue-grey">
                        <v-card color="blue-grey">
                            <v-form
                                autocomplete="off"
                                @submit.prevent="createMessage()"
                            >
                                <v-card-title class="title" color="blue-grey">
                                    <div class="subheading white--text">
                                        Новое сообщение
                                    </div>
                                </v-card-title>
                                <v-card-text class="white pb-0 pt-4">
                                    <v-textarea
                                        v-model="comment.data.message"
                                        :error-messages="comment.errors.message"
                                        rows="7"
                                        class="pt-0"
                                        placeholder="Введите содержание сообщения"
                                    ></v-textarea>
                                </v-card-text>
                                <v-card-actions class="white pt-0">
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        :loading="comment.progress"
                                        class="blue-grey darken-2"
                                        dark
                                        type="submit"
                                    >
                                        Отправить
                                    </v-btn>
                                </v-card-actions>
                            </v-form>
                        </v-card>
                    </v-timeline-item>
                </v-timeline>
            </v-container>
        </v-window-item>
    </v-window>
</template>

<script>
import Vue2Filters from 'vue2-filters'

export default {
    name: "SupportController",

    mixins: [Vue2Filters.mixin],

    data () {
        return {
            index: {
                data: [],
                loading: false,
            },
            chat: {
                request: null,
                messages: [],
            },
            comment: {
                data: {
                    request_id: null,
                    message: '',
                },
                errors: {},
                progress: false,
            },
            create: {
                data: {
                    subject: null,
                    message: '',
                },
                errors: {},
                progress: false,
            },
            subjects: [
                {
                    id: 'sms',
                    name: 'Создание рассылок',
                    icon: 'mdi-send',
                },
                {
                    id: 'signature',
                    name: 'Подписи отправителя',
                    icon: 'mdi-at',
                },
                {
                    id: 'phonebook',
                    name: 'Списки получателей',
                    icon: 'mdi-view-list',
                },
                {
                    id: 'report',
                    name: 'Отчеты по рассылкам',
                    icon: 'mdi-format-list-checkbox',
                },
                {
                    id: 'template',
                    name: 'Шаблоны сообщений',
                    icon: 'mdi-text-subject',
                },
                {
                    id: 'scheduler',
                    name: 'Планировщик',
                    icon: 'mdi-calendar',
                },
                {
                    id: 'api',
                    name: 'API и SMPP',
                    icon: 'settings',
                },
                {
                    id: 'finance',
                    name: 'Финансы',
                    icon: 'mdi-wallet',
                },
                {
                    id: 'other',
                    name: 'Другие вопросы',
                    icon: 'mdi-help-circle',
                },
            ],
            window: 1,
        }
    },

    created: function () {
        this.loadRequests()
        this.loadMessages()
    },

    methods: {
        createMessage: function () {
            this.comment.progress = true

            const data = new FormData;
            data.append('request_id', this.comment.data.request_id)
            data.append('message', this.comment.data.message)

            this.$axios.post('/support/message/create', data)
                .then(res => {
                    if (res.data.success) {
                        this.comment.data.message = ''
                        this.comment.errors = {}
                        this.loadMessages()
                    } else {
                        this.comment.errors.message = res.data.errors.message || []
                    }

                    this.comment.progress = false
                })
                .catch(() => {
                    this.comment.progress = false
                })
        },

        createRequest: function () {
            this.create.progress = true

            const data = new FormData;
            data.append('subject', this.create.data.subject)
            data.append('message', this.create.data.message)

            this.$axios.post('/support/create', data)
                .then(res => {
                    if (res.data.success) {
                        this.loadRequests()
                        this.window = 1
                        this.create.data.subject = null
                        this.create.data.message = ''
                        this.create.errors = {}
                    } else {
                        this.create.errors.subject = res.data.errors.subject || []
                        this.create.errors.message = res.data.errors.message || []
                    }

                    this.create.progress = false
                })
                .catch(() => {
                    this.create.progress = false
                })
        },

        loadMessages: function () {
            this.chat.loading = true

            const data = {request_id: this.comment.data.request_id}

            this.$axios.post('/support/message/search', data)
                .then(res => {
                    if (res.data.success) {
                        this.chat.messages = res.data.data
                    }
                    this.chat.loading = false
                })
                .catch(() => {
                    this.chat.loading = false
                })
        },

        loadRequests: function () {
            this.index.loading = true

            this.$axios.post('/support/search')
                .then(res => {
                    if (res.data.success) {
                        this.index.data = res.data.data
                    }
                    this.index.loading = false
                })
                .catch(() => {
                    this.index.loading = false
                })
        },

        viewRequest: function (request) {
            this.chat.request = request
            this.comment.data.request_id = request.id
            this.loadMessages()
            this.window = 2
            if (request.status === 'user') {
                request.status = 'read'
            }
        },
    },
}
</script>

<style scoped>
.v-timeline:before {
    top: 30px;
}
</style>
