<template>
    <v-window
        v-model="window"
    >
        <v-window-item>
            <v-container>
                <v-row wrap>
                    <v-col xs="12">
                        <v-card>
                            <v-toolbar flat dense class="pa-auto" color="grey lighten-4">
                                <v-toolbar-title>Фильтры</v-toolbar-title>
                            </v-toolbar>
                            <v-card-text>
                                <v-radio-group
                                    v-model="filter.data.type"
                                    @change="filter.data.page = 1; search()"
                                    class="d-flex"
                                >
                                    <v-row wrap>
                                        <v-col
                                            xs="6" sm="4" lg="3" xl="2"
                                            class="mr-5"
                                        >
                                            <v-radio
                                                color="primary"
                                                label="Массовые рассылки"
                                                value="bulk"
                                                sm3
                                            />
                                        </v-col>
                                        <v-col xs="6" sm="4" lg="3" xl="2">
                                            <v-radio
                                                color="primary"
                                                label="Одиночные сообщения"
                                                value="sms"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-radio-group>
                                <v-row wrap>
                                    <v-col
                                        xs="6" sm="4" lg="3" xl="2"
                                        class="mr-5"
                                    >
                                        <v-menu
                                            v-model="menu.fromDate"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    v-model="fromDateFormatted"
                                                    :error-messages="filter.errors.from_date"
                                                    label="Начало периода"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-on="on"
                                                />
                                            </template>
                                            <v-date-picker
                                                v-model="filter.data.from_date"
                                                locale="ru"
                                                first-day-of-week="1"
                                                color="primary"
                                                @input="updateFromDate()"
                                            />
                                        </v-menu>
                                    </v-col>
                                    <v-col xs="6" sm="4" lg="3" xl="2">
                                        <v-menu
                                            v-model="menu.tillDate"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    v-model="tillDateFormatted"
                                                    :error-messages="filter.errors.till_date"
                                                    label="Окончание периода"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-on="on"
                                                />
                                            </template>
                                            <v-date-picker
                                                v-model="filter.data.till_date"
                                                locale="ru"
                                                first-day-of-week="1"
                                                color="primary"
                                                @input="updateTillDate()"
                                            />
                                        </v-menu>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row
                    text-center
                    wrap
                    pb-5
                >
                    <v-col
                        v-if="reports.pages > 1"
                        xs="12"
                        mt-5
                    >
                        <v-pagination
                            v-model="filter.data.page"
                            :length="reports.pages"
                            @input="search"
                        />
                    </v-col>
                    <v-col
                        v-if="filter.data.type === 'bulk'"
                        xs="12"
                    >
                        <v-hover
                            v-for="bulk in reports.data"
                            :key="bulk.id"
                            class="my-10"
                        >
                            <v-card
                                slot-scope="{ hover }"
                                :class="`elevation-${hover ? 12 : 2}`"
                            >
                                <v-toolbar flat color="grey lighten-4">
                                    <v-toolbar-title>{{ $moment(bulk.created).format('DD.MM.YYYY HH:mm') }}</v-toolbar-title>
                                    <v-spacer/>
                                    <v-btn elevation-1 depressed
                                        :color="status.bulk[bulk.status].color"
                                    >
                                        {{ status.bulk[bulk.status].name }}
                                    </v-btn>
                                </v-toolbar>
                                <v-card-text
                                    class="text-left"
                                >
                                    <kbd
                                        class="text-left py-5 px-3"
                                        style="width: 100%; overflow-x: hidden; display: block"
                                    >
                                        {{ bulk.msg }}
                                    </kbd>
                                    <v-row wrap>
                                        <v-col xs="12" lg="4" class="my-3">
                                            <table>
                                                <tr>
                                                    <td style="white-space: nowrap">Подпись отправителя:</td>
                                                    <td class="px-5" style="white-space: nowrap">{{ bulk.signature }}</td>
                                                </tr>
                                                <tr>
                                                    <td style="white-space: nowrap">Кол-во SMS:</td>
                                                    <td class="px-5" style="white-space: nowrap">{{ bulk.num | currency('', 0, { thousandsSeparator: ' ' }) }}</td>
                                                </tr>
                                                <tr>
                                                    <td style="white-space: nowrap">Стоимость:</td>
                                                    <td class="px-5" style="white-space: nowrap">{{ bulk.total | currency('', 2, { thousandsSeparator: ' ' }) }} ₽</td>
                                                </tr>
                                                <tr v-if="bulk.schedule_from">
                                                    <td style="white-space: nowrap">Начало отправки:</td>
                                                    <td class="px-5" style="white-space: nowrap">{{ $moment(bulk.schedule_from).format('DD.MM.YYYY HH:mm') }}</td>
                                                </tr>
                                                <tr v-if="bulk.schedule_till">
                                                    <td style="white-space: nowrap">Окончание отправки:</td>
                                                    <td class="px-5" style="white-space: nowrap">{{ $moment(bulk.schedule_till).format('DD.MM.YYYY HH:mm') }}</td>
                                                </tr>
                                            </table>
                                        </v-col>
                                        <v-col xs="12" lg="8" class="my-3" v-if="reports.stats[bulk.id]">
                                            <table>
                                                <tr>
                                                    <td style="white-space: nowrap">Отправлено SMS:</td>
                                                    <td class="px-5 text-right" style="white-space: nowrap">{{ reports.stats[bulk.id].enroute + reports.stats[bulk.id].delivered + reports.stats[bulk.id].error | currency('', 0, { thousandsSeparator: ' ' }) }}</td>
                                                    <td style="width: 100%">
                                                        <v-progress-linear
                                                            color="primary"
                                                            height="15"
                                                            :value="100 * (reports.stats[bulk.id].enroute + reports.stats[bulk.id].delivered + reports.stats[bulk.id].error) / bulk.num"
                                                            class="ma-0"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="white-space: nowrap">Доставлено SMS:</td>
                                                    <td class="px-5 text-right" style="white-space: nowrap">{{ reports.stats[bulk.id].delivered | currency('', 0, { thousandsSeparator: ' ' }) }}</td>
                                                    <td style="width: 100%">
                                                        <v-progress-linear
                                                            color="success"
                                                            height="15"
                                                            :value="100 * reports.stats[bulk.id].delivered / bulk.num"
                                                            class="ma-0"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="white-space: nowrap">Не доставлено SMS:</td>
                                                    <td class="px-5 text-right" style="white-space: nowrap">{{ reports.stats[bulk.id].error | currency('', 0, { thousandsSeparator: ' ' }) }}</td>
                                                    <td style="width: 100%">
                                                        <v-progress-linear
                                                            color="error"
                                                            height="15"
                                                            :value="100 * reports.stats[bulk.id].error / bulk.num"
                                                            class="ma-0"
                                                        />
                                                    </td>
                                                </tr>
                                            </table>
                                        </v-col>
                                    </v-row>
                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on }">
                                            <v-btn fab small absolute bottom right color="primary" v-on="on">
                                                <v-icon>mdi-menu</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item
                                                @click="bulkShow(bulk)"
                                            >
                                                <v-icon left>mdi-view-list</v-icon>
                                                <v-list-item-title>Подробный отчет</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item
                                                v-if="bulk.status === 'ready' || bulk.status === 'unpaid'"
                                                @click="bulkPause(bulk)"
                                            >
                                                <v-icon left>mdi-pause</v-icon>
                                                <v-list-item-title>Остановить</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item
                                                v-if="bulk.status === 'paused'"
                                                @click="bulkResume(bulk)"
                                            >
                                                <v-icon left>mdi-play</v-icon>
                                                <v-list-item-title>Возобновить</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item
                                                v-if="bulk.status === 'paused' || bulk.status === 'reserve' || bulk.status === 'unpaid'"
                                                @click="bulkCancel(bulk)"
                                            >
                                                <v-icon left>mdi-delete</v-icon>
                                                <v-list-item-title>Отменить</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </v-card-text>
                            </v-card>
                        </v-hover>
                    </v-col>
                    <v-col
                        v-if="filter.data.type === 'sms'"
                        xs="12"
                    >
                        <APISMS
                            v-for="sms in reports.data"
                            :key="sms.id"
                            :sms="sms"
                            class="mt-5"
                            @onCancelled="smsCancelled"
                        />
                    </v-col>
                    <v-col
                        v-if="reports.pages > 1 && reports.data.length"
                        xs="12"
                        mt-5
                    >
                        <v-pagination
                            v-model="filter.data.page"
                            :length="reports.pages"
                            @input="search"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </v-window-item>
        <v-window-item>
            <v-container>
                <v-btn
                    class="ml-0 mb-5"
                    @click="window = 0"
                >
                    <v-icon left>mdi-chevron-left</v-icon>
                    Назад
                </v-btn>
            </v-container>
            <BulkSMS :bulk="report.bulk" @onBulkCancelled="bulkCancelled"/>
        </v-window-item>
    </v-window>
</template>

<script>
import BulkSMS from '../models/BulkSMS'
import APISMS from '../models/APISMS'

export default {
    name: "ReportController",
    components: {
        APISMS: APISMS,
        BulkSMS: BulkSMS,
    },
    data () {
        return {
            filter: {
                data: {
                    type: 'bulk',
                    from_date: this.$moment().format('YYYY-MM-DD'),
                    till_date: this.$moment().format('YYYY-MM-DD'),
                    page: 1,
                },
                errors: {},
            },
            menu: {
                fromDate: false,
                tillDate: false,
            },
            report: {
                bulk: null,
            },
            reports: {
                data: [],
                loading: false,
                pages: 1,
                stats: {},
                total: 0,
            },
            status: {
                bulk: {
                    cancel: {
                        name: 'Отменяется',
                        color: 'warning',
                    },
                    done: {
                        name: 'Завершена',
                        color: 'success',
                    },
                    init: {
                        name: 'Формируется',
                        color: 'grey lighten-2',
                    },
                    paused: {
                        name: 'Остановлена',
                        color: 'warning',
                    },
                    ready: {
                        name: 'Работает',
                        color: 'primary',
                    },
                    reserve: {
                        name: 'Ожидает оплаты',
                        color: 'warning',
                    },
                    unpaid: {
                        name: 'Ожидает оплаты',
                        color: 'warning',
                    },
                },
                sms: {
                    cancel: {
                        name: 'Отменяется',
                        color: 'warning',
                    },
                    cancelled: {
                        name: 'Отменено',
                        color: 'grey',
                    },
                    deleted: {
                        name: 'Удалено оператором',
                        color: 'error',
                    },
                    delivered: {
                        name: 'Доставлено',
                        color: 'success',
                    },
                    enroute: {
                        name: 'Отправлено',
                        color: 'primary',
                    },
                    error: {
                        name: 'Ошибка',
                        color: 'error',
                    },
                    expired: {
                        name: 'Истек срок жизни',
                        color: 'error',
                    },
                    init: {
                        name: 'Формируется',
                        color: 'grey',
                    },
                    noroute: {
                        name: 'Нет маршрута',
                        color: 'error',
                    },
                    paused: {
                        name: 'Остановлено',
                        color: 'warning',
                    },
                    ready: {
                        name: 'Ожидает отправки',
                        color: 'primary',
                    },
                    rejected: {
                        name: 'Отклонено',
                        color: 'error',
                    },
                    reserve: {
                        name: 'Ожидает оплаты',
                        color: 'warning',
                    },
                    route: {
                        name: 'Маршрутизируется',
                        color: 'primary',
                    },
                    undeliverable: {
                        name: 'Ошибка',
                        color: 'error',
                    },
                    unknown: {
                        name: 'Ошибка',
                        color: 'error',
                    },
                    unpaid: {
                        name: 'Ожидает оплаты',
                        color: 'warning',
                    },
                },
            },
            window: 0,
        }
    },

    computed: {
        fromDateFormatted () {
            return this.formatDate(this.filter.data.from_date)
        },
        tillDateFormatted () {
            return this.formatDate(this.filter.data.till_date)
        },
    },

    created () {
        this.search()
    },

    methods: {
        bulkCancel (bulk) {
            this.$axios.post('/message/bulk/cancel', {id: bulk.id})
                .then(res => {
                    if (res.data.success) {
                        this.search()
                    }
                })
                .catch(() => {})
        },

        bulkCancelled () {
            this.search()
            this.window = 0
        },

        bulkPause (bulk) {
            this.$axios.post('/message/bulk/pause', {id: bulk.id})
                .then(res => {
                    if (res.data.success) {
                        bulk.status = res.data.data.status
                        if (this.report.bulk && this.report.bulk.id === bulk.id) {
                            this.report.bulk.status = res.data.data.status
                            this.loadReport()
                        }
                    }
                })
                .catch(() => {})
        },

        bulkResume (bulk) {
            this.$axios.post('/message/bulk/resume', {id: bulk.id})
                .then(res => {
                    if (res.data.success) {
                        bulk.status = res.data.data.status
                        if (this.report.bulk && this.report.bulk.id === bulk.id) {
                            this.report.bulk.status = res.data.data.status
                            this.loadReport()
                        }
                    }
                })
                .catch(() => {})
        },

        bulkShow (bulk) {
            this.report.bulk = bulk
            this.window = 1
        },

        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}.${month}.${year}`
        },

        search () {
            if (this.filter.data.type === 'bulk') {
                this.searchBulkSMS()
            } else {
                this.searchSMS()
            }
        },

        searchBulkSMS () {
            this.reports.loading = true
            this.reports.data = []

            this.$axios.post('/report/bulk/search', this.filter.data)
                .then(res => {
                    if (res.data.success) {
                        this.reports.pages = res.data.meta.pages
                        this.reports.total = res.data.meta.total
                        this.reports.data = res.data.data
                        this.searchStatus()
                    }
                    this.reports.loading = false
                })
                .catch(() => {
                    this.reports.loading = false
                })
        },

        searchSMS () {
            this.reports.loading = true
            this.reports.data = []

            this.$axios.post('/report/sms/search', this.filter.data)
                .then(res => {
                    if (res.data.success) {
                        this.reports.pages = res.data.meta.pages
                        this.reports.total = res.data.meta.total
                        this.reports.data = res.data.data
                        this.reports.stats = {}
                    }
                    this.reports.loading = false
                })
                .catch(() => {
                    this.reports.loading = false
                })
        },

        searchStatus () {
            const data = {id: this.reports.data.map(bulk => bulk.id)}

            this.$axios.post('/report/bulk/status', data)
                .then(res => {
                    if (res.data.success) {
                        this.reports.stats = res.data.data
                    }
                })
                .catch(() => {})
        },

        smsCancel (sms) {
            this.$axios.post('/sms/cancel', {id: sms.id})
                .then(res => {
                    if (res.data.success) {
                        this.search()
                    }
                })
                .catch(() => {})
        },

        smsCancelled () {
            this.search()
            this.window = 0
        },

        smsPause (sms) {
            this.$axios.post('/sms/pause', {id: sms.id})
                .then(res => {
                    if (res.data.success) {
                        sms.status = res.data.data.status
                    }
                })
                .catch(() => {})
        },

        smsResume (sms) {
            this.$axios.post('/sms/resume', {id: sms.id})
                .then(res => {
                    if (res.data.success) {
                        sms.status = res.data.data.status
                    }
                })
                .catch(() => {})
        },

        updateFromDate () {
            this.menu.fromDate = false
            this.filter.errors.from_date = []
            this.search()
        },

        updateTillDate () {
            this.menu.tillDate = false
            this.filter.errors.till_date = []
            this.search()
        },
    },
}
</script>
