<template>
    <v-window
        v-model="window"
    >
        <v-window-item>
            <v-container>
                <v-card>
                    <v-card-text>
                        <table class="mb-3 subheading">
                            <tr>
                                <td class="pr-3">
                                    Доступно:
                                </td>
                                <td class="text-right success--text text--darken-1">
                                    {{ user.walletSystem | currency('', 2, { thousandsSeparator: ' ' }) }} ₽
                                </td>
                            </tr>
                            <tr>
                                <td class="pr-3">
                                    В резерве:
                                </td>
                                <td class="text-right error--text text--darken-1">
                                    {{ user.walletReserve | currency('', 2, { thousandsSeparator: ' ' }) }} ₽
                                </td>
                            </tr>
                        </table>
                        <v-tabs grow>
                            <v-tab>
                                История баланса
                            </v-tab>
                            <v-tab>
                                Зарезервированные средства
                            </v-tab>
                            <v-tab-item>
                                <v-data-table
                                    :headers="history.headers"
                                    :items="history.data"
                                    :loading="history.loading"
                                    :options.sync="history.options"
                                    :footer-props="history.footer"
                                    :server-items-length="history.total"
                                    @update:options="loadHistory"
                                    footer-items-per-page-text="Строк на странице"
                                    must-sort
                                >
                                    <template v-slot:item="{ item }">
                                        <tr>
                                            <td class="text-left">{{ $moment(item.created).format('DD.MM.YYYY в HH:mm') }}</td>
                                            <td class="text-right" v-bind:class="{'red--text': item.amount < 0, 'green-text': item.amount > 0}">{{ Math.abs(item.amount) | currency('', 2, { thousandsSeparator: ' ' }) }} ₽</td>
                                            <td class="text-right">{{ item.opening | currency('', 2, { thousandsSeparator: ' ' }) }} ₽</td>
                                            <td class="text-right">{{ item.closing | currency('', 2, { thousandsSeparator: ' ' }) }} ₽</td>
                                            <td class="text-left">
                                                {{ history.items[item.item] }}
                                            </td>
                                            <td class="text-left"><a v-html="item.note" @click="showBulk(item)"/></td>
                                        </tr>
                                    </template>
                                    <template v-slot:no-data>
                                        <div v-if="history.loading" class="text-center">
                                            Идет загрузка данных
                                        </div>
                                        <div v-if="!history.loading" class="text-center">
                                            Не найдены записи
                                        </div>
                                    </template>
                                    <template v-slot:no-results>
                                        <div v-if="history.loading" class="text-center">
                                            Идет загрузка данных
                                        </div>
                                        <div v-if="!history.loading" class="text-center">
                                            Не найдены записи
                                        </div>
                                    </template>
                                </v-data-table>
                            </v-tab-item>
                            <v-tab-item>
                                <v-data-table
                                    :headers="reserve.headers"
                                    :items="reserve.data"
                                    :loading="reserve.loading"
                                    :options.sync="reserve.options"
                                    :footer-props="reserve.footer"
                                    :server-items-length="reserve.total"
                                    @update:options="loadReserve"
                                    footer-items-per-page-text="Строк на странице"
                                    must-sort
                                >
                                    <template v-slot:item="{ item }">
                                        <tr>
                                            <td class="text-left">{{ $moment(item.created).format('DD.MM.YYYY в HH:mm') }}</td>
                                            <td class="text-right" v-bind:class="{'red--text': item.amount < 0, 'green-text': item.amount > 0}">{{ Math.abs(item.amount) | currency('', 2, { thousandsSeparator: ' ' }) }} ₽</td>
                                            <td class="text-left">{{ reserve.items[item.item] }}</td>
                                            <td class="text-left">
                                                <a
                                                    v-if="item.item === 'BulkSMS'"
                                                    v-html="item.note"
                                                    @click="showBulk(item)"
                                                ></a>
                                                <a
                                                    v-if="item.item === 'SMS'"
                                                    v-html="item.note"
                                                    @click="showSMS(item)"
                                                ></a>
                                            </td>
                                        </tr>
                                    </template>
                                    <template v-slot:no-data>
                                        <div v-if="reserve.loading" class="text-center">
                                            Идет загрузка данных
                                        </div>
                                        <div v-if="!reserve.loading" class="text-center">
                                            Не найдены записи
                                        </div>
                                    </template>
                                    <template v-slot:no-results>
                                        <div v-if="reserve.loading" class="text-center">
                                            Идет загрузка данных
                                        </div>
                                        <div v-if="!reserve.loading" class="text-center">
                                            Не найдены записи
                                        </div>
                                    </template>
                                </v-data-table>
                            </v-tab-item>
                        </v-tabs>
                    </v-card-text>
                </v-card>
            </v-container>
        </v-window-item>
        <v-window-item>
            <v-container>
                <v-btn
                    class="ml-0"
                    @click="window = 0"
                >
                    <v-icon left>mdi-chevron-left</v-icon>
                    Назад
                </v-btn>
                <APISMS
                    v-if="data.sms"
                    :sms="data.sms"
                    class="mt-3"
                    @onCancelled="cancelled"
                />
            </v-container>
            <BulkSMS
                v-if="data.bulk"
                :bulk="data.bulk"
                @onCancelled="cancelled"
            />
        </v-window-item>
    </v-window>
</template>

<script>
import BulkSMS from '../models/BulkSMS'
import APISMS from '../models/APISMS'

export default {
    name: "FinanceController",
    components: {
        APISMS: APISMS,
        BulkSMS: BulkSMS,
    },
    data () {
        return {
            data: {
                bulk: null,
                sms: null,
            },
            filter: {
                from_date: this.$moment().format('YYYY-MM-DD'),
                till_date: this.$moment().format('YYYY-MM-DD'),
                errors: {},
            },
            menu: {
                fromDate: false,
                tillDate: false,
            },
            history: {
                data: [],
                footer: {
                    'items-per-page-options': [10, 25, 50, 100],
                    'items-per-page-text': "Строк на странице",
                },
                headers: [
                    {
                        text: 'Дата',
                        value: 'created',
                    },
                    {
                        text: 'Сумма',
                        value: 'amount',
                    },
                    {
                        text: 'Входящий баланс',
                        value: 'opening',
                        sortable: false,
                    },
                    {
                        text: 'Исходящий баланс',
                        value: 'closing',
                        sortable: false,
                    },
                    {
                        text: 'Тип операции',
                        value: 'type',
                        sortable: false,
                    },
                    {
                        text: 'Примечание',
                        value: 'note',
                        sortable: false,
                    },
                ],
                items: {
                    BulkSMS: 'Массовая рассылка',
                },
                loading: false,
                options: {
                    page: 1,
                    itemsPerPage: 10,
                    sortBy: ['created'],
                    sortDesc: [true],
                    totalItems: 0,
                },
                total: 0,
            },
            reserve: {
                data: [],
                footer: {
                    itemsPerPage: [10, 25, 50, 100],
                },
                headers: [
                    {
                        text: 'Дата',
                        value: 'created',
                    },
                    {
                        text: 'Сумма',
                        value: 'amount',
                    },
                    {
                        text: 'Тип операции',
                        value: 'type',
                        sortable: false,
                    },
                    {
                        text: 'Примечание',
                        value: 'note',
                        sortable: false,
                    },
                ],
                items: {
                    BulkSMS: 'Массовая рассылка',
                    SMS: 'Одиночное сообщение',
                },
                loading: false,
                options: {
                    page: 1,
                    itemsPerPage: 10,
                    sortBy: ['created'],
                    sortDesc: [true],
                    totalItems: 0,
                },
                total: 0,
            },
            window: 0,
        }
    },

    computed: {
        user () {
            return this.$store.getters.user
        },
    },

    methods: {
        cancelled () {
            this.loadReserve()
            this.window = 0
        },

        loadHistory () {
            this.history.loading = true

            this.$axios.post('/finance/history/search', this.history.options)
                .then(res => {
                    if (res.data.success) {
                        this.history.loading = false
                        this.history.data = res.data.data
                        this.history.total = res.data.meta.total
                    } else {
                        this.history.loading = false
                        this.history.data = []
                    }
                })
                .catch(() => {
                    this.history.loading = false
                    this.history.data = []
                })
        },

        loadReserve () {
            this.reserve.loading = true

            this.$axios.post('/finance/reserve/search', this.reserve.options)
                .then(res => {
                    if (res.data.success) {
                        this.reserve.loading = false
                        this.reserve.data = res.data.data
                        this.reserve.total = res.data.meta.total
                    } else {
                        this.reserve.loading = false
                        this.reserve.data = []
                    }
                })
                .catch(() => {
                    this.reserve.loading = false
                    this.reserve.data = []
                })
        },

        searchBulk (id, callback) {
            this.$axios.post('/report/bulk/search/one', {id})
                .then(res => {
                    if (res.data.success) {
                        callback(res.data.data)
                    }
                })
                .catch(() => {})
        },

        showBulk (history) {
            this.data.sms = null
            this.searchBulk(history.data.bulk_id, (bulk) => {
                this.data.bulk = bulk
                this.window = 1
            })
        },

        showSMS (history) {
            this.data.bulk = null
            this.data.sms = history.sms
            this.window = 1
        },
    },
}
</script>
