<template>
    <v-container>
        <v-row text-center wrap>
            <v-col xs="12">
                <v-card>
                    <v-toolbar flat color="grey lighten-4">
                        <v-toolbar-title>Подписи отправителя, SMS</v-toolbar-title>
                        <v-spacer/>
                        <v-btn
                            color="blue-grey darken-2"
                            dark
                            @click="sms.create.show = true"
                        >
                            <v-icon>mdi-plus</v-icon> Добавить
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-data-table
                            :headers="sms.search.headers"
                            :items="sms.search.data"
                            :loading="sms.search.loading"
                            hide-default-footer
                            disable-pagination
                        >
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td class="text-left">{{ item.signature }}</td>
                                    <td class="text-left">{{ item.categoryName }}</td>
                                    <td class="text-left">{{ item.statusName }}</td>
                                    <td class="layout">
                                        <v-icon
                                            small
                                            @click="sms.remove.data = item; sms.remove.show = true"
                                        >
                                            mdi-delete
                                        </v-icon>
                                    </td>
                                </tr>
                            </template>
                            <template v-slot:no-data>
                                <div v-if="sms.search.loading">
                                    Идет загрузка данных
                                </div>
                                <div v-if="!sms.search.loading">
                                    Нет доступных подписей
                                </div>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row text-center wrap>
            <v-col xs="12">
                <v-card>
                    <v-toolbar flat color="grey lighten-4">
                        <v-toolbar-title>Подписи отправителя, Viber</v-toolbar-title>
                        <v-spacer/>
                        <v-btn
                            color="blue-grey darken-2"
                            dark
                            @click="viber.create.show = true"
                        >
                            <v-icon>mdi-plus</v-icon> Добавить
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-data-table
                            :headers="viber.search.headers"
                            :items="viber.search.data"
                            :loading="viber.search.loading"
                            hide-default-footer
                            disable-pagination
                        >
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td class="text-left">{{ item.signature }}</td>
                                    <td class="text-left">{{ item.statusName }}</td>
                                    <td class="layout">
                                        <v-icon
                                            small
                                            @click="viber.remove.data = item; viber.remove.show = true"
                                        >
                                            mdi-delete
                                        </v-icon>
                                    </td>
                                </tr>
                            </template>
                            <template v-slot:no-data>
                                <div v-if="viber.search.loading">
                                    Идет загрузка данных
                                </div>
                                <div v-if="!viber.search.loading">
                                    Нет доступных подписей
                                </div>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog
            v-model="sms.create.show"
            persistent
            max-width="500"
        >
            <v-card>
                <v-toolbar flat color="grey lighten-4">
                    <v-toolbar-title>Новая подпись</v-toolbar-title>
                </v-toolbar>
                <v-form @submit.prevent="createSMSSignature">
                    <v-card-text>
                        <v-select
                            v-model="sms.create.data.category"
                            :items="sms.create.categories"
                            :error-messages="sms.create.errors.category"
                            item-text="name"
                            item-value="category"
                            label="Категория"
                            placeholder="Выберите категорию подписи"
                            class="mt-5"
                        />
                        <v-text-field
                            v-model="sms.create.data.signature"
                            :error-messages="sms.create.errors.signature"
                            name="signature"
                            label="Подпись"
                            type="text"
                            class="mt-5"
                        />
                        <v-switch
                            v-if="sms.create.data.category === 'sms_service'"
                            v-model="sms.create.data.ru_beeline"
                            label="Билайн"
                            color="primary"
                            class="mt-5"
                        />
                        <v-switch
                            v-if="sms.create.data.category === 'sms_service'"
                            v-model="sms.create.data.ru_megafon"
                            label="МегаФон"
                            color="primary"
                            class="mt-0"
                        />
                        <v-switch
                            v-if="sms.create.data.category === 'sms_service'"
                            v-model="sms.create.data.ru_mts"
                            label="МТС"
                            color="primary"
                            class="mt-0"
                        />
                        <v-switch
                            v-if="sms.create.data.category === 'sms_service'"
                            v-model="sms.create.data.ru_tele2"
                            label="Теле2"
                            color="primary"
                            class="mt-0"
                        />
                        <v-switch
                            v-if="sms.create.data.category === 'sms_service'"
                            v-model="sms.create.data.ru_motiv"
                            label="Мотив"
                            color="primary"
                            class="mt-0"
                        />
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer/>
                        <v-btn
                            :disabled="sms.create.progress"
                            text
                            color="error"
                            @click="sms.create.show = false"
                        >Отменить</v-btn>
                        <v-btn
                            :loading="sms.create.progress"
                            type="submit"
                            color="blue-grey darken-2"
                            dark
                        >Сохранить</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="viber.create.show"
            persistent
            max-width="500"
        >
            <v-card>
                <v-toolbar flat color="grey lighten-4">
                    <v-toolbar-title>Новая подпись</v-toolbar-title>
                </v-toolbar>
                <v-form @submit.prevent="createViberSignature">
                    <v-card-text>
                        <v-select
                            v-model="viber.create.data.category"
                            :items="viber.create.categories"
                            :error-messages="viber.create.errors.category"
                            item-text="name"
                            item-value="category"
                            label="Категория"
                            placeholder="Выберите категорию подписи"
                            class="mt-5"
                        />
                        <v-text-field
                            v-model="viber.create.data.signature"
                            :error-messages="viber.create.errors.signature"
                            name="signature"
                            label="Подпись"
                            type="text"
                            class="mt-5"
                        />
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer/>
                        <v-btn
                            :disabled="viber.create.progress"
                            text
                            color="error"
                            @click="viber.create.show = false"
                        >Отменить</v-btn>
                        <v-btn
                            :loading="viber.create.progress"
                            type="submit"
                            color="blue-grey darken-2"
                            dark
                        >Сохранить</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
        <v-dialog
            v-if="sms.remove.data"
            v-model="sms.remove.show"
            max-width="500"
        >
            <v-card>
                <v-card-title class="headline">Удалить подпись {{ sms.remove.data.signature }}?</v-card-title>
                <v-card-text>
                    <p>
                        После удаления подписи вы больше не сможете использовать ее для новых SMS.
                    </p>
                    <p>
                        Удаление не отразится на уже запланированных рассылках.
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn
                        @click="sms.remove.show = false"
                        color="red darken-1"
                        text
                    >
                        Отменить
                    </v-btn>
                    <v-btn
                        @click="removeSMSSignature"
                        color="blue-grey darken-2"
                        dark
                        text
                    >
                        Удалить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-if="viber.remove.data"
            v-model="viber.remove.show"
            max-width="500"
        >
            <v-card>
                <v-card-title class="headline">Удалить подпись {{ viber.remove.data.signature }}?</v-card-title>
                <v-card-text>
                    <p>
                        После удаления подписи вы больше не сможете использовать ее для новых сообщений Viber.
                    </p>
                    <p>
                        Удаление не отразится на уже запланированных рассылках.
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn
                        @click="viber.remove.show = false"
                        color="red darken-1"
                        text
                    >
                        Отменить
                    </v-btn>
                    <v-btn
                        @click="removeViberSignature"
                        color="blue-grey darken-2"
                        dark
                        text
                    >
                        Удалить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
export default {
    name: "SignatureController",
    data () {
        return {
            sms: {
                create: {
                    categories: [
                        {
                            category: 'sms_promo',
                            name: 'Бесплатная подпись для рекламных SMS',
                        },
                        {
                            category: 'sms_service',
                            name: 'Платная подпись для сервисных SMS',
                        },
                    ],
                    default: {
                        category: 'sms_promo',
                        ru_beeline: false,
                        ru_megafon: false,
                        ru_motiv: false,
                        ru_mts: false,
                        ru_tele2: false,
                        signature: '',
                    },
                    data: {},
                    errors: {
                        signature: [],
                    },
                    progress: false,
                    show: false,
                },
                remove: {
                    data: null,
                    show: false,
                },
                search: {
                    data: [],
                    headers: [
                        {
                            text: 'Подпись',
                            value: 'signature',
                        },
                        {
                            text: 'Категория',
                            value: 'category',
                            width: '30%',
                        },
                        {
                            text: 'Состояние',
                            value: 'state',
                            width: '20%',
                        },
                        {
                            text: 'Действия',
                            value: 'signature',
                            sortable: false,
                            width: '20%',
                        },
                    ],
                    loading: false,
                },
            },
            viber: {
                create: {
                    categories: [
                        {
                            category: 'viber',
                            name: 'Платная подпись для сообщений Viber',
                        },
                    ],
                    default: {
                        category: 'viber',
                        signature: '',
                    },
                    data: {},
                    errors: {
                        signature: [],
                    },
                    progress: false,
                    show: false,
                },
                remove: {
                    data: null,
                    show: false,
                },
                search: {
                    data: [],
                    headers: [
                        {
                            text: 'Подпись',
                            value: 'signature',
                        },
                        {
                            text: 'Состояние',
                            value: 'state',
                            width: '20%',
                        },
                        {
                            text: 'Действия',
                            value: 'signature',
                            sortable: false,
                            width: '20%',
                        },
                    ],
                    loading: false,
                },
            },
        }
    },
    created () {
        this.searchSMSSignature()
        this.searchViberSignature()
        this.sms.create.data = {...this.sms.create.default}
        this.viber.create.data = {...this.viber.create.default}
    },
    methods: {
        createSMSSignature () {
            this.sms.create.progress = true

            this.$axios.post('/signature/sms/create', this.sms.create.data)
                .then(res => {
                    if (res.data.success) {
                        this.sms.create.progress = false
                        this.sms.create.show = false
                        this.sms.create.data = {...this.sms.create.default}
                        this.sms.create.errors = {}
                        this.searchSMSSignature()
                    } else {
                        this.sms.create.progress = false
                        this.sms.create.errors = res.data.errors
                    }
                })
                .catch(() => {
                    this.sms.create.progress = false
                })
        },

        createViberSignature () {
            this.viber.create.progress = true

            this.$axios.post('/signature/viber/create', this.viber.create.data)
                .then(res => {
                    if (res.data.success) {
                        this.viber.create.progress = false
                        this.viber.create.show = false
                        this.viber.create.data = {...this.viber.create.default}
                        this.viber.create.errors = {}
                        this.searchViberSignature()
                    } else {
                        this.viber.create.progress = false
                        this.viber.create.errors = res.data.errors
                    }
                })
                .catch(() => {
                    this.viber.create.progress = false
                })
        },

        removeSMSSignature () {
            this.$axios.post('/signature/sms/remove', this.sms.remove.data)
                .then(() => {
                    this.sms.search.data = []
                    this.sms.remove.show = false
                    this.sms.remove.data = null

                    this.searchSMSSignature()
                })
                .catch(() => {})
        },

        removeViberSignature () {
            this.$axios.post('/signature/viber/remove', this.viber.remove.data)
                .then(() => {
                    this.viber.search.data = []
                    this.viber.remove.show = false
                    this.viber.remove.data = null

                    this.searchViberSignature()
                })
                .catch(() => {})
        },

        searchSMSSignature () {
            this.sms.search.loading = true

            this.$axios.post('/signature/sms/search')
                .then(res => {
                    if (res.data.success) {
                        this.sms.search.data = res.data.data
                    }
                    this.sms.search.loading = false
                })
                .catch(() => {
                    this.sms.search.loading = false
                })
        },

        searchViberSignature () {
            this.viber.search.loading = true

            this.$axios.post('/signature/viber/search')
                .then(res => {
                    if (res.data.success) {
                        this.viber.search.data = res.data.data
                    }
                    this.viber.search.loading = false
                })
                .catch(() => {
                    this.viber.search.loading = false
                })
        },
    },
}
</script>
