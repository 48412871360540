const state = {
    userWalletSystem: localStorage.getItem('user-wallet-system') || null,
    userWalletReserve: localStorage.getItem('user-wallet-reserve') || null,
    userEmail: localStorage.getItem('user-email') || null,
    userPhone: localStorage.getItem('user-phone') || null,
}

const getters = {
    user: function (state) {
        return {
            walletSystem: state.userWalletSystem,
            walletReserve: state.userWalletReserve,
            email: state.userEmail,
            phone: state.userPhone,
        }
    },
}

const mutations = {
    removeUser (state) {
        localStorage.removeItem('user-wallet-system')
        localStorage.removeItem('user-wallet-reserve')
        localStorage.removeItem('user-email')
        localStorage.removeItem('user-phone')
        state.userWalletSystem = null
        state.userWalletReserve = null
        state.userEmail = null
        state.userPhone = null
    },
    updateUser (state, user) {
        localStorage.setItem('user-wallet-system', user.wallet_system)
        localStorage.setItem('user-wallet-reserve', user.wallet_reserve)
        localStorage.setItem('user-email', user.email)
        localStorage.setItem('user-phone', user.phone)
        state.userWalletSystem = user.wallet_system
        state.userWalletReserve = user.wallet_reserve
        state.userEmail = user.email
        state.userPhone = user.phone
    },
    updateUserWalletSystem (state, wallet) {
        localStorage.setItem('user-wallet-system', 0)
        state.userWalletSystem = wallet
    },
    updateUserWalletReserve (state, wallet) {
        localStorage.setItem('user-wallet-reserve', wallet)
        state.userWalletReserve = wallet
    },
}

export default {
    state,
    getters,
    mutations,
}
