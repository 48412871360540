<template>
    <v-container
        v-if="sms"
        class="ma-0 pa-0"
    >
        <v-hover>
            <v-card
                slot-scope="{ hover }"
                :class="`elevation-${hover ? 12 : 2}`"
            >
                <v-toolbar flat class="grey lighten-4">
                    <v-toolbar-title>{{ $moment(sms.created).format('DD.MM.YYYY HH:mm') }}</v-toolbar-title>
                    <v-spacer />
                    <v-btn elevation-1 depressed
                           :color="status[sms.status].color"
                    >
                        {{ status[sms.status].name }}
                    </v-btn>
                </v-toolbar>
                <v-card-text
                    class="text-left"
                >
                    <kbd
                        v-if="sms.msg.length"
                        class="text-left py-5 px-3 my-2 blue-grey darken-2"
                        style="width: 100%; overflow-x: auto; display: block"
                    >
                        {{ sms.msg }}
                    </kbd>
                    <v-row wrap>
                        <v-col xs="12" lg="4" class="my-3">
                            <table>
                                <tr>
                                    <td style="white-space: nowrap">Подпись отправителя:</td>
                                    <td class="px-5" style="white-space: nowrap">{{ sms.signature }}</td>
                                </tr>
                                <tr>
                                    <td style="white-space: nowrap">Номер получателя:</td>
                                    <td class="px-5" style="white-space: nowrap">+{{ sms.phone }}</td>
                                </tr>
                                <tr v-if="sms.parts">
                                    <td style="white-space: nowrap">Кол-во частей:</td>
                                    <td class="px-5" style="white-space: nowrap">{{ sms.parts }}</td>
                                </tr>
                                <tr v-if="sms.total">
                                    <td style="white-space: nowrap">Стоимость:</td>
                                    <td class="px-5" style="white-space: nowrap">{{ sms.total | currency('', 2, { thousandsSeparator: ' ' }) }} ₽</td>
                                </tr>
                                <tr v-if="sms.schedule">
                                    <td style="white-space: nowrap">Время отправки:</td>
                                    <td class="px-5" style="white-space: nowrap">{{ $moment(sms.schedule).format('DD.MM.YYYY HH:mm') }}</td>
                                </tr>
                                <tr v-if="sms.expire">
                                    <td style="white-space: nowrap">Срок жизни:</td>
                                    <td class="px-5" style="white-space: nowrap">{{ $moment(sms.expire).format('DD.MM.YYYY HH:mm') }}</td>
                                </tr>
                                <tr v-if="sms.locked">
                                    <td style="white-space: nowrap">Время доставки:</td>
                                    <td class="px-5" style="white-space: nowrap">{{ $moment(sms.locked).format('DD.MM.YYYY HH:mm') }}</td>
                                </tr>
                            </table>
                        </v-col>
                    </v-row>
                    <v-menu offset-y v-if="['queue', 'ready', 'unpaid', 'paused'].indexOf(sms.status) >= 0">
                        <template v-slot:activator="{ on }">
                            <v-btn fab small absolute bottom right color="primary" v-on="on">
                                <v-icon>mdi-menu</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                                v-if="sms.status === 'queue' || sms.status === 'ready' || sms.status === 'unpaid'"
                                @click="pause(sms)"
                            >
                                <v-icon left>mdi-pause</v-icon>
                                <v-list-item-title>Остановить</v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-if="sms.status === 'paused'"
                                @click="resume(sms)"
                            >
                                <v-icon left>mdi-play</v-icon>
                                <v-list-item-title>Возобновить</v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-if="sms.status === 'paused'"
                                @click="cancel(sms)"
                            >
                                <v-icon left>mdi-delete</v-icon>
                                <v-list-item-title>Отменить</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-card-text>
            </v-card>
        </v-hover>
    </v-container>
</template>

<script>
export default {
    name: "APISMS",

    props: ['sms'],

    data () {
        return {
            data: [],
            loading: false,
            status: {
                cancel: {
                    name: 'Отменяется',
                    color: 'warning',
                },
                cancelled: {
                    name: 'Отменено',
                    color: 'grey',
                },
                deleted: {
                    name: 'Удалено оператором',
                    color: 'error',
                },
                delivered: {
                    name: 'Доставлено',
                    color: 'success',
                },
                enroute: {
                    name: 'Отправлено',
                    color: 'primary',
                },
                error: {
                    name: 'Ошибка',
                    color: 'error',
                },
                expired: {
                    name: 'Истек срок жизни',
                    color: 'error',
                },
                init: {
                    name: 'Формируется',
                    color: 'grey',
                },
                noroute: {
                    name: 'Нет маршрута',
                    color: 'error',
                },
                paused: {
                    name: 'Остановлено',
                    color: 'warning',
                },
                ready: {
                    name: 'Ожидает отправки',
                    color: 'primary',
                },
                rejected: {
                    name: 'Отклонено',
                    color: 'error',
                },
                reserve: {
                    name: 'Ожидает оплаты',
                    color: 'warning',
                },
                route: {
                    name: 'Маршрутизируется',
                    color: 'primary',
                },
                undeliverable: {
                    name: 'Ошибка',
                    color: 'error',
                },
                unknown: {
                    name: 'Ошибка',
                    color: 'error',
                },
                unpaid: {
                    name: 'Ожидает оплаты',
                    color: 'warning',
                },
            },
        }
    },

    methods: {
        cancel: function (sms) {
            this.$axios.post('/sms/cancel', {id: sms.id})
                .then(res => {
                    if (res.data.success) {
                        this.$emit('onCancelled')
                    }
                })
                .catch(() => {})
        },

        pause: function (sms) {
            this.$axios.post('/sms/pause', {id: sms.id})
                .then(res => {
                    if (res.data.success) {
                        sms.status = res.data.data.status
                    }
                })
                .catch(() => {})
        },

        resume: function (sms) {
            this.$axios.post('/sms/resume', {id: sms.id})
                .then(res => {
                    if (res.data.success) {
                        sms.status = res.data.data.status
                    }
                })
                .catch(() => {})
        },
    }
}
</script>

<style scoped>
</style>
