<template>
    <v-container
        v-if="bulk"
        class="mt-5 pt-0"
    >
        <v-card>
            <v-toolbar flat class="grey lighten-4">
                <v-toolbar-title>{{ $moment(bulk.created).format('DD.MM.YYYY HH:mm') }}</v-toolbar-title>
                <v-spacer/>
                <v-btn
                    elevation-1
                    depressed
                    :color="status.bulk[bulk.status].color"
                >
                    {{ status.bulk[bulk.status].name }}
                </v-btn>
            </v-toolbar>
            <v-card-text
                class="text-left"
            >
                <v-row>
                    <v-col xs="12" xl="12" md="12">
                        <kbd
                            class="text-left py-5 px-3 my-2 blue-grey darken-2"
                            style="width: 100%; overflow-x: hidden"
                        >
                            {{ bulk.msg }}
                        </kbd>
                    </v-col>
                </v-row>
                <v-row wrap>
                    <v-col xs="12" lg="4" class="my-3">
                        <table>
                            <tr>
                                <td style="white-space: nowrap">Подпись отправителя:</td>
                                <td class="px-5" style="white-space: nowrap">{{ bulk.signature }}</td>
                            </tr>
                            <tr>
                                <td style="white-space: nowrap">Кол-во SMS:</td>
                                <td class="px-5" style="white-space: nowrap">{{ bulk.num | currency('', 0, { thousandsSeparator: ' ' }) }}</td>
                            </tr>
                            <tr>
                                <td style="white-space: nowrap">Стоимость:</td>
                                <td class="px-5" style="white-space: nowrap">{{ bulk.total | currency('', 2, { thousandsSeparator: ' ' }) }} ₽</td>
                            </tr>
                            <tr v-if="bulk.schedule_from">
                                <td style="white-space: nowrap">Начало отправки:</td>
                                <td class="px-5" style="white-space: nowrap">{{ $moment(bulk.schedule_from).format('DD.MM.YYYY HH:mm') }}</td>
                            </tr>
                            <tr v-if="bulk.schedule_till">
                                <td style="white-space: nowrap">Окончание отправки:</td>
                                <td class="px-5" style="white-space: nowrap">{{ $moment(bulk.schedule_till).format('DD.MM.YYYY HH:mm') }}</td>
                            </tr>
                        </table>
                    </v-col>
                    <v-col xs="12" lg="8" class="my-3" v-if="stats">
                        <table>
                            <tr>
                                <td style="white-space: nowrap">Отправлено SMS:</td>
                                <td class="px-5 text-right" style="white-space: nowrap">{{ stats.enroute + stats.delivered + stats.error | currency('', 0, { thousandsSeparator: ' ' }) }}</td>
                                <td style="width: 100%">
                                    <v-progress-linear
                                        color="primary"
                                        height="15"
                                        :value="100 * (stats.enroute + stats.delivered + stats.error) / bulk.num"
                                        class="ma-0"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td style="white-space: nowrap">Доставлено SMS:</td>
                                <td class="px-5 text-right" style="white-space: nowrap">{{ stats.delivered | currency('', 0, { thousandsSeparator: ' ' }) }}</td>
                                <td style="width: 100%">
                                    <v-progress-linear
                                        color="success"
                                        height="15"
                                        :value="100 * stats.delivered / bulk.num"
                                        class="ma-0"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td style="white-space: nowrap">Не доставлено SMS:</td>
                                <td class="px-5 text-right" style="white-space: nowrap">{{ stats.error | currency('', 0, { thousandsSeparator: ' ' }) }}</td>
                                <td style="width: 100%">
                                    <v-progress-linear
                                        color="error"
                                        height="15"
                                        :value="100 * stats.error / bulk.num"
                                        class="ma-0"
                                    />
                                </td>
                            </tr>
                        </table>
                    </v-col>
                </v-row>
                <v-menu offset-y v-if="['ready', 'unpaid', 'paused'].indexOf(bulk.status) >= 0">
                    <template v-slot:activator="{ on }">
                        <v-btn fab small absolute bottom right color="primary" v-on="on">
                            <v-icon>mdi-menu</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                            v-if="bulk.status === 'ready' || bulk.status === 'unpaid'"
                            @click="bulkPause(bulk)"
                        >
                            <v-icon left>mdi-pause</v-icon>
                            <v-list-item-title>Остановить</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            v-if="bulk.status === 'paused'"
                            @click="bulkResume(bulk)"
                        >
                            <v-icon left>mdi-play</v-icon>
                            <v-list-item-title>Возобновить</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            v-if="bulk.status === 'paused'"
                            @click="bulkCancel(bulk)"
                        >
                            <v-icon left>mdi-delete</v-icon>
                            <v-list-item-title>Отменить</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-card-text>
        </v-card>
        <v-card
            class="mt-10"
            v-if="bulk"
        >
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="data"
                    :loading="loading"
                    :options.sync="options"
                    :footer-props="footer"
                    :server-items-length="bulk.num"
                    must-sort
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td class="text-left">+{{ item.phone }}</td>
                            <td class="text-left" v-bind:class="{'grey--text': !item.routed}">{{ $moment(item.schedule).format('DD.MM.YYYY HH:mm') }}</td>
                            <td class="text-left" v-bind:class="{'grey--text': !item.locked}">{{ $moment(item.locked).format('DD.MM.YYYY HH:mm') || 'Отчет ожидается' }}</td>
                            <td class="text-right">{{ item.total | currency('', 2) }} ₽</td>
                            <td class="text-left" v-bind:class="status.sms[item.status].color + '--text'">{{ status.sms[item.status].name }}</td>
                        </tr>
                    </template>
                    <template v-slot:no-data>
                        <div v-if="loading" class="text-center">
                            Идет загрузка данных
                        </div>
                        <div v-if="!loading" class="text-center">
                            Не найдены SMS
                        </div>
                    </template>
                    <template v-slot:no-results>
                        <div v-if="loading" class="text-center">
                            Идет загрузка данных
                        </div>
                        <div v-if="!loading" class="text-center">
                            Не найдены SMS
                        </div>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
export default {
    name: "BulkSMS",

    props: ['bulk'],

    data () {
        return {
            data: [],
            footer: {
                'items-per-page-options': [10, 25, 50, 100],
                'items-per-page-text': "Строк на странице",
            },
            headers: [
                {
                    text: 'Телефон',
                    value: 'phone',
                },
                {
                    text: 'Время отправки',
                    value: 'schedule',
                },
                {
                    text: 'Время отчета',
                    value: 'locked',
                },
                {
                    text: 'Цена',
                    value: 'price',
                },
                {
                    text: 'Статус',
                    value: 'status',
                    sortable: false,
                },
            ],
            loading: false,
            options: {
                page: 1,
                itemsPerPage: 25,
                sortBy: ['schedule'],
                sortDesc: [false],
            },
            stats: {},
            status: {
                bulk: {
                    done: {
                        name: 'Завершена',
                        color: 'success',
                    },
                    init: {
                        name: 'Формируется',
                        color: 'grey',
                    },
                    paused: {
                        name: 'Остановлена',
                        color: 'warning',
                    },
                    ready: {
                        name: 'Работает',
                        color: 'primary',
                    },
                    reserve: {
                        name: 'Ожидает оплаты',
                        color: 'warning',
                    },
                    unpaid: {
                        name: 'Ожидает оплаты',
                        color: 'warning',
                    },
                },
                sms: {
                    cancel: {
                        name: 'Отменяется',
                        color: 'warning',
                    },
                    cancelled: {
                        name: 'Отменено',
                        color: 'grey',
                    },
                    deleted: {
                        name: 'Удалено оператором',
                        color: 'error',
                    },
                    delivered: {
                        name: 'Доставлено',
                        color: 'success',
                    },
                    enroute: {
                        name: 'Отправлено',
                        color: 'primary',
                    },
                    error: {
                        name: 'Ошибка',
                        color: 'error',
                    },
                    expired: {
                        name: 'Истек срок жизни',
                        color: 'error',
                    },
                    init: {
                        name: 'Формируется',
                        color: 'grey',
                    },
                    noroute: {
                        name: 'Нет маршрута',
                        color: 'error',
                    },
                    paused: {
                        name: 'Остановлено',
                        color: 'warning',
                    },
                    ready: {
                        name: 'Ожидает отправки',
                        color: 'primary',
                    },
                    rejected: {
                        name: 'Отклонено',
                        color: 'error',
                    },
                    reserve: {
                        name: 'Ожидает оплаты',
                        color: 'warning',
                    },
                    route: {
                        name: 'Маршрутизируется',
                        color: 'primary',
                    },
                    undeliverable: {
                        name: 'Ошибка',
                        color: 'error',
                    },
                    unknown: {
                        name: 'Ошибка',
                        color: 'error',
                    },
                    unpaid: {
                        name: 'Ожидает оплаты',
                        color: 'warning',
                    },
                },
            },
        }
    },

    created () {
        this.loadStats()
    },

    watch: {
        bulk: {
            handler () {
                this.loadReport()
                this.loadStats()
            },
            deep: true,
        },
        options: {
            handler () {
                this.loadReport()
            },
            deep: true,
        },
    },

    methods: {
        bulkCancel (bulk) {
            this.$axios.post('/sms/bulk/cancel', {id: bulk.id})
                .then(res => {
                    if (res.data.success) {
                        this.$emit('onCancelled')
                    }
                })
                .catch(() => {})
        },

        bulkPause (bulk) {
            this.$axios.post('/sms/bulk/pause', {id: bulk.id})
                .then(res => {
                    if (res.data.success) {
                        bulk.status = res.data.data.status
                        this.loadReport()
                    }
                })
                .catch(() => {})
        },

        bulkResume (bulk) {
            this.$axios.post('/sms/bulk/resume', {id: bulk.id})
                .then(res => {
                    if (res.data.success) {
                        bulk.status = res.data.data.status
                        this.loadReport()
                    }
                })
                .catch(() => {})
        },

        loadReport () {
            this.loading = true

            const data = {
                bulk_id: this.bulk.id,
                ...this.options,
            }

            this.$axios.post('/report/bulk/search/sms', data)
                .then(res => {
                    if (res.data.success) {
                        this.loading = false
                        this.data = res.data.data
                    } else {
                        this.loading = false
                        this.data = []
                    }
                })
                .catch(() => {
                    this.loading = false
                    this.data = []
                })
        },

        loadStats () {
            if (!this.bulk) {
                return
            }

            this.$axios.post('/report/bulk/status/one', {id: this.bulk.id})
                .then(res => {
                    if (res.data.success) {
                        this.stats = res.data.data
                    }
                })
                .catch(() => {})
        },
    }
}
</script>

<style scoped>
</style>
