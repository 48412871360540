import Vue from 'vue'
import Vue2Filters from 'vue2-filters'
import VueMoment from 'vue-moment'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import './plugins/axios'

Vue.use(Vue2Filters)
Vue.use(VueMoment)

Vue.config.productionTip = false

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
    created: function () {
        this.$axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;

        const authToken = localStorage.getItem('auth-token')

        if (authToken) {
            this.$axios.defaults.headers.common['Authorization'] = authToken
        }

        const self = this

        this.$axios.interceptors.response.use(
            function (res) {
                if (res.headers['x-user-wallet-system'] !== undefined && self.$store.getters.isUser) {
                    self.$store.commit('updateUserWalletSystem', res.headers['x-user-wallet-system'])
                }
                if (res.headers['x-user-wallet-reserve'] !== undefined && self.$store.getters.isUser) {
                    self.$store.commit('updateUserWalletReserve', res.headers['x-user-wallet-reserve'])
                }

                return Promise.resolve(res)
            },
            function (error) {
                if (error.response.status === 401) {
                    self.$store.commit('removeToken')
                    self.$store.commit('removeUser')
                    self.$router.push('/sign-in')
                    return Promise.reject(error)
                }
            }
        )
    },
}).$mount('#app')
