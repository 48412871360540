<template>
    <v-app>
        <v-app-bar app flat clipped-right color="grey lighten-4">
            <v-toolbar-title
                v-if="!isUser"
                class="headline text-uppercase"
            >
                <span class="font-weight-light">YE</span>
                <span>SMS</span>
            </v-toolbar-title>
            <v-app-bar-nav-icon v-if="isUser" @click="sidebar = !sidebar"/>
            <v-spacer/>
            <v-btn-toggle
                dense
                borderless
                v-if="isUser"
            >
                <v-btn
                    to="/finance"
                    color="blue-grey darken-2"
                    class="white--text px-7 v-btn--active"
                >
                    {{ user.walletSystem | currency('', 2, { thousandsSeparator: ' ' }) }} ₽
                </v-btn>
                <v-btn
                    class="success lighten-2 v-btn--active"
                    to="/finance"
                >
                    <v-icon color="white">mdi-plus</v-icon>
                </v-btn>
            </v-btn-toggle>
            <v-spacer/>
            <v-btn
                v-if="isUser"
                text
                @click="signOut"
            >
                Выйти
            </v-btn>
        </v-app-bar>

        <v-navigation-drawer
            v-if="isUser"
            v-model="sidebar"
            app
        >
            <v-card flat>
                <v-toolbar flat color="grey lighten-4">
                    <v-toolbar-title class="headline text-uppercase">
                        <span class="font-weight-light">YE</span>
                        <span>SMS</span>
                    </v-toolbar-title>
                </v-toolbar>
            </v-card>
            <v-divider/>
            <v-card flat>
                <v-list flat class="pt-0 pb-0">
                    <router-link
                        v-for="(item, i) in sidebarItems"
                        :key="i"
                        :to="item.href"
                        active-class="grey lighten-4"
                        class="d-block"
                        exact flat
                    >
                        <v-list-item>
                            <v-list-item-action>
                                <v-icon>{{item.icon}}</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>{{item.title}}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>
                </v-list>
            </v-card>
        </v-navigation-drawer>

        <v-main>
            <v-divider/>
            <router-view/>
        </v-main>
    </v-app>
</template>

<script>
export default {
    name: 'App',
    data () {
        return {
            sidebar: true,
            sidebarItems: [
                // {
                //     href: '/',
                //     title: 'Сводные данные',
                //     icon: 'mdi-view-dashboard',
                // },
                {
                    href: '/message',
                    title: 'Создать рассылку',
                    icon: 'mdi-send',
                },
                {
                    href: '/signature',
                    title: 'Подписи отправителя',
                    icon: 'mdi-at',
                },
                {
                    href: '/phonebook',
                    title: 'Списки получателей',
                    icon: 'mdi-view-list',
                },
                {
                    href: '/report',
                    title: 'Отчеты по рассылкам',
                    icon: 'mdi-format-list-checkbox',
                },
                {
                    href: '/template',
                    title: 'Шаблоны сообщений',
                    icon: 'mdi-code-json',
                },
                // {
                //     href: '/schedule',
                //     title: 'Планировщик',
                //     icon: 'mdi-calendar',
                // },
                {
                    href: '/blacklist',
                    title: 'Черный список',
                    icon: 'mdi-cancel',
                },
                {
                    href: '/finance',
                    title: 'Финансы',
                    icon: 'mdi-wallet',
                },
                {
                    href: '/config',
                    title: 'Настройки',
                    icon: 'mdi-cog',
                },
                {
                    href: '/support',
                    title: 'Служба поддержки',
                    icon: 'mdi-help-circle',
                },
            ],
        }
    },
    created: function () {
        if (this.isUser) {
            this.$axios.post('/user/account')
                .then(res => {
                    if (res.data.success) {
                        this.$store.commit('updateUser', res.data.data)
                    }
                })
        }
    },
    computed: {
        user () {
            return this.$store.getters.user
        },
        isUser () {
            return this.$store.getters.isUser
        },
    },
    watch: {
        isUser () {
            if (this.isUser) {
                this.$axios.post('/user/account')
                    .then(res => {
                        if (res.data.success) {
                            this.$store.commit('updateUser', res.data.data)
                        }
                    })
            }
        }
    },
    methods: {
        signOut () {
            this.$axios.post('/sign-out')
                .then(res => {
                    if (res.data.success) {
                        this.$store.commit('removeToken')
                        this.$store.commit('removeUser')
                        delete this.$axios.defaults.headers.common['Authorization']
                        this.$router.push('/sign-in')
                    }
                })
        }
    },
}
</script>

<style scoped>
.v-btn--active {
    opacity: 1 !important;
}

.v-navigation-drawer a {
    text-decoration: none;
    color: inherit;
}

.v-navigation-drawer a:hover {
    background-color: #f5f5f5;
}
</style>
