<template>
    <v-container>
        <v-layout
            text-center
            wrap
        >
            <v-flex xs12>
                <v-timeline dense>
                    <v-timeline-item
                        icon="mdi-at"
                        color="blue-grey"
                        fill-dot
                        small
                        right
                    >
                        <v-card>
                            <v-toolbar flat dense class="pa-auto" color="grey lighten-4">
                                <v-toolbar-title>От кого</v-toolbar-title>
                            </v-toolbar>
                            <v-card-text class="white">
                                <v-radio-group
                                    v-model="bulk.category"
                                    @change="bulk.signature_id = ''; calc.data = []; calc.errors.signature_id = []"
                                    row
                                >
                                    <v-radio
                                        color="primary"
                                        label="SMS"
                                        value="sms"
                                    />
                                    <v-radio
                                        color="primary"
                                        label="Viber"
                                        value="viber"
                                    />
                                    <v-radio
                                        color="primary"
                                        label="Viber с переотправкой по SMS"
                                        value="viber_sms"
                                    />
                                </v-radio-group>
                                <v-select
                                    v-if="bulk.category === 'viber' || bulk.category === 'viber_sms'"
                                    v-model="bulk.viber_signature_id"
                                    :items="signature.viber.data"
                                    :error-messages="calc.errors.viber_signature_id"
                                    item-text="signature"
                                    item-value="id"
                                    label="Подпись отправителя для Viber"
                                    placeholder="Выберите подпись отправителя для Viber"
                                    @change="calc.data = []; calc.errors.viber_signature_id = []"
                                />
                                <v-select
                                    v-if="bulk.category === 'sms' || bulk.category === 'viber_sms'"
                                    v-model="bulk.sms_signature_id"
                                    :items="signature.sms.data"
                                    :error-messages="calc.errors.sms_signature_id"
                                    item-text="signature"
                                    item-value="id"
                                    label="Подпись отправителя для SMS"
                                    placeholder="Выберите подпись отправителя для SMS"
                                    @change="calc.data = []; calc.errors.sms_signature_id = []"
                                />
                            </v-card-text>
                        </v-card>
                    </v-timeline-item>
                    <v-timeline-item
                        v-if="step2"
                        icon="mdi-view-list"
                        color="blue-grey"
                        fill-dot
                        small
                        right
                    >
                        <v-card>
                            <v-toolbar flat dense class="pa-auto" color="grey lighten-4">
                                <v-toolbar-title>Кому</v-toolbar-title>
                            </v-toolbar>
                            <v-card-text>
                                <v-radio-group
                                    v-model="bulk.phonebook_type"
                                    row
                                    @change="smsCount(); viberCount()"
                                >
                                    <v-radio
                                        color="primary"
                                        label="По списку получателей"
                                        value="phonebook"
                                    />
                                    <v-radio
                                        color="primary"
                                        label="Указать вручную"
                                        value="manual"
                                    />
                                </v-radio-group>
                                <div
                                    v-if="bulk.phonebook_type === 'phonebook'"
                                >
                                    <v-select
                                        v-model="bulk.phonebook_id"
                                        :items="phonebook.phonebooks"
                                        :error-messages="calc.errors.phonebook_id"
                                        item-text="name"
                                        item-value="id"
                                        label="Список получателей"
                                        placeholder="Выберите список получателей"
                                        @change="smsCount(); viberCount(); calc.errors.phonebook_id = []"
                                    />
                                    <div
                                        v-for="(book, i) in find(this.phonebook.phonebooks, bulk.phonebook_id, 'id')"
                                        :key="i"
                                    >
                                        <div v-if="book.filterByBirthday">
                                            <v-checkbox
                                                v-model="bulk.filter_by_birthday"
                                                label="Фильтровать по дате празднования дня рождения в этом году"
                                                color="primary"
                                                class="my-0 py-0"
                                                @change="calc.data = []"
                                            />
                                            <v-layout
                                                v-if="bulk.filter_by_birthday"
                                                row
                                                wrap
                                                ml-5 mb-3
                                            >
                                                <v-flex
                                                    xs12 sm6 md3
                                                    class="mr-3"
                                                >
                                                    <v-menu
                                                        ref="menuBirthdayFromDate"
                                                        v-model="menu.birthdayFromDate"
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="290px"
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                            <v-text-field
                                                                v-model="birthdayFromDateFormatted"
                                                                :error-messages="calc.errors.birthday_from_date"
                                                                label="Начало периода"
                                                                prepend-icon="mdi-calendar"
                                                                readonly
                                                                v-on="on"
                                                            />
                                                        </template>
                                                        <v-date-picker
                                                            v-model="bulk.birthday_from_date"
                                                            locale="ru"
                                                            first-day-of-week="1"
                                                            color="primary"
                                                            @input="checkBirthdayFromDate(); calc.errors.birthday_from_date = []"
                                                        />
                                                    </v-menu>
                                                </v-flex>
                                                <v-flex
                                                    xs12 sm6 md3
                                                >
                                                    <v-menu
                                                        ref="menuBirthdayTillDate"
                                                        v-model="menu.birthdayTillDate"
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="290px"
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                            <v-text-field
                                                                v-model="birthdayTillDateFormatted"
                                                                :error-messages="calc.errors.birthday_till_date"
                                                                label="Окончание периода"
                                                                prepend-icon="mdi-calendar"
                                                                readonly
                                                                v-on="on"
                                                            />
                                                        </template>
                                                        <v-date-picker
                                                            v-model="bulk.birthday_till_date"
                                                            locale="ru"
                                                            first-day-of-week="1"
                                                            color="primary"
                                                            @input="checkBirthdayTillDate(); calc.errors.birthday_till_date"
                                                        />
                                                    </v-menu>
                                                </v-flex>
                                                <v-flex
                                                    v-if="phonebook.leap"
                                                    xs12 sm12 md12
                                                >
                                                    <v-switch
                                                        v-if="bulk.phonebook_type === 'phonebook'"
                                                        v-model="bulk.leap"
                                                        color="primary"
                                                        label="Включать в выборку 29 февраля для високосных годов"
                                                        class="mt-0"
                                                        @change="calc.data = []"
                                                    />
                                                </v-flex>
                                            </v-layout>
                                        </div>
                                        <div v-if="book.filterByGender">
                                            <v-checkbox
                                                v-model="bulk.filter_by_gender"
                                                label="Фильтровать по полу"
                                                color="primary"
                                                class="my-0 py-0"
                                                @change="calc.data = []"
                                            />
                                            <v-radio-group
                                                v-if="bulk.filter_by_gender"
                                                v-model="bulk.gender"
                                                row
                                                class="mt-0 pt-0 ml-5"
                                                @change="calc.data = []"
                                            >
                                                <v-radio color="primary" label="Мужской" value="male"/>
                                                <v-radio color="primary" label="Женский" value="female"/>
                                            </v-radio-group>
                                        </div>
                                    </div>
                                </div>
                                <v-switch
                                    v-if="bulk.phonebook_type === 'manual'"
                                    v-model="bulk.russia"
                                    color="primary"
                                    label="Номера, начинающиеся на 8, считать российскими"
                                    class="mt-0"
                                    @change="calc.data = []"
                                />
                                <v-textarea
                                    v-if="bulk.phonebook_type === 'manual'"
                                    v-model="bulk.phones"
                                    label="Список получателей"
                                    placeholder="Укажите номера получателей через запятую или с новой строки"
                                    @change="calc.data = []; calc.errors.phones = []"
                                />
                            </v-card-text>
                        </v-card>
                    </v-timeline-item>
                    <v-timeline-item
                        v-if="step3"
                        icon="mdi-clock-outline"
                        color="blue-grey"
                        fill-dot
                        small
                        right
                    >
                        <v-card>
                            <v-toolbar flat dense class="pa-auto" color="grey lighten-4">
                                <v-toolbar-title>Когда</v-toolbar-title>
                            </v-toolbar>
                            <v-card-text>
                                <v-radio-group v-model="bulk.schedule" class="mb-0" row>
                                    <v-radio color="primary" label="Сейчас" value="now"/>
                                    <v-radio color="primary" label="Позже" value="schedule"/>
                                </v-radio-group>
                                <div
                                    v-if="bulk.schedule === 'now' || bulk.schedule === 'schedule'"
                                >
                                    <v-switch
                                        v-model="bulk.even"
                                        color="primary"
                                        label="Рассылать равномерно"
                                        class="my-0"
                                    />
                                    <v-row wrap>
                                        <v-col
                                            v-if="bulk.schedule === 'schedule'"
                                            xs="12" sm="6" md="2"
                                            class="pt-0"
                                        >
                                            <v-menu
                                                ref="menuFromDate"
                                                v-model="menu.fromDate"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        v-model="fromDateFormatted"
                                                        :error-messages="calc.errors.from_date"
                                                        label="Дата начала"
                                                        prepend-icon="mdi-calendar"
                                                        readonly
                                                        v-on="on"
                                                    />
                                                </template>
                                                <v-date-picker
                                                    v-model="bulk.from_date"
                                                    locale="ru"
                                                    first-day-of-week="1"
                                                    color="primary"
                                                    @input="menu.fromDate = false; calc.errors.from_date = []"
                                                />
                                            </v-menu>
                                        </v-col>
                                        <v-col
                                            v-if="bulk.schedule === 'schedule'"
                                            xs="12" sm="6" md="2"
                                            class="pt-0"
                                        >
                                            <v-menu
                                                ref="menuFromTime"
                                                v-model="menu.fromTime"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        v-model="bulk.from_time"
                                                        :error-messages="calc.errors.from_time"
                                                        label="Время начала"
                                                        prepend-icon="mdi-clock-outline"
                                                        readonly
                                                        v-on="on"
                                                    />
                                                </template>
                                                <v-time-picker
                                                    v-model="bulk.from_time"
                                                    format="24hr"
                                                    color="primary"
                                                    @click:minute="$refs.menuFromTime.save(bulk.from_time); calc.errors.from_time = []"
                                                />
                                            </v-menu>
                                        </v-col>
                                        <v-col
                                            v-if="bulk.even"
                                            xs="12" sm="6" md="2" ml-3
                                            class="pt-0"
                                        >
                                            <v-menu
                                                ref="menuTillTime"
                                                v-model="menu.tillTime"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        v-model="bulk.till_time"
                                                        :error-messages="calc.errors.till_time"
                                                        label="Время окончания"
                                                        prepend-icon="mdi-clock-outline"
                                                        readonly
                                                        v-on="on"
                                                    />
                                                </template>
                                                <v-time-picker
                                                    v-model="bulk.till_time"
                                                    format="24hr"
                                                    color="primary"
                                                    @click:minute="$refs.menuTillTime.save(bulk.till_time); calc.errors.till_time = []"
                                                />
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                    <v-row
                                        v-if="bulk.category === 'viber_sms'"
                                    >
                                        <v-col xs="6" md="4">
                                            <v-text-field
                                                v-model="bulk.viber_lifetime"
                                                :error-messages="calc.errors.viber_lifetime"
                                                label="Срок жизни сообщений Viber, от 1 до 24 часов"
                                            />
                                        </v-col>
                                    </v-row>
                                    <div class="subheading text-left mt-5">
                                        Сообщения актуальны до:
                                    </div>
                                    <v-row>
                                        <v-col xs="12" sm="6" md="2">
                                            <v-menu
                                                ref="menuExpireDate"
                                                v-model="menu.expireDate"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        v-model="expireDateFormatted"
                                                        :error-messages="calc.errors.expire_date"
                                                        label="Дата"
                                                        prepend-icon="mdi-calendar"
                                                        readonly
                                                        v-on="on"
                                                    />
                                                </template>
                                                <v-date-picker
                                                    v-model="bulk.expire_date"
                                                    locale="ru"
                                                    first-day-of-week="1"
                                                    color="primary"
                                                    @input="menu.expireDate = false; calc.errors.expire_date = []"
                                                />
                                            </v-menu>
                                        </v-col>
                                        <v-col xs="12" sm="6" md="2">
                                            <v-menu
                                                ref="menuExpireTime"
                                                v-model="menu.expireTime"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        v-model="bulk.expire_time"
                                                        :error-messages="calc.errors.expire_time"
                                                        label="Время"
                                                        prepend-icon="mdi-clock-outline"
                                                        readonly
                                                        v-on="on"
                                                    />
                                                </template>
                                                <v-time-picker
                                                    v-model="bulk.expire_time"
                                                    format="24hr"
                                                    color="primary"
                                                    @click:minute="$refs.menuExpireTime.save(bulk.expire_time); calc.errors.expire_time = []"
                                                />
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-timeline-item>
                    <v-timeline-item
                        v-if="step4 && (bulk.category === 'viber' || bulk.category === 'viber_sms')"
                        icon="mdi-pencil"
                        color="blue-grey"
                        fill-dot
                        small
                        right
                    >
                        <v-card>
                            <v-toolbar flat dense class="pa-auto" color="grey lighten-4">
                                <v-toolbar-title>Что (Viber)</v-toolbar-title>
                            </v-toolbar>
                            <v-card-text class="text-left">
                                <v-radio-group
                                    v-model="bulk.viber_type"
                                    row
                                >
                                    <v-radio
                                        color="primary"
                                        label="Текст + картинка + кнопка"
                                        value="text_button_image"
                                    />
                                    <v-radio
                                        color="primary"
                                        label="Текст + кнопка"
                                        value="text_button"
                                    />
                                    <v-radio
                                        color="primary"
                                        label="Текст"
                                        value="text"
                                    />
                                    <v-radio
                                        color="primary"
                                        label="Картинка"
                                        value="image"
                                    />
                                </v-radio-group>
                                <v-text-field
                                    v-if="bulk.viber_type === 'text_button' || bulk.viber_type === 'text_button_image'"
                                    v-model="bulk.viber_button_caption"
                                    :error-messages="calc.errors.viber_button_caption"
                                    name="viber_button_caption"
                                    label="Надпись на кнопке"
                                    placeholder="Введите надпись для кнопки"
                                    maxlength="19"
                                />
                                <v-text-field
                                    v-if="bulk.viber_type === 'text_button' || bulk.viber_type === 'text_button_image'"
                                    v-model="bulk.viber_button_url"
                                    :error-messages="calc.errors.viber_button_url"
                                    name="viber_button_url"
                                    label="Ссылка"
                                    placeholder="Введите ссылку"
                                />
                                <v-text-field
                                    v-if="bulk.viber_type === 'image' || bulk.viber_type === 'text_button_image'"
                                    v-model="bulk.viber_image_filename"
                                    :error-messages="calc.errors.viber_image"
                                    :readonly="!!bulk.viber_image_filename"
                                    :disabled="calc.wait || send.wait"
                                    name="viber_image"
                                    label="Картинка"
                                    type="text"
                                    clearable
                                    @click="viberImageFileSelect"
                                    @click:clear="viberImageFileReset"
                                />
                                <input
                                    v-if="upload.viber_image && (bulk.viber_type === 'image' || bulk.viber_type === 'text_button_image')"
                                    type="file"
                                    style="display: none"
                                    ref="viber_image"
                                    accept=".jpg,.jpeg,.png,.gif"
                                    @change="viberImageFileSelected"
                                />
                                <div v-if="bulk.viber_type === 'text' || bulk.viber_type === 'text_button' || bulk.viber_type === 'text_button_image'">
                                    <v-textarea
                                        v-model="bulk.viber_message"
                                        :error-messages="calc.errors.viber_message"
                                        ref="viber_message"
                                        name="viber_message"
                                        label="Содержание сообщения Viber"
                                        placeholder="Введите текст сообщения"
                                        maxlength="1000"
                                        @input="viberCount(); calc.errors.viber_message = []"
                                    />
                                    <v-row>
                                        <v-col>
                                            <div
                                                v-if="bulk.phonebook_type === 'phonebook' && bulk.phonebook_id"
                                            >
                                                <div
                                                    v-for="(book, i) in find(this.phonebook.phonebooks, bulk.phonebook_id, 'id')"
                                                    :key="i"
                                                >
                                                    <div
                                                        v-for="(param, i) in book.params"
                                                        :key="i"
                                                        class="d-inline"
                                                    >
                                                        <v-btn
                                                            v-if="param.type === 'last_name' || param.type === 'first_name' || param.type === 'middle_name' || param.type === 'custom'"
                                                            small
                                                            color="primary"
                                                            class="ml-1"
                                                            @click="viberAddParam(param)"
                                                        >
                                                            {{ param.name }}
                                                        </v-btn>
                                                    </div>
                                                </div>
                                            </div>
                                        </v-col>
                                        <v-col>
                                            <div class="caption text-right pt-1">
                                                Длина сообщения:
                                                <span v-if="viber.maxLength > viber.minLength">
                                                    от {{ viber.minLength }} до {{ viber.maxLength }} символов
                                                </span>
                                                <span v-else>
                                                    {{ viber.minLength }} символов
                                                </span>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-alert
                                        :value="viber.error"
                                        color="error"
                                        icon="mdi-exclamation"
                                        outlined
                                        class="text-left mt-3"
                                    >
                                        Сообщение содержит метки вида <span v-pre>{{</span>МЕТКА<span v-pre>}}</span>, которым не соответствует ни один из параметров списка получателей.
                                    </v-alert>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-timeline-item>
                    <v-timeline-item
                        v-if="step4 && (bulk.category === 'sms' || bulk.category === 'viber_sms')"
                        icon="mdi-pencil"
                        color="blue-grey"
                        fill-dot
                        small
                        right
                    >
                        <v-card>
                            <v-toolbar flat dense class="pa-auto" color="grey lighten-4">
                                <v-toolbar-title>Что (SMS)</v-toolbar-title>
                            </v-toolbar>
                            <v-card-text class="text-left">
                                <v-textarea
                                    v-model="bulk.sms_message"
                                    :error-messages="calc.errors.sms_message"
                                    ref="sms_message"
                                    name="sms_message"
                                    label="Содержание SMS"
                                    placeholder="Введите текст сообщения"
                                    @input="smsCount(); calc.errors.sms_message = []"
                                />
                                <v-row>
                                    <v-col>
                                        <div
                                            v-if="bulk.phonebook_type === 'phonebook' && bulk.phonebook_id"
                                        >
                                            <div
                                                v-for="(book, i) in find(this.phonebook.phonebooks, bulk.phonebook_id, 'id')"
                                                :key="i"
                                            >
                                                <div
                                                    v-for="(param, i) in book.params"
                                                    :key="i"
                                                    class="d-inline"
                                                >
                                                    <v-btn
                                                        v-if="param.type === 'last_name' || param.type === 'first_name' || param.type === 'middle_name' || param.type === 'custom'"
                                                        small
                                                        color="primary"
                                                        class="mt-0 mr-1 mb-2"
                                                        @click="smsAddParam(param)"
                                                    >
                                                        {{ param.name }}
                                                    </v-btn>
                                                </div>
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col>
                                        <div class="caption text-right pt-1">
                                            Длина сообщения:
                                            <span v-if="sms.maxLength > sms.minLength">
                                                от {{ sms.minLength }} до {{ sms.maxLength }} символов,
                                            </span>
                                            <span v-else>
                                                {{ sms.minLength }} символов,
                                            </span>
                                            SMS содержит:
                                            <span v-if="sms.maxParts > sms.minParts">
                                                от {{ sms.minParts }} до {{ sms.maxParts }} частей
                                            </span>
                                            <span v-else>
                                                {{ sms.minParts }}
                                                <span v-if="sms.minParts > 1">
                                                    части
                                                </span>
                                                <span v-else>
                                                    часть
                                                </span>
                                            </span>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-alert
                                    :value="sms.maxParts > 1"
                                    color="warning"
                                    icon="mdi-exclamation"
                                    outlined
                                    class="text-left mt-3"
                                >
                                    <span v-if="sms.maxParts > sms.minParts">
                                        SMS содержит до {{ sms.maxParts }} частей
                                    </span>
                                    <span v-else>
                                        SMS содержит {{ sms.maxParts }} части
                                    </span>
                                </v-alert>
                                <v-alert
                                    :value="sms.error"
                                    color="error"
                                    icon="mdi-exclamation"
                                    outlined
                                    class="text-left mt-3"
                                >
                                    SMS содержит метки вида <span v-pre>{{</span>МЕТКА<span v-pre>}}</span>, которым не соответствует ни один из параметров списка получателей.
                                </v-alert>
                            </v-card-text>
                        </v-card>
                    </v-timeline-item>
                    <v-timeline-item
                        v-if="step2 && ((bulk.phonebook_type === 'phonebook' && bulk.phonebook_id) || (bulk.phonebook_type === 'manual' && bulk.phones)) && bulk.sms_message"
                        icon="mdi-check"
                        color="blue-grey"
                        fill-dot
                        small
                        right
                    >
                        <v-card>
                            <v-toolbar flat dense class="pa-auto" color="grey lighten-4">
                                <v-toolbar-title>Сколько стоит</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <div class="align-end">
                                    <v-switch
                                        v-model="calc.detailed"
                                        color="primary"
                                        label="Подробно"
                                        class="mt-5"
                                    ></v-switch>
                                </div>
                            </v-toolbar>
                            <v-card-text
                                class="text-center"
                            >
                                <v-data-table
                                    v-if="calc.total && calc.data.length && calc.detailed && !calc.wait"
                                    :headers="calc.headers"
                                    :items="calc.data"
                                    hide-default-footer
                                >
                                    <template v-slot:item="{ item }">
                                        <tr>
                                            <td v-if="item.parts" class="text-left">{{ item.operator }}</td>
                                            <td v-if="item.parts" class="text-right">{{ item.num | currency('', 0, { thousandsSeparator: ' ' }) }}</td>
                                            <td v-if="item.parts" class="text-right">{{ item.parts | currency('', 0, { thousandsSeparator: ' ' }) }}</td>
                                            <td v-if="item.parts" class="text-right">{{ item.price | currency('', 2, { thousandsSeparator: ' ' }) }}</td>
                                            <td v-if="item.parts" class="text-right">{{ item.total | currency('', 2, { thousandsSeparator: ' ' }) }}</td>
                                            <td v-if="!item.parts" class="text-left font-weight-bold">{{ item.operator }}</td>
                                            <td v-if="!item.parts" class="text-right font-weight-bold">{{ item.sms | currency('', 0, { thousandsSeparator: ' ' }) }}</td>
                                            <td v-if="!item.parts" colspan="3" class="text-right font-weight-bold">{{ item.total | currency('', 2, { thousandsSeparator: ' ' }) }}</td>
                                        </tr>
                                    </template>
                                </v-data-table>
                                <v-btn
                                    v-if="!calc.data.length && !calc.wait"
                                    color="blue-grey darken-2"
                                    dark
                                    class="ma-5"
                                    @click="estimate"
                                >
                                    <v-icon class="pr-2">mdi-refresh</v-icon>
                                    Подсчитать
                                </v-btn>
                                <v-progress-circular
                                    v-if="calc.wait"
                                    size="75"
                                    width="7"
                                    color="blue-grey darken-2"
                                    indeterminate
                                ></v-progress-circular>
                                <div
                                    v-if="calc.total && !calc.wait && !calc.detailed && calc.data.length"
                                    class="headline blue-grey--text text--darken-1 ma-5"
                                >
                                    {{ calc.total | currency('', 2, { thousandsSeparator: ' ' }) }} ₽
                                </div>
                                <div
                                    v-if="!calc.total && !calc.wait && calc.data.length"
                                    class="title error--text text--darken-1 ma-5"
                                >
                                    Не найдено номеров получателей, удовлетворяющих всем условиям
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-timeline-item>
                    <v-timeline-item
                        v-if="calc.total && calc.data.length > 0"
                        icon="mdi-send"
                        color="blue-grey"
                        fill-dot
                        small
                        right
                        class="text-center pt-5"
                        @click="calc.wait= true"
                    >
                        <v-btn
                            v-if="!send.wait"
                            @click="commit"
                            dark
                            color="primary"
                            class="ma-5"
                        >
                            <v-icon class="pr-2">mdi-send</v-icon>
                            Отправить
                        </v-btn>
                        <v-progress-circular
                            v-if="send.wait"
                            size="75"
                            width="7"
                            color="primary"
                            indeterminate
                        ></v-progress-circular>
                    </v-timeline-item>
                </v-timeline>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import Vue2Filters from 'vue2-filters'

export default {
    name: "MessageController",
    mixins: [Vue2Filters.mixin],
    data: function () {
        return {
            bulk: {
                category: 'sms', // Тип рассылки: sms, viber, viber_sms
                sms_signature_id: '5ef8df7c2bbb3a441017f6d2', // Подпись SMS
                viber_signature_id: '6001ef5711ddc471ca4cbba2', // Подпись Viber

                phonebook_type: 'phonebook', // Тип списка отправителей: phonebook, manual
                phonebook_id: '5f00e6f742d88b1055020531', // ID списка получателей
                phones: '+79372629572\n+79085509888', // Список получателей
                russia: true, // Номера, начинающиеся на 8, считать российскими
                filter_by_birthday: false, // Фильтровать по дате празднования дня рождения в этом году
                birthday_from_date: this.$moment().format('YYYY-MM-DD'), // Начало периода
                birthday_till_date: this.$moment().format('YYYY-MM-DD'), // Окончание периода
                leap: false, // Включать в выборку 29 февраля для високосных годов
                filter_by_gender: false, // Фильтровать по полу
                gender: 'male', // Пол: mail, female

                schedule: 'now', // Когда рассылать: now, schedule
                from_date: this.$moment().format('YYYY-MM-DD'), // Дата начала
                from_time: this.$moment().format('HH:mm'), // Время начала
                even: false, // Равномерная рассылка
                till_time: this.$moment('18', 'HH').isBefore(this.$moment()) ? (this.$moment('20', 'HH').isBefore(this.$moment()) ? '23:59' : '20:00') : '18:00', // Время окончания
                viber_lifetime: 12, // Срок жизни сообщений Viber, от 1 до 24 часов
                expire_date: this.$moment().add(3, 'days').format('YYYY-MM-DD'), // Дата истечения срока жизни сообщений
                expire_time: this.$moment().format('HH:mm'), // Время истечения срока жизни сообщений

                viber_type: 'text_button', // Тип сообщения Viber: text_button_image, text_button, text, image
                viber_button_caption: 'Жми!', // Надпись на кнопке
                viber_button_url: 'https://yesms.dev/buy', // Ссылка
                viber_image: '', // Изображение
                viber_image_filename: '', // Название файлы с картинкой
                viber_message: '{{Фамилия}} {{Имя}} {{Отчество}} {{Доп. параметр}}', // Текст сообщения Viber

                sms_message: '{{Фамилия}} {{Имя}} {{Отчество}} {{Доп. параметр}}', // Текст SMS
            },
            calc: {
                data: [],
                detailed: false,
                errors: {},
                headers: [
                    {
                        text: 'Оператор',
                        value: 'operator',
                        sortable: false,
                    },
                    {
                        text: 'Кол-во SMS, шт.',
                        value: 'sms',
                        sortable: false,
                        align: 'right',
                    },
                    {
                        text: 'Кол-во частей, шт.',
                        value: 'parts',
                        sortable: false,
                        align: 'right',
                    },
                    {
                        text: 'Цена, ₽',
                        value: 'price',
                        sortable: false,
                        align: 'right',
                    },
                    {
                        text: 'Стоимость, ₽',
                        value: 'total',
                        sortable: false,
                        align: 'right',
                    },
                ],
                total: 0,
                wait: false,
            },
            menu: {
                birthdayFromDate: false,
                birthdayTillDate: false,
                expireDate: false,
                expireTime: false,
                fromDate: false,
                fromTime: false,
                tillTime: false,
            },
            phonebook: {
                leap: !this.$moment().isLeapYear() && (this.$moment().format('MM-DD') === '05-01' || this.$moment().format('MM-DD') === '04-28'),
                loading: true,
                phonebooks: [],
            },
            send: {
                wait: false,
            },
            signature: {
                sms: {
                    data: [],
                    loading: true,
                },
                viber: {
                    data: [],
                    loading: true,
                },
            },
            sms: {
                countable: false,
                error: false,
                unicode: false,
                minLength: 0,
                maxLength: 0,
                minParts: 1,
                maxParts: 1,
            },
            upload: {
                viber_image: true,
            },
            viber: {
                countable: false,
                error: false,
                minLength: 0,
                maxLength: 0,
            },
        }
    },

    computed: {
        birthdayFromDateFormatted () {
            return this.formatDate(this.bulk.birthday_from_date)
        },
        birthdayTillDateFormatted () {
            return this.formatDate(this.bulk.birthday_till_date)
        },
        expireDateFormatted () {
            return this.formatDate(this.bulk.expire_date)
        },
        fromDateFormatted () {
            return this.formatDate(this.bulk.from_date)
        },
        step2 () {
            return (this.bulk.category === 'sms' && this.bulk.sms_signature_id) || (this.bulk.category === 'viber' && this.bulk.viber_signature_id) || (this.bulk.category === 'viber_sms' && this.bulk.sms_signature_id && this.bulk.viber_signature_id)
        },
        step3 () {
            return this.step2 && ((this.bulk.phonebook_type === 'phonebook' && this.bulk.phonebook_id) || (this.bulk.phonebook_type === 'manual' && this.bulk.phones))
        },
        step4 () {
            return this.step3 && (this.bulk.schedule === 'now' || this.bulk.schedule === 'schedule')
        },
    },

    created () {
        this.searchSMSSignatures()
        this.searchViberSignatures()

        this.$axios.post('/phonebook/search')
            .then(res => {
                if (res.data.success) {
                    this.phonebook.phonebooks = res.data.data
                }
                this.phonebook.loading = false
            })
            .catch(() => {
                this.phonebook.loading = false
            })
    },

    methods: {
        checkBirthdayFromDate () {
            this.menu.birthdayFromDate = false

            if (!this.$moment().isLeapYear()) {
                if (this.$moment(this.bulk.birthday_from_date).format('MM-DD') === '05-01') {
                    return this.phonebook.leap = true
                }

                if (this.$moment(this.bulk.birthday_till_date).format('MM-DD') === '04-28') {
                    return this.phonebook.leap = true
                }
            }

            this.phonebook.leap = false

            return true
        },

        checkBirthdayTillDate () {
            this.calc.data = []

            this.menu.birthdayTillDate = false

            if (!this.$moment().isLeapYear()) {
                if (this.$moment(this.bulk.birthday_from_date).format('MM-DD') === '05-01') {
                    return this.phonebook.leap = true
                }

                if (this.$moment(this.bulk.birthday_till_date).format('MM-DD') === '04-28') {
                    return this.phonebook.leap = true
                }
            }

            this.phonebook.leap = false

            return true
        },

        commit () {
            this.send.wait = true

            this.$axios.post('/message/bulk/create', this.bulk)
                .then(res => {
                    if (res.data.success) {
                        this.$router.push('/report')
                    } else {
                        this.send.wait = false
                        if (Array.isArray(res.data.errors) && !res.data.errors.length) {
                            this.calc.total = 0
                            this.calc.data = [{
                                operator: 'Итого',
                                sms: 0,
                                parts: 0,
                                price: 0,
                                total: 0,
                            }]
                        } else {
                            this.calc.errors = res.data.errors
                        }
                    }
                })
                .catch(() => {
                    this.send.wait = false
                })
        },

        estimate () {
            this.calc.wait = true

            this.calc.errors = {};

            const data = new FormData;

            data.append('category', this.bulk.category)
            data.append('sms_signature_id', this.bulk.sms_signature_id)
            data.append('viber_signature_id', this.bulk.viber_signature_id)

            data.append('phonebook_type', this.bulk.phonebook_type)
            data.append('phonebook_id', this.bulk.phonebook_id)
            data.append('phones', this.bulk.phones)
            data.append('russia', this.bulk.russia ? '1' : '0')
            data.append('filter_by_birthday', this.bulk.filter_by_birthday ? '1' : '0')
            data.append('birthday_from_date', this.bulk.birthday_from_date)
            data.append('birthday_till_date', this.bulk.birthday_till_date)
            data.append('leap', this.bulk.leap ? '1' : '0')
            data.append('filter_by_gender', this.bulk.filter_by_gender ? '1' : '0')
            data.append('gender', this.bulk.gender)

            data.append('schedule', this.bulk.schedule)
            data.append('from_date', this.bulk.from_date)
            data.append('from_time', this.bulk.from_time)
            data.append('even', this.bulk.even ? '1' : '0')
            data.append('till_time', this.bulk.till_time)
            data.append('viber_lifetime', this.bulk.viber_lifetime)
            data.append('expire_date', this.bulk.expire_date)
            data.append('expire_time', this.bulk.expire_time)

            data.append('viber_type', this.bulk.viber_type)
            data.append('viber_button_caption', this.bulk.viber_button_caption)
            data.append('viber_button_url', this.bulk.viber_button_url)
            data.append('viber_image', this.bulk.viber_image)
            data.append('viber_message', this.bulk.viber_message)

            data.append('sms_message', this.bulk.sms_message)

            this.$axios.post('/message/bulk/estimate', data)
                .then(res => {
                    if (res.data.success) {
                        this.calc.total = res.data.data.total
                        this.calc.data = Object.values(res.data.data.details)
                    } else {
                        this.calc.errors = res.data.errors
                    }
                    this.calc.wait = false
                })
                .catch(() => {
                    this.calc.total = 0
                    this.calc.data = []
                    this.calc.wait = false
                })
        },

        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}.${month}.${year}`
        },

        searchSMSSignatures () {
            this.signature.sms.loading = true

            this.$axios.post('/signature/sms/search', {category: 'sms_promo'})
                .then(res => {
                    if (res.data.success) {
                        this.signature.sms.data = res.data.data
                    }
                    this.signature.loading = false
                })
                .catch(() => {
                    this.signature.loading = false
                })
        },

        searchViberSignatures () {
            this.signature.viber.loading = true

            this.$axios.post('/signature/viber/search')
                .then(res => {
                    if (res.data.success) {
                        this.signature.viber.data = res.data.data
                    }
                    this.signature.viber.loading = false
                })
                .catch(() => {
                    this.signature.viber.loading = false
                })
        },

        smsAddParam (param) {
            let textarea = document.getElementsByName('sms_message')[0]
            let pos = textarea.selectionStart;

            this.bulk.sms_message
                = this.bulk.sms_message.substring(0, pos)
                + `{{${param.name}}}`
                + this.bulk.sms_message.substring(textarea.selectionEnd, this.bulk.sms_message.length);

            this.smsCount()

            setTimeout(() => {
                textarea.selectionStart = textarea.selectionEnd = pos + param.name.length + 4
                textarea.focus()
            }, 10);
        },

        smsCount () {
            if (this.bulk.category === 'viber') {
                return
            }

            this.calc.data = []

            this.$axios.post('/message/sms/count', this.bulk)
                .then(res => {
                    if (res.data.success) {
                        this.sms.error = res.data.data.error
                        this.sms.minLength = res.data.data.minLength
                        this.sms.minParts = res.data.data.minParts
                        this.sms.maxLength = res.data.data.maxLength
                        this.sms.maxParts = res.data.data.maxParts
                        this.sms.unicode = res.data.data.unicode
                    }
                })
                .catch(() => {})
        },

        viberAddParam (param) {
            let textarea = document.getElementsByName('viber_message')[0]
            let pos = textarea.selectionStart;

            this.bulk.viber_message
                = this.bulk.viber_message.substring(0, pos)
                + `{{${param.name}}}`
                + this.bulk.viber_message.substring(textarea.selectionEnd, this.bulk.viber_message.length);

            this.viberCount()

            setTimeout(() => {
                textarea.selectionStart = textarea.selectionEnd = pos + param.name.length + 4
                textarea.focus()
            }, 10);
        },

        viberCount () {
            if (this.bulk.category === 'sms') {
                return
            }

            this.calc.data = []

            this.$axios.post('/message/viber/count', this.bulk)
                .then(res => {
                    if (res.data.success) {
                        this.viber.error = res.data.data.error
                        this.viber.minLength = res.data.data.minLength
                        this.viber.maxLength = res.data.data.maxLength
                    }
                })
                .catch(() => {})
        },

        viberImageFileReset () {
            this.bulk.viber_image = null
            this.bulk.viber_image_filename = ''
            this.upload.viber_image = false
            this.$nextTick(() => {
                this.upload.viber_image = true
            })
        },

        viberImageFileSelect () {
            this.$refs.viber_image.click()
        },

        viberImageFileSelected (e) {
            const files = e.target.files

            if (files[0] === undefined) {
                this.bulk.viber_image = null
                this.bulk.viber_image_filename = ''
                return
            }

            this.bulk.viber_image = files[0]
            this.bulk.viber_image_filename = files[0].name

            this.calc.errors.viber_image = []
        },
    },
}
</script>

<style scoped>
    .v-application--is-ltr .v-timeline--dense:not(.v-timeline--reverse):before {
        top: -12px;
        bottom: -12px;
        height: auto;
    }
</style>
