<template>
    <v-container>
        <v-row
            text-center
            wrap
        >
            <v-col xs="12">
                <v-card>
                    <v-toolbar flat class="grey lighten-4">
                        <v-toolbar-title>Шаблоны сообщений</v-toolbar-title>
                        <v-spacer/>
                        <v-btn
                            @click="create.show = true"
                            color="blue-grey darken-2"
                            dark
                        >
                            <v-icon>mdi-plus</v-icon>
                            Добавить
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-data-table
                            :headers="search.headers"
                            :items="search.templates"
                            :loading="search.loading"
                        >
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td class="text-left">{{ item.name }}</td>
                                    <td class="text-left">{{ item.service ? 'Сервисный' : 'Рекламный' }}</td>
                                    <td class="text-left">{{ item.message }}</td>
                                    <td class="layout">
                                        <v-icon
                                            @click="update.data = {...item}; update.show = true"
                                            class="mr-2"
                                            small
                                        >
                                            mdi-pencil
                                        </v-icon>
                                        <v-icon
                                            @click="remove.data = item; remove.show = true"
                                            small
                                        >
                                            mdi-delete
                                        </v-icon>
                                    </td>
                                </tr>
                            </template>
                            <template v-slot:no-data>
                                <div v-if="search.loading">
                                    Идет загрузка данных
                                </div>
                                <div v-if="!search.loading">
                                    Нет доступных шаблонов
                                </div>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog
            v-model="create.show"
            max-width="800"
        >
            <v-card>
                <v-toolbar flat class="grey lighten-4">
                    <v-toolbar-title>Новый шаблон</v-toolbar-title>
                </v-toolbar>
                <v-form
                    autocomplete="off"
                    @submit.prevent="createTemplate"
                >
                    <v-card-text>
                        <v-switch
                            v-model="create.data.service"
                            color="primary"
                            label="Шаблон для сервисных / транзакционных сообщений"
                        />
                        <v-text-field
                            v-model="create.data.name"
                            :error-messages="create.errors.name"
                            :disabled="create.progress"
                            name="name"
                            label="Название"
                            type="text"
                            class="mt-5"
                        />
                        <v-textarea
                            v-model="create.data.message"
                            :error-messages="create.errors.message"
                            ref="message"
                            name="create-message"
                            label="Шаблон"
                            class="mt-5"
                            placeholder="Введите текст шаблона"
                        />
                        <div
                            v-if="create.data.service"
                            class="text-left mt-2"
                        >
                            <v-btn
                                small
                                color="warning"
                                class="mr-1"
                                @click="addParam('create', 'СЛОВО')"
                            >
                                {СЛОВО}
                            </v-btn>
                            <v-btn
                                small
                                color="warning"
                                class="mr-1"
                                @click="addParam('create', 'СЛОВА')"
                            >
                                {СЛОВА}
                            </v-btn>
                            <v-btn
                                small
                                color="warning"
                                class="mr-1"
                                @click="addParam('create', 'ЧИСЛО')"
                            >
                                {ЧИСЛО}
                            </v-btn>
                            <v-btn
                                small
                                color="warning"
                                @click="addParam('create', 'ЧИСЛА')"
                            >
                                {ЧИСЛА}
                            </v-btn>
                        </div>
                        <div
                            v-else
                            class="text-left mt-2"
                        >
                            <v-btn
                                small
                                color="warning"
                                class="mr-1"
                                @click="addParam('create', 'Фамилия')"
                            >
                                Фамилия
                            </v-btn>
                            <v-btn
                                small
                                color="warning"
                                class="mr-1"
                                @click="addParam('create', 'Имя')"
                            >
                                Имя
                            </v-btn>
                            <v-btn
                                small
                                color="warning"
                                class="mr-1"
                                @click="addParam('create', 'Отчество')"
                            >
                                Отчество
                            </v-btn>
                            <v-btn
                                small
                                color="warning"
                                @click="addParam('create', 'Параметр')"
                            >
                                Параметр
                            </v-btn>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            :disabled="create.progress"
                            text
                            color="error"
                            @click="create.show = false"
                        >
                            Отменить
                        </v-btn>
                        <v-btn
                            :loading="create.progress"
                            type="submit"
                            color="blue-grey darken-2"
                            dark
                        >
                            Сохранить
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
        <v-dialog
            v-if="remove.data"
            v-model="remove.show"
            max-width="500"
        >
            <v-card>
                <v-card-title class="headline">Удалить шаблон {{ remove.data.name }}?</v-card-title>
                <v-card-text>
                    Удаление не отразится на уже запланированных рассылках. <span v-if="remove.data.service">Вы не сможете больше использовать шаблон для отправки сервисных сообщений.</span>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn
                        @click="remove.show = false"
                        :disabled="remove.progress"
                        color="red darken-1"
                        text
                    >
                        Отменить
                    </v-btn>
                    <v-btn
                        @click="removeTemplate"
                        :loading="remove.progress"
                        color="blue-grey darken-2"
                        darl
                        text
                    >
                        Удалить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-if="update.data"
            v-model="update.show"
            max-width="800"
        >
            <v-card>
                <v-toolbar flat class="grey lighten-4">
                    <v-toolbar-title>Редактирование шаблона</v-toolbar-title>
                </v-toolbar>
                <v-form
                    @submit.prevent="updateTemplate"
                >
                    <v-card-text>
                        <v-text-field
                            v-model="update.data.name"
                            :error-messages="update.errors.name"
                            name="name"
                            label="Название"
                            type="text">
                        </v-text-field>
                        <v-textarea
                            v-if="!update.data.service"
                            v-model="update.data.message"
                            :error-messages="update.errors.message"
                            ref="message"
                            name="update-message"
                            label="Шаблон"
                            class="mt-5"
                            placeholder="Введите текст шаблона"
                        >
                        </v-textarea>
                        <div
                            v-if="!update.data.service"
                            class="text-left mt-0"
                        >
                            <v-btn
                                small
                                color="warning"
                                class="mr-1"
                                @click="addParam('update', 'Фамилия')"
                            >
                                Фамилия
                            </v-btn>
                            <v-btn
                                small
                                color="warning"
                                class="mr-1"
                                @click="addParam('update', 'Имя')"
                            >
                                Имя
                            </v-btn>
                            <v-btn
                                small
                                color="warning"
                                class="mr-1"
                                @click="addParam('update', 'Отчество')"
                            >
                                Отчество
                            </v-btn>
                            <v-btn
                                small
                                color="warning"
                                @click="addParam('update', 'Параметр')"
                            >
                                Параметр
                            </v-btn>
                        </div>
                    </v-card-text>
                    <v-toolbar flat color="light_grey">
                        <v-spacer/>
                        <v-btn
                            :disabled="update.progress"
                            color="red darken-1"
                            text
                            @click="update.show = false"
                        >
                            Отменить
                        </v-btn>
                        <v-btn
                            :loading="update.progress"
                            type="submit"
                            color="blue-grey darken-2"
                            dark
                        >Сохранить</v-btn>
                    </v-toolbar>
                </v-form>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
export default {
    name: "TemplateController",
    data () {
        return {
            create: {
                data: {
                    message: '',
                    name: '',
                    service: false,
                },
                errors: {},
                progress: false,
                show: false,
            },
            remove: {
                data: null,
                progress: false,
                show: false,
            },
            search: {
                data: [],
                headers: [
                    {
                        text: 'Название',
                        value: 'name',
                    },
                    {
                        text: 'Тип',
                        value: 'service',
                    },
                    {
                        text: 'Шаблон',
                        value: 'message',
                    },
                    {
                        text: 'Действия',
                        value: 'name',
                        sortable: false,
                    },
                ],
                loading: false,
            },
            update: {
                data: {
                    id: null,
                    message: '',
                    name: '',
                    service: false,
                },
                errors: {},
                progress: false,
                show: false,
            },
        }
    },
    created: function () {
        this.searchTemplates()
    },
    methods: {
        addParam: function (action, param) {
            let textarea = document.getElementsByName(action + '-message')[0]
            let pos = textarea.selectionStart;

            this[action].data.message
                = this[action].data.message.substring(0, pos)
                + `{${param}}`
                + this[action].data.message.substring(textarea.selectionEnd, this[action].data.message.length);

            setTimeout(() => {
                textarea.selectionStart = textarea.selectionEnd = pos + param.length + 4
                textarea.focus()
            }, 10);
        },

        createTemplate: function () {
            this.create.progress = true

            this.$axios.post('/template/create', this.create.data)
                .then(res => {
                    if (res.data.success) {
                        this.create.progress = false
                        this.create.show = false
                        this.create.data.message = ''
                        this.create.data.name = ''
                        this.create.errors = {}

                        this.searchTemplates()
                    } else {
                        this.create.errors = res.data.errors
                        this.create.progress = false
                    }
                })
                .catch(() => {
                    this.create.progress = false
                })
        },

        searchTemplates: function () {
            this.search.loading = true

            this.$axios.post('/template/search')
                .then(res => {
                    if (res.data.success) {
                        this.search.templates = res.data.data
                    }
                    this.search.loading = false
                })
                .catch(() => {
                    this.search.loading = false
                })
        },

        removeTemplate: function () {
            this.remove.progress = true

            const data = {
                id: this.remove.data.id,
            };

            this.$axios.post('/template/remove', data)
                .then(() => {
                    this.search.templates = []
                    this.remove.show = false
                    this.remove.progress = false
                    this.searchTemplates()
                })
                .catch(() => {
                    this.remove.progress = false
                })
        },

        updateTemplate: function () {
            this.update.progress = true

            this.update.errors = {}

            this.$axios.post('/template/update', this.update.data)
                .then((res) => {
                    if (res.data.success) {
                        this.update.show = false
                        this.update.data.id = null
                        this.update.data.message = ''
                        this.update.data.name = ''
                        this.update.data.service = false

                        this.searchTemplates()
                    } else {
                        this.update.errors = res.data.errors
                    }

                    this.update.progress = false
                })
                .catch(() => {
                    this.update.progress = false
                })
        },
    },
}
</script>
